import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import TextControl from '../../components/TextControl';

export default function Reset({ sessionValid, showMessage }) {
  const [pass, setPass] = useState('');
  const [confirmPass, setConfirmPass] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('Checking token...');
  const [user, setUser] = useState('');
  const [token, setToken] = useState('');

  let history = useHistory();

  document.title = 'Reset Password | Asterism';

  const checkToken = async (token) => {
    let data = await window.ASTERISM.makeRequest(`check-reset-token?data=${token}`, 'GET');
    if (!data || data.error) {
      setMessage('Sorry, it looks like your token is expired.')
    } else {
      setUser(data.email);
      setMessage('');
    }
  }

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    let token = urlSearchParams.get('token')
    if (!token) {
      setMessage('Sorry, there does not appear to be a valid token here.')
    } else {
      setToken(token);
      checkToken(token);
    }
  }, [])

  const reset = async () => {
    if (pass !== confirmPass) {
      setError('Passwords must match');
    } else if (pass.length < 6) {
      setError('Password should be at least 6 characters');
    } else {
      let info = {
        email: user,
        password: pass,
        token: token,
      };
      let data = await window.ASTERISM.makeRequest('change-password', 'POST', info);
      if (!data.error) {
        history.push('/login');
        showMessage({text: 'Success! You can now log in.', temp: true, type: 'success'})
      }
    }
  }

  if (sessionValid) {
    return (
      <div className='space'>
        <h4>Whoa there. You're already logged in. Go to <Link to={'/my-account'}>your account</Link>.</h4>
      </div>
    )
  }

  if (message) {
    return (
      <div className='login-container'>
        <div className='login-box space'>
          <h1 className='page-title mb-24'>Reset Password</h1>
          <em>{message}</em>
        </div>
      </div>
    )
  }

  return(
    <div className='login-container'>
      <div className='login-box space'>
        <h1 className='page-title mb-24'>Reset Password for {user}</h1>
        <TextControl
          classlist='med-input'
          value={pass}
          change={setPass}
          label={'New Password'}
          help={''}
          type='password'
          disabled={false}
        />

        <TextControl
          classlist='med-input'
          value={confirmPass}
          change={setConfirmPass}
          label={'Confirm Password'}
          help={''}
          type='password'
          disabled={false}
        />

        <button className='main-submit' onClick={reset}>Reset</button>

        {error && <div className='error'>{error}</div>}
      </div>

    </div>
  )

}
