import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import moment from 'moment';
import { getMenus } from '../../Menus.js';
import Paginator from '../../components/Paginator';
import { ReactComponent as Book } from '../../book.svg';
import { returnImageMarkup } from '../../Utils.js';

export default function AccountProducts({ showMessage, userId, roles, userMenu, userName }) {
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);

  document.title = 'All Products | Asterism Admin';

  let { page } = useParams();

  const getProducts = async () => {
    let data = await window.ASTERISM.makeRequest(`private-products?page=${page ? page - 1 : 0}&per=20`, 'GET');
    setProducts(data.products);
    setTotal(data.total);
  }

  let history = useHistory();

  useEffect(() => {
    getProducts();
  }, [page]);

  const updatePage = (page) => {
    if (!page || page == 0) {
      history.push(`/my-account/products`)

    } else {
      history.push(`/my-account/products/page/${parseInt(page) + 1}`)

    }
  }

  return (
    <div>
      <div className='admin-full'>

        <div className='standard-header'>
          <h2>All Products ({total})</h2>
          <Link to={`/my-account/add-product`}><button className='secondary-submit'>+ Add Product</button></Link>
        </div>
        <div className='table-header table-item'>
          <div className='image icon'>Cover</div>
          <div className='flex-1'>Title</div>
          <div className='flex-1'>Publication Date</div>
          <div className='flex-1'>ID</div>
          <div className='flex-1'>Format</div>
          <div className='flex-1'>Status</div>
          <div className='flex-1'>Retail</div>
          <div className='flex-1'>Inventory</div>
        </div>
        <div className='admin-orders'>
          {products.map((product, index) =>
            <div key={product._id} className='cart-item'>
              <div className='image'>{product.cover ? returnImageMarkup(product.cover) : <Book />}</div>
              <span className='col'>
                <div className='product-list-title'>
                  <div>{product.title}</div>
                  <span className='authors'>{product.authors.join(', ')}</span>
                  <Link to={`/my-account/product/${product._id}`}>Edit</Link> | <a href={`/products/${product.slug}`} target='_blank'>View</a>
                </div>
              </span>
              <span className='col'>{product.release ? moment(product.release).format('LL') : '--'}</span>
              <span className='col'>{product.idType} {product.productId}</span>
              <span className='col'>{product.format ? product.format : '--'}</span>
              <span className='col'>{product.public ? 'Public' : 'Private'}</span>
              <span className='col'>${product.prices.retail}</span>
              <span className='col'>{product.inventory}</span>
            </div>
          )}
          {products.length == 0 && <div className='mt-24'><em>You don't have any products yet.</em></div>}
          <Paginator value={page ? parseInt(page) - 1 : 0} maxPages={Math.ceil(total / 20)} change={updatePage} />
        </div>
      </div>
    </div>
  )
}
