import React, { useEffect, useState } from 'react';
import {
  Link
} from "react-router-dom";
import moment from 'moment';
import { returnImageMarkup } from '../../Utils.js';

export default function ProductGridItem({ product, home = false, addToCart, showMessage, ebooks = false, className }) {

  const isPreorder = (product) => {
    return new Date(product.release).setHours(0, 0, 0, 0) >= new Date().getTime();
  }

  return (
    <div className={`grid-product smaller ${className}`} key={product.asterismID}>
      <div className='grid-image cover'>
        <Link to={`/products/${product.slug}${ebooks ? "?format=ebook" : ''}`}>
          <div className='cover-image'>
            {returnImageMarkup(product.cover, `Cover art for ${product.title}`, '', 600)}
          </div>
        </Link>
      </div>
      <div className='grid-info'>
        <div className='left'>
          <div className='product-top-meta smaller'>{moment(product.release).format('LL')}</div>
          <div className='title-cont'>
            <Link className='grid-info-title hov' to={`/products/${product.slug}${ebooks ? "?format=ebook" : ''}`}>{product.title}</Link>
          </div>
          <div className='grid-info-authors'>{product.authors && product.authors.join(', ')}</div>
        </div>
        <div className='grid-info-meta right'>
          {!ebooks && <div>{product.prices && !product.prices.enableSale && <span>${product.prices.retail}</span>}</div>}
          {ebooks && <div>${product.ebook.price}</div>}
          {!isPreorder(product) && product.inventory == 0 ?
            <button className='secondary-submit small' disabled={true}>Out of Stock</button>
            : <button className='secondary-submit small' onClick={() => { addToCart(product.theID ? product.theID : product._id, `${ebooks ? 'ebooks' : 'books'}`) }}>Cart +</button>}
        </div>
      </div>
    </div>
  )
}
