import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import Paginator from '../../components/Paginator';
import moment from 'moment';
import showdown from 'showdown';
const converter = new showdown.Converter();

export default function Magazine({ api }) {
  const [posts, setPosts] = useState([]);
  const [total, setTotal] = useState(0);
  const perPage = 20;

  let { page } = useParams();

  const getPosts = async () => {
    let perPage = 20;
    let data = await window.ASTERISM.makeRequest(`list-magazine-archive?page=${parseInt(page) - 1}&per=${perPage}`);
    setPosts(data.posts);
    setTotal(data.total);
  }

  document.title = 'Firmament Archive | Sublunary Editions';
  document.querySelector('meta[name="description"]').setAttribute("content", 'The official magazine of Sublunary Editions. Essays, poetry, fiction, and more every week.');

  let history = useHistory();

  useEffect(() => {
    getPosts();
  }, [page]);

  const updatePage = (page) => {
    if (parseInt(page) == 0) {
      history.push(`/magazine`)
    } else {
      history.push(`/magazine/page/${parseInt(page) + 1}`)
    }
  }

  return (
    <div>
      <div className='magazine-header'>
        <div className='magazine-name'>Firmament</div>
        <div className='magazine-tagline'><span>A magazine of considered miscellany from Sublunary Editions.</span></div>
      </div>
      <div className='featured-area-plain'>
        <Paginator value={parseInt(page) - 1} maxPages={Math.ceil(total / perPage)} change={updatePage} />

        <div className='post-grid'>
          {posts.map((post, index) =>
            <div key={post._id} className={`post-grid-item archive`}>
              <div className='post-grid-frame'></div>
              <div className='post-grid-item-content'>
                <div className='subtitle'>{post.published}</div>
                <span class='title'><Link className='hov' to={`/magazine/${post.slug}`}>{post.title}</Link></span>
                <div className='subtitle'>by {post.byline}</div>
                <div className='excerpt' dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                <Link to={`/magazine/${post.slug}`} class='read-more-link'>Read More</Link>
              </div>
            </div>
          )}
        </div>
        <Paginator value={parseInt(page) - 1} maxPages={Math.ceil(total / perPage)} change={updatePage} />
      </div>
    </div>
  )
}
