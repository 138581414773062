import React, {useState, useEffect} from 'react';
import NumberControl from '../../components/NumberControl';
import DateTimeControl from '../../components/DateTimeControl';
import BinaryControl from '../../components/BinaryControl';
import TextControl from '../../components/TextControl';
import SelectControl from '../../components/SelectControl';

import moment from 'moment';

export default function Copyright() {
  const [pubDate, setPubDate] = useState('January 1, 1950');
  const [renewalDate, setRenewalDate] = useState('January 1, 1960');
  const [authorDeathYear, setAuthorDeathYear] = useState('January 1, 1960');
  const [authorDead, setAuthorDead] = useState(true);
  const [renewed, setRenewed] = useState(false);
  const [wfh, setWfh] = useState(false);
  const [copyrighted, setCopyrighted] = useState(true);
  const [expiry, setExpiry] = useState('');
  const [message, setMessage] = useState('');
  const [explanation, setExplanation] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [searching, setSearching] = useState(false);
  const [searchError, setSearchError] = useState('');

  const [country, setCountry] = useState('US');
  const [results, setResults] = useState([]);


  const countries = require('./countries.json');

  const getPubYearInt = (date) => {
    return parseInt(moment(date).format('YYYY'));
  }

  useEffect(() => {
    if (country == 'US') {
      getCopyrightStatus();
    } else {
      getInternationalCopyrightStatus();
    }
  });

  const key = {
    'CA': 50,
    'AU': getPubYearInt(authorDeathYear) < 1955 ? 50 : 70,
    'BY': 50,
    'CN': 50,
    'GT': 75,
    'IN': 60,
    'MX': getPubYearInt(authorDeathYear) < 1928 ? 50 : getPubYearInt(authorDeathYear) < 1944 ? 75 : getPubYearInt(authorDeathYear) > 2003 ? 100 : 70,
    'NZ': 50,
    'ZA': 50,
    'VE': 60
  };

  const explainers = {
    'one': `The 1976 Copyright Act carried over the system in the 1909 Copyright Act for computing copyright duration for works protected by federal statute before January 1, 1978, with one major change: the length of the renewal term was increased to 47 years. The 1998 Copyright Term Extension Act increased the renewal term another 20 years to 67 years. Thus the maximum total term of copyright protection for works already protected by January 1, 1978, has been increased from 56 years (a first term of 28 years plus a renewal term of 28 years) to 95 years (a first term of 28 years plus a renewal term of 67 years). Applying these standards, all works published in the United States before January 1, ${getPubYearInt() - 96}, are in the public domain. Via copyright.gov.`,
    'three': 'Works originally copyrighted between January 1, 1964, and December 31, 1977. Congress amended the copyright law on June 26, 1992, to automatically renew the copyright in these works and to make renewal registration for them optional. Via copyright.gov.',
    'four': 'The law automatically protects a work that is created and fixed in a tangible medium of expression on or after January 1, 1978, from the moment of its creation and gives it a term lasting for the author’s life plus an additional 70 years. For a “joint work prepared by two or more authors who did not work for hire,” the term lasts for 70 years after the last surviving author’s death. Via copyright.gov.',
    'int': 'A majority of countries offer a standard copyright protection of 70 years after the death of the author',
    'CA': 'Subject to subsection (2), in the case of a literary, dramatic or musical work, or an engraving, in which copyright subsists at the date of the death of the author or, in the case of a work of joint authorship, at or immediately before the date of the death of the author who dies last, but which has not been published or, in the case of a lecture or a dramatic or musical work, been performed in public or communicated to the public by telecommunication, before that date, copyright shall subsist until publication, or performance in public or communication to the public by telecommunication, whichever may first happen, for the remainder of the calendar year of the publication or of the performance in public or communication to the public by telecommunication, as the case may be, and for a period of fifty years following the end of that calendar year. Via https://laws-lois.justice.gc.ca/eng/acts/C-42/page-3.html#h-102776'
  }

  const getCopyrightStatus = () => {
    let pub = getPubYearInt(pubDate);
    let now = getPubYearInt();
    let death = getPubYearInt(authorDeathYear);
    let renewal = getPubYearInt(renewalDate);
    if (pub <= now - 95) {
      setMessage(`This work is out of copyright. Its copyright expired on January 1, ${pub + 95}`);
      setExplanation(explainers['one']);
    } else if (pub >= 1978 && !authorDead && !wfh) {
      setMessage(`This is copyrighted. Its copyright expires 70 years after the author's death`);
      setExplanation(explainers['four']);
    } else if (pub >= 1978 && authorDead && !wfh) {
      setMessage(`This is copyrighted. Its copyright expires January 1, ${death + 70}`);
      setExplanation(explainers['four']);
    } else if (pub >= 1978 && wfh) {
      setMessage(`This is copyrighted. Its copyright expires January 1, ${pub + 95}`);
      setExplanation(explainers['four']);
    } else if (pub > 1963 && pub < 1978) {
      setMessage(`This is copyrighted. Its copyright expires January 1, ${pub + 95}`);
      setExplanation(explainers['one'] + explainers['three']);
    } else if (pub >= now - 95 && pub < 1964 && !renewed) {
      setExplanation(explainers['one']);
      setMessage(`This work is out of copyright. Its copyright expired on January 1, ${pub + 28}`);
    } else if (pub >= now - 95 && pub < 1964 && renewed) {
      setExplanation(explainers['one']);
      setMessage(`This is copyrighted. Its copyright expires January 1, ${pub + 95}`);
    }
  }

  const getInternationalCopyrightStatus = () => {
    let death = getPubYearInt(authorDeathYear);
    let now = getPubYearInt();
    let term = key[country] ? key[country] : 70;
    if (!authorDead) {
      setMessage(`This is copyrighted. Its copyright expires 70 years after the author's death`);
    } else if ((death + term) < now) {
      setMessage(`This is not copyrighted. Its copyright expired on January 1, ${death + term + 1}`);
    } else {
      setMessage(`This is copyrighted. Its copyright expires January 1, ${death + term + 1}`);
    }
    setExplanation(explainers[country] ? explainers[country] : explainers['int']);
  }

  const search = async () => {
    setResults([]);
    setSearching(true);
    let data = await window.ASTERISM.makeRequest(`renewal-search?term=${searchTerm}`, 'GET');
    setSearching(false)
    if (data.data) {
      setResults(data.data);
      setSearchError('')
    } else {
      setSearchError('We didn\'t find anything.')
    }
  }

  if (country !== 'US') {
    return (
      <div className='calc'>
        <h3>Copyright Term Calculator</h3>

        <SelectControl value={country}
          change={setCountry}
          label={'Country'}
          help={''}
          options={countries.map((country) => {
            return {
              value: country.code,
              label: country.name
            }
          })}
        />
        <BinaryControl
          value={authorDead}
          change={setAuthorDead}
          label={'Is the author dead?'}
        />
        {authorDead &&
          <DateTimeControl
            value={authorDeathYear}
            change={setAuthorDeathYear}
            label={'When did the author die?'}
            help={''}
            time={false}
            range={[1900, 2022]}
          />
        }
        <div className='mb-24'>{message}</div>
        {explanation && <p><em>{explanation}</em></p>}
      </div>
    )
  }

  return (
    <div className='calc'>
      <h3>Copyright Term Calculator</h3>
      <SelectControl value={country}
        change={setCountry}
        label={'Country'}
        help={''}
        options={countries.map((country) => {
          return {
            value: country.code,
            label: country.name
          }
        })}
      />
      <DateTimeControl
        value={pubDate}
        change={setPubDate}
        label={'Date of Publication'}
        help={'Leave it January 1 if you only know the year'}
        time={false}
        range={[1900, 2010]}
      />
      {getPubYearInt(pubDate) > getPubYearInt() - 95 &&
        <div>
          {getPubYearInt(pubDate) > 1977 &&
            <div>
              <BinaryControl
                value={authorDead}
                change={setAuthorDead}
                label={'Is the author dead?'}
              />
              {authorDead &&
                <DateTimeControl
                  value={authorDeathYear}
                  change={setAuthorDeathYear}
                  label={'When did the author die?'}
                  help={''}
                  time={false}
                  range={[1900, 2022]}
                />
              }
              <BinaryControl
                value={wfh}
                change={setWfh}
                label={'Was this a work for hire?'}
              />
            </div>
          }
          <BinaryControl
            value={renewed}
            change={setRenewed}
            label={'Was the copyright renewed?'}
          />
          {renewed &&
            <DateTimeControl
              value={renewalDate}
              change={setRenewalDate}
              label={'When was it renewed?'}
              help={''}
              time={false}
              range={[1925, 1978]}
            />
          }
        </div>
      }
      <div className='mb-24'>{message}</div>
      {explanation && <p><em>{explanation}</em></p>}
      <hr className='mt-24 mb-24' />
      <TextControl
        value={searchTerm}
        change={setSearchTerm}
        label={'Stanford Copyright Renewal Database Search'}
        valid={false}
        type='text'
      />
      {!searching && <div className='mb-24'><button className='main-submit' disabled={searchTerm.length == 0} onClick={search}>Search</button></div>}
      {searching && <em>Searching for "{searchTerm}"</em>}

      {results.length > 0 && <strong>{results.length} {results.length == 1 ? 'result' : 'results'} for {searchTerm}</strong>}
      {results.map((result) =>
        <div className='mt-24'>
          <div><strong>Title:</strong> {result.title}</div>
          <div><strong>Author:</strong> {result.author}</div>
          <div><strong>Renewal Date:</strong> {result.renewalDate}</div>
          <div><strong>Publication Date:</strong> {result.publicationDate}</div>
          <div><strong>Claimant:</strong> {result.claimant}</div>
          <div><strong>Class Code:</strong> {result.classCode}</div>
        </div>
      )}
    </div>
  )
}
