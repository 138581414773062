import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import TextControl from '../../components/TextControl';

export default function Login({ checkAuth, sessionValid }) {
  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');
  const [error, setError] = useState('');

  let history = useHistory();

  const login = async () => {
    let data = await window.ASTERISM.makeRequest('login', 'POST', { email: email, password: pass });
    if (data.status !== 'good') {
      setError(data.message);
    } else {
      localStorage.setItem('asToken', data.token);
      setTimeout(() => {
        checkAuth();
        const params = new URLSearchParams(window.location.search)
        const redirect = params.has('return') ? params.get('return') : '/my-account';
        history.push(`${redirect}`)
      }, 300);
    }
  }

  document.title = 'Login | Sublunary Editions';

  const logout = () => {
    localStorage.setItem('asToken', '');
    setTimeout(() => {
      checkAuth();
      history.push('/');
    }, 300);
  }

  if (sessionValid) {
    return (
      <div className='space'>
        <h4>Whoa there. You're already logged in. Go to <Link to={'/my-account'}>your account</Link> or <a onClick={logout}>log out</a>.</h4>
      </div>
    )
  }

  return (
    <div className='login-container'>
      <div className='login-box space'>
        <h1 className='page-title mb-24'>Login</h1>
        <TextControl
          classlist='med-input'
          value={email}
          change={setEmail}
          label={'Email'}
          help={''}
          valid={email.length > 0 && email.includes('@')}
          type='text'
          disabled={false}
        />

        <TextControl
          classlist='med-input'
          value={pass}
          change={setPass}
          label={'Password'}
          help={''}
          valid={false}
          type='password'
          disabled={false}
          onEnter={login}
        />

        <button className='main-submit' onClick={login}>Login</button>

        {error && <div className='error'>{error}. <Link to='/forgot-password'>Forgot your password?</Link></div>}
      </div>

    </div>
  )

}
