import React, {useState, useEffect, useRef} from 'react';
import moment from 'moment';
import TextControl from '../TextControl';
import NumberControl from '../NumberControl';
import FileControl from '../FileControl';
import SelectControl from '../SelectControl';
import SelectAndAddControl from '../SelectAndAddControl';
import BinaryControl from '../BinaryControl';
import RichControl from '../RichControl';
import MultiSelect from '../MultiSelect';
import MarkdownControl from '../MarkdownControl';
import DateTimeControl from '../DateTimeControl';
import showdown from 'showdown';
const converter = new showdown.Converter();

export default function PlanFormEditor({ data, setClosed, person = '', showMessage, contactList = null, publicationList = null, outletList = null }) {
  const [pitch, setPitch] = useState({});
  const [people, setPeople] = useState(contactList);
  const [outlets, setOutlets] = useState([]);
  const [books, setBooks] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [contact, setContact] = useState({});
  const [deleting, setDeleting] = useState(false);

  const features = ['Interviews', 'Reviews', 'Features', 'Excerpts'];
  const formats = ['Digital', 'Print'];

  const attachmentFile = useRef(null);

  const updatePitch = (field, value) => {
    let newPitch = {...pitch};
    newPitch[field] = value;
    setPitch(pitch => newPitch);
  }

  const getOutlets = async() => {
    let data = await window.ASTERISM.makeRequest(`list-all-outlets`, 'GET');
    setOutlets(data);
  }

  const getPublications = async() => {
    let data = await window.ASTERISM.makeRequest(`list-all-publications`, 'GET');
    setBooks(data);
  }

  const getContact = async () => {
    let data = await window.ASTERISM.makeRequest(`get-person?id=${pitch.contact}`, 'GET');
    if (data.email) {
      updatePitch('sendPitchEmailTo', data.email);
    }
    setContact(data);
  }

  useEffect(() => {
    if ((pitch.contact && !contact._id) || (pitch.contact && contact._id && pitch.contact !== contact._id)) {
      getContact();
    }
  }, [pitch])

  useEffect(() => {
    let defaultPitch = {
      contact: person ? person._id : '',
      outlet: '',
      publication: '',
      contactRequest: false,
      features: [],
      status: 'Pitched', // Requested, Followed Up, Declined, Cancelled, Success
      copyRequested: false,
      copySent: false,
      copyFormats: [],
      notes: '',
      sendPitchEmail: false,
      sendPitchEmailTo: person ? person.email : '',
      sendPitchEmailFrom: '',
      sendPitchEmailSubject: '',
      sendPitchEmailMessage: ''
    }
    if (data) {
      setPitch(data);
    } else {
      setPitch(defaultPitch);
    }
    if (outletList) {
      setOutlets(outletList)
    } else {
      getOutlets();
    }
    if (publicationList) {
      setBooks(publicationList);
    } else {
      getPublications();
    }
    if (contactList) {
      setContacts(contactList);
    }
  }, []);

  useEffect(() => {
    setContacts(contactList);
  }, [contactList]);

  useEffect(() => {
    setBooks(publicationList);
  }, [publicationList]);

  useEffect(() => {
    setOutlets(outletList);
  }, [outletList]);

  useEffect(() => {
    if (person._id !== pitch.contact) {
      updatePitch('contact', person._id);
    }
    if (person.email !== pitch.sendPitchEmailTo) {
      updatePitch('sendPitchEmailTo', person.email);
    }
  }, [person]);

  const savePitch = async () => {
    if (!pitch.publication) {
      showMessage({ text: 'You must select a publication', temp: true, type: 'error', label: 'Oops!'});
      return;
    } else if (!pitch._id && pitch.sendPitchEmail && !pitch.sendPitchEmailTo) {
      showMessage({ text: 'You need to fill in the "to" email address', temp: true, type: 'error', label: 'Oops!'});
      return;
    } else if (!pitch._id && pitch.sendPitchEmail && !pitch.sendPitchEmailFrom) {
      showMessage({ text: 'You need to choose a "from" email address', temp: true, type: 'error', label: 'Oops!'});
      return;
    } else if (!pitch._id && pitch.sendPitchEmail && !pitch.sendPitchEmailSubject) {
      showMessage({ text: 'The pitch email needs a subject line', temp: true, type: 'error', label: 'Oops!'});
      return;
    } else if (!pitch._id && pitch.sendPitchEmail && !pitch.sendPitchEmailMessage) {
      showMessage({ text: 'The body of the pitch email can\'t be blank', temp: true, type: 'error', label: 'Oops!'});
      return;
    }
    setUploading(true);
    let updatedPitch = {...pitch};
    if (!pitch.created) {
      updatedPitch.created = new Date().toString();
    }
    updatedPitch.updated = new Date().toString();
    if (pitch._id) {
      if (pitch.contact._id) {
        updatedPitch.contact = pitch.contact._id
      }
      if (pitch.outlet._id) {
        updatedPitch.outlet = pitch.outlet._id
      }
      if (pitch.publication._id) {
        updatedPitch.publication = pitch.publication._id
      }
      let data = await window.ASTERISM.makeRequest(`update-pitch`, 'POST', updatedPitch);
      if (data.ok) {
        showMessage({text: 'Pitch successfully updated!', temp: true, type: 'success'});
        setClosed();
      }
    } else {
      if (pitch.sendPitchEmail) {
        updatedPitch.sendPitchEmailMessage = converter.makeHtml(pitch.sendPitchEmailMessage);
        if (attachmentFile.current && attachmentFile.current.files.length > 0) {
          showMessage({text: 'Hold tight! We\'re uploading your files', temp: true, type: 'info'});
          updatedPitch.sendPitchEmailAttachment = await window.ASTERISM.uploadFile(attachmentFile);
        }
      }
      let data = await window.ASTERISM.makeRequest(`create-pitch`, 'POST', updatedPitch);
      if (data.ok) {
        showMessage({text: 'Pitch successfully created!', temp: true, type: 'success'});
        setClosed();
      }
    }
  }

  const deletePitch = async (id) => {
    let data = await window.ASTERISM.makeRequest(`delete-pitch?id=${id}`, 'GET');
    setClosed(false);
  }

  return(
    <div className='modal full'>
      <div className='modal-content full-sized'>
        <div className='standard-header'>
          <h2>{pitch._id ? 'Editing Pitch' : 'Create a New Pitch'}</h2>
        </div>

        <div className='modal-form two-col'>
          <div className='modal-form-col'>

            {person && <div className='text-control'>
              <label className='form-label'>Pitch To</label>
              <div>{person.name}</div>
            </div>}

            {(pitch && pitch.contact && pitch.contact.name) && <div className='text-control'>
              <label className='form-label'>Pitch To</label>
              <div>{pitch.contact.name}</div>
            </div>}

            {pitch && !pitch._id &&
              <SelectControl
                classlist='med-input'
                value={pitch.contact}
                change={(value) => {updatePitch('contact', value)}}
                label={'Pitching To...'}
                help={''}
                disabled={uploading}
                options={[{value: '', label: '--'}].concat(contacts.sort((a, b) => {
                  return b.value.toUpperCase() < a.value.toUpperCase();
                }).map((outlet) => {
                  return {value: outlet.key,
                  label: outlet.value};
                }))}
              />
            }

            <SelectControl
              classlist='med-input'
              value={pitch.status}
              change={(value) => {updatePitch('status', value)}}
              label={'Pitch Status'}
              help={''}
              disabled={uploading}
              options={[
                {value: 'Pitched', label: 'Pitched'},
                {valued: 'Requires Action', label: 'Requires Action'},
                {valued: 'Waiting', label: 'Waiting'},
                {valued: 'On Hold', label: 'On Hold'},
                {valued: 'Success', label: 'Success'},
                {valued: 'Declined', label: 'Declined'},
                {valued: 'Cancelled', label: 'Cancelled'}
              ]}
            />

            <SelectControl
              classlist='med-input'
              value={pitch.publication && pitch.publication._id ? pitch.publication._id : pitch.publication}
              change={(value) => {updatePitch('publication', value)}}
              label={'What book is this for?'}
              disabled={uploading}
              options={[{value: '', label: '--'}].concat(books.sort((a, b) => {
                return b.value.toUpperCase() < a.value.toUpperCase();
              }).map((outlet) => {
                return {value: outlet.key,
                label: outlet.value};
              }))}
            />

            <SelectControl
              classlist='med-input'
              value={pitch.outlet && pitch.outlet._id ? pitch.outlet._id : pitch.outlet}
              change={(value) => {updatePitch('outlet', value)}}
              label={'Outlet for Pitch'}
              help={'Leave empty if there isn\'t any particular outlet in mind'}
              disabled={uploading}
              options={[{value: '', label: '--'}].concat(outlets.map((outlet) => {
                return {value: outlet.key,
                label: outlet.value};
              }))}
            />

            <MultiSelect
              classList=''
              values={pitch.features ? pitch.features : []}
              change={(value) => {updatePitch('features', value)}}
              choices={features}
              label={'Feature Types'}
              help={'Select types of features type being pitched'}
              disabled={uploading}
            />

            <BinaryControl
              value={pitch.contactRequest}
              disabled={uploading}
              change={(value) => {updatePitch('contactRequest', value)}}
              label={'Did they contact us first?'}
              help={'Just to keep track of outgoing vs. incoming pitches.'}
            />

            <BinaryControl
              value={pitch.copyRequested}
              disabled={uploading}
              change={(value) => {updatePitch('copyRequested', value)}}
              label={'Has a copy been requested?'}
              help={''}
            />

            <BinaryControl
              value={pitch.copySent}
              disabled={uploading}
              change={(value) => {updatePitch('copySent', value)}}
              label={'Have we sent them a copy?'}
              help={''}
            />

            <MultiSelect
              classList=''
              values={pitch.formats ? pitch.formats : []}
              change={(value) => {updatePitch('formats', value)}}
              choices={formats}
              label={'Do they have a preferred format?'}
              help={'Select both if agnostic, leave empty if uknown'}
              disabled={uploading}
            />

            <MarkdownControl
              label='Notes'
              disabled={uploading}
              value={pitch.notes}
              change={(value) => {updatePitch('notes', value)}}
              rows={5}
            />

          </div>

          <div className='modal-form-col'>
            {!pitch._id && <BinaryControl
              value={pitch.sendPitchEmail}
              disabled={uploading}
              change={(value) => {updatePitch('sendPitchEmail', value)}}
              label={'Do you want to send a pitch email from here?'}
              help={''}
            />}

            {!pitch._id && pitch.sendPitchEmail &&
              <div>
                <hr />
                <TextControl
                  value={pitch.sendPitchEmailTo}
                  change={(value) => {updatePitch('sendPitchEmailTo', value)}}
                  label={'Email To'}
                  valid={false}
                  type='text'
                  disabled={uploading}
                />

                <SelectControl
                  classlist='med-input'
                  value={pitch.sendPitchEmailFrom}
                  change={(value) => {updatePitch('sendPitchEmailFrom', value)}}
                  label={'Email From'}
                  help={''}
                  disabled={uploading}
                  options={[
                    {value: '', label: '--'},
                    {value: 'vik@sublunaryeditions.com', label: 'vik@sublunaryeditions.com'},
                    {value: 'jacob@sublunaryeditions.com', label: 'jacob@sublunaryeditions.com'},
                    {value: 'rothes@sublunaryeditions.com', label: 'rothes@sublunaryeditions.com'}
                  ]}
                />

                <TextControl
                  value={pitch.sendPitchEmailSubject}
                  change={(value) => {updatePitch('sendPitchEmailSubject', value)}}
                  label={'Email Subject Line'}
                  valid={false}
                  type='text'
                  disabled={uploading}
                />

                <MarkdownControl
                  label='Email Body'
                  disabled={uploading}
                  value={pitch.sendPitchEmailMessage}
                  change={(value) => {updatePitch('sendPitchEmailMessage', value)}}
                  rows={5}
                />

                <FileControl
                  label={'Email Attachment'}
                  valid={false}
                  reference={attachmentFile}
                  disabled={uploading}
                />
              </div>

            }
            {pitch._id &&
              <div>
                <h3>Contact Info</h3>
                <div className='text-control'>
                  <label className='form-label'>Email</label>
                  <div>{pitch.contact.email}</div>
                </div>

                <div className='text-control'>
                  <label className='form-label'>Address</label>
                  <div>{pitch.contact.address ? pitch.contact.address : '--'}</div>
                </div>

                <div className='text-control'>
                  <label className='form-label'>Sent Email w/ Pitch</label>
                  <div>{pitch.sendPitchEmail ? 'Y' : 'N'}</div>
                </div>

                {pitch.sendPitchEmail &&
                  <div>
                    <div className='text-control'>
                      <label className='form-label'>Email To / From</label>
                      <div>{pitch.sendPitchEmailTo} / {pitch.sendPitchEmailFrom}</div>
                    </div>

                    <div className='text-control'>
                      <label className='form-label'>Email Subject</label>
                      <div>{pitch.sendPitchEmailSubject}</div>
                    </div>

                    <div className='text-control'>
                      <label className='form-label'>Email Message</label>
                      <div dangerouslySetInnerHTML={{__html: pitch.sendPitchEmailMessage}}></div>
                    </div>

                    <div className='text-control'>
                      <label className='form-label'>Attachment</label>
                      <div>{pitch.sendPitchEmailAttachment ? pitch.sendPitchEmailAttachment : '--'}</div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>

        </div>
        <div className='two-col'>
          <div className='button-controls'>
            <button className='main-submit' onClick={savePitch}>{pitch._id ? 'Save Changes' : pitch.sendPitchEmail ? 'Create and Send Pitch' : 'Create Pitch' }</button>
            <button className='secondary-submit' onClick={() => {setClosed(false)}}>Cancel</button>
          </div>
          <div className='button-controls'>
            {pitch._id && !deleting && <button className='secondary-submit' onClick={() => {setDeleting(true)}}>Delete</button>}
            {pitch._id && deleting && <button className='secondary-submit' onClick={() => {setDeleting(false)}}>Cancel Delete</button>}
            {pitch._id && deleting && <button className='main-submit' onClick={() => {deletePitch(pitch._id)}}>Confirm Delete</button>}
          </div>
        </div>
      </div>
    </div>
  )
}
