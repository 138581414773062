import React, { useEffect, useState } from 'react';

export default function SelectAndAddControl( { values, change, label = '', help = '', valid = true, options = [], classlist = '', disabled = false } ) {
  const [currentValue, setCurrentValue] = useState('');

  const addToList = () => {
    let list = values ? [...values] : [];
    if (list.indexOf(currentValue) == -1) {
      list.push(currentValue);
    }
    change(list);
  }

  const removeFromList = (v) => {
    let list = values ? [...values] : [];
    let index = list.indexOf(v);
    list.splice(index, 1)
    change(list);
  }

  let optionKeys = options.reduce((obj, item) => Object.assign(obj, { [item.value]: item.label }), {});

  return (
    <div className={`text-control ${classlist}`}>
      {label && <label className='form-label'>{label}</label>}
      <div className='add-values'>
        {values && values.map((v) =>
          <div key={v} className={`pill email`}>{optionKeys[v] ? optionKeys[v] : v} <span onClick={() => {removeFromList(v)}}>x</span></div>
        )}
      </div>
      <select disabled={disabled ? 'disabled' : ''} className={`${valid && 'valid'}`} value={currentValue} onChange={(e) => {setCurrentValue(e.target.value)}}>
        <option value=''>--</option>
        {options.length > 0 && options.map((opt) =>
          <option value={opt.value}>{opt.label}</option>
        )}
      </select>
      <button className='secondary-submit mt-12' onClick={addToList}>Add Outlet</button>
      {help && <div className='form-help'>{help}</div>}
    </div>
  )
}
