import React, { useEffect, useState } from 'react';
import {
  Link
} from "react-router-dom";
import moment from 'moment';

export default function PostGridItem({ post }) {

  const getLink = (post) => {
    return post.categories.indexOf('News') > -1 ? 'news' : 'magazine';
  }

  return (
    <div key={post._id} className='post-grid-item search-result'>
      <div className='post-grid-item-content'>
        <div className='subtitle'>{post.published}</div>
        <span className='title'><Link className='hov' to={`/${getLink(post)}/${post.slug}`}>{post.title}</Link></span>
        <div className='subtitle'>by {post.byline}</div>
        <div className='excerpt' dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
        <Link to={`/${getLink(post)}/${post.slug}`} className='read-more-link'>Read More</Link>
      </div>
    </div>
  )
}
