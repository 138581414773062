import React, {useState, useEffect, useRef} from 'react';
import moment from 'moment';
import TextControl from '../TextControl';
import NumberControl from '../NumberControl';
import FileControl from '../FileControl';
import SelectControl from '../SelectControl';
import SelectAndAddControl from '../SelectAndAddControl';
import BinaryControl from '../BinaryControl';
import RichControl from '../RichControl';
import MultiSelect from '../MultiSelect';
import MarkdownControl from '../MarkdownControl';
import DateTimeControl from '../DateTimeControl';
import PersonForm from '../PersonEditForm';

const defaultPerson = {
  name: '',
  description: '',
  notes: '',
  comments: [],
  email: '',
  address: '',
  website: '',
  twitter: '',
  facebook: '',
  instagram: '',
  featureTypes: [],
  genres: [],
  outlets: [],
};

export default function PersonModal({ showMessage, setClosed, person = null  }) {
  const [newPerson, setNewPerson] = useState({});

  const getPerson = async (id) => {
    let data = await window.ASTERISM.makeRequest(`get-person?id=${id}`, 'GET');
    setNewPerson(data);
  }

  useEffect(() => {
    if (person) {
      getPerson(person);
    } else {
      setNewPerson(defaultPerson);
    }
  }, [])

  const createPerson = async () => {
    if (newPerson._id) {
      let data = await window.ASTERISM.makeRequest('update-person', 'POST', newPerson);
      if (data.ok) {
        showMessage({text: 'Person successfully updated!', temp: true, type: 'success'});
        setClosed(false);
      }
    } else {
      let data = await window.ASTERISM.makeRequest(`create-person`, 'POST', newPerson);
      if (data.ok) {
        showMessage({text: 'Person successfully created!', temp: true, type: 'success'});
        setClosed(false);
      }
    }
  };

  return(
    <div className='modal full'>
      <div className='modal-content full-sized'>
        <div className='standard-header'>
          <h2>{newPerson._id ? 'Editing Contact' : 'Create a New Contact'}</h2>
        </div>

        <div className='modal-form'>
          {typeof(newPerson.name) == 'string' && <PersonForm setPage={setNewPerson} page={newPerson} />}

        </div>

        <div className='button-controls'>
          <button className='main-submit' onClick={createPerson}>{newPerson._id ? 'Save Changes' : 'Create'}</button>
          <button className='secondary-submit' onClick={() => {setClosed(false)}}>Cancel</button>
        </div>
      </div>
    </div>
  )
}
