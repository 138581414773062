import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import Paginator from '../../components/Paginator';
import ProductGridItem from '../../components/ProductGridItem';

export default function AllBooks({ api, showMessage, addToCart }) {
  const [books, setBooks] = useState([]);
  const [total, setTotal] = useState(0);

  const getBooks = async () => {
    setBooks([]);
    const urlSearchParams = new URLSearchParams(window.location.search);
    let category = urlSearchParams.get('category');
    let newbooks = await fetch(`${api}list-all-books?${category ? `category=${category}&` : ''}page=${page ? page - 1 : 0}&per=20`);
    let newdata = await newbooks.json();
    setBooks(newdata.books);
    setTotal(newdata.total);
  }

  let { page } = useParams();

  let history = useHistory();

  document.title = 'Browse All Books | Sublunary Editions';
  document.querySelector('meta[name="description"]').setAttribute("content", 'Browse all publications by Sublunary Editions.');

  useEffect(() => {
    getBooks();
  }, [page]);

  const updatePage = (page) => {
    if (parseInt(page) == 0) {
      history.push(`/all-books`)
    } else {
      history.push(`/all-books/page/${parseInt(page) + 1}`)
    }
  }

  return (
    <div>
      <div className='page-header-new mb-48'>
        <h1>All Books</h1>
        <p></p>
      </div>
      <div className='product-grid'>
        {books.length > 0 && books.map((product) =>
          <ProductGridItem product={product} home={false} showMessage={showMessage} addToCart={addToCart} />
        )}
      </div>
      {books.length > 0 && <div className='mt-24 mb-24 text-center paginator-sticky'><Paginator value={page ? parseInt(page) - 1 : 0} maxPages={Math.ceil(total / 20)} change={updatePage} />
      </div>}

    </div>
  )
}
