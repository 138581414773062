import React from 'react';

export default function DeleteOrders({selected, orders, cancel, setSelected, getOrders}) {

  const deleteTheOrders = async () => {
    console.log(selected);
    let goners = [];
    for (let i = 0; i < selected.length; i++) {
      goners.push(orders[selected[i]]);
    }
    let data = {
      orders: goners
    };
    let request = await window.ASTERISM.makeRequest(`delete-bulk-orders`, 'POST', data);
    setSelected([]);
    getOrders();
    cancel(false);
  }


  return (
    <div className='modal'>
      <div className='modal-content auto-height'>

        <div className='modal-form'>
          <p>Do you really wanted to delete {selected.length} {selected.length == 1 ? 'order' : 'orders'}?</p>

        </div>

        <div className='button-controls'>
          <button className='main-submit' onClick={deleteTheOrders}>Delete</button>
          <button className='secondary-submit' onClick={() => {cancel(false)}}>Cancel</button>
        </div>
      </div>
    </div>
  )
}
