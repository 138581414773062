import React from 'react';

export const uploadFile = async (ref, makePublic = true) => {
  const token = localStorage.getItem('asToken');
  let originalName = ref.current.files[0].name;
  let originalParts = originalName.split('.');
  let extension = originalParts[originalParts.length - 1];
  let file = ref.current.files[0];
  const formData = new FormData();
  formData.append('file', file);
  let url = !makePublic ? 'file-upload-private' : 'file-upload';
  let response = await fetch(`${window.ASTERISM.api}${url}?token=${token}&ext=${extension}`, {
    method: 'post',
    body: formData,
  });
  let data = await response.json();
  return data.images ? data.images : data.file;
}


export const uploadFileMulti = async (ref, makePublic = true) => {
  const token = localStorage.getItem('asToken');
  let url = !makePublic ? 'file-upload-private' : 'file-upload';
  let fileArray = [];
  for (let i = 0; i < ref.current.files.length; i++) {
    let originalName = ref.current.files[i].name;
    let originalParts = originalName.split('.');
    let extension = originalParts[originalParts.length - 1];
    let file = ref.current.files[i];
    const formData = new FormData();
    formData.append('file', file);
    let response = await fetch(`${window.ASTERISM.api}${url}?token=${token}&ext=${extension}`, {
      method: 'post',
      body: formData,
    });
    let data = await response.json();
    fileArray.push(data.file);
  }
  return fileArray;
}

export const inventoryCount = (product, id = null) => {
  if (!product.inventory || Object.keys(product.inventory).length == 0) {
    return 0;
  }
  let count = 0;
  let sources = Object.keys(product.inventory);
  if (id) {
    return product.inventory[id];
  }
  sources.forEach((source) => {
    count += product.inventory[source];
  });
  return count;
}

const getHeads = (method, body = null) => {
  let heads = {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  if (body) {
    heads.body = JSON.stringify(body);
  }
  return heads;
}

export const makeRequest = async (url, method, body) => {
  url = window.ASTERISM.api + url;
  const token = localStorage.getItem('asToken');
  url += url.includes('?') ? `&token=${token}` : `?token=${token}`;
  let heads = getHeads(method, body ? body : null);
  let response = await fetch(url, heads);
  let data = await response.json();
  return (data);
}

export const getSource = (images, max) => {
  let source = '';
  let keys = Object.keys(images);
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] > max) {
      break;
    }
    source = images[keys[i]];
  }
  return source ? source : images[keys[0]];
}

export const findImageSource = (book) => {
  if (!book.cover) {
    return '';
  } else if (typeof book.cover == 'string') {
    return book.cover;
  }
  else {
    return getSource(book.cover);
  }
}

const buildSizes = (images) => {
  let sizes = [];
  let keys = Object.keys(images);
  keys.forEach((size) => {
    sizes.push(`(max-width: ${size * 3}px) ${size}px`);
  });
  sizes.push(images[keys[keys.length - 1]]);
  return sizes.join(', ')
}

const buildSrcSet = (images, max) => {
  let srcs = [];
  let keys = Object.keys(images);
  keys.forEach((size) => {
    if (size <= max) {
      srcs.push(`${images[size].replace('asterism-files.s3.amazonaws.com', 'files.sublunaryeditions.com')} ${size}w ${size / 300}x`);
    }
  });
  return srcs.join(', ')
}

export const returnImageMarkup = (image, alt = '', classList = '', max = 1200) => {
  if (!image) {
    return;
  } else if (typeof image == 'string') {
    return (
      <img src={image.replace('asterism-files.s3.amazonaws.com', 'files.sublunaryeditions.com')} alt={alt} className={classList} />
    )
  } else {
    const source = getSource(image, max);
    const sourceSet = buildSrcSet(image, max);
    //const sizes = buildSizes(image);
    return (
      <img src={source} srcSet={sourceSet} alt={alt} className={classList} />
    )
  }
}