import React, { useEffect, useState } from 'react';
import BinaryControl from '../BinaryControl';
import showdown from 'showdown';
const converter = new showdown.Converter();

export default function MarkdownControl( { value, change, label = '', help = '', placeholder ='', valid = true, type = 'text', classlist = '', disabled = false, rows = 3 } ) {
  const [localValue, setLocalValue] = useState(value);
  const [editing, setEditing] = useState(true);

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div className='text-control'>
      {label && <label className='form-label'>{label}</label>}
      <BinaryControl
        value={editing}
        change={(value) => {setEditing(value)}}
        options={['Edit', 'Preview']}
      />
      <div className='markdown-container'>
        {editing && <textarea
          disabled={disabled ? 'disabled' : ''}
          rows={rows}
          type={type}
          className={`${valid && 'valid'} ${classlist}`}
          placeholder={placeholder}
          onBlur={() => change(localValue)}
          value={localValue} onChange={(e) => {setLocalValue(e.target.value)}} />}
          {!editing && <div className='post-content product-description' dangerouslySetInnerHTML={{__html: converter.makeHtml(localValue)}}></div>}
      </div>
      {editing && <div className='form-help'>This editor uses Markdown. Unfamiliar? You can find a guide <a href='https://www.markdownguide.org/basic-syntax/' target='_blank'>here</a>.</div>}
    </div>
  )
}
