import React, {useState, useEffect, useRef} from 'react';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import PageForm from '../../components/PageEditForm';
import slugify from 'slugify';
import moment from 'moment';


export default function AccountPageEdit({ showMessage, userId }) {
  const [page, setPage] = useState({});
  const [error, setError] = useState('');
  const [login, setLogin] = useState(false);

  const updatePage = (field, value) => {
    let newPage = {...page};
    newPage[field] = value;
    setPage(newPage);
  }

  let { id } = useParams();

  document.title = 'Editing Page | Asterism';

  const getPage = async () => {
    let data = await window.ASTERISM.makeRequest(`private-page?id=${id}`, 'GET');
    setPage(data);
  }


  useEffect(() => {
    getPage();
  }, []);


  const submit = async () => {
    let data = await window.ASTERISM.makeRequest('update-page', 'POST', page);
    if (data.ok) {
      updatePage('_rev', data.rev);
      showMessage({text: 'Page successfully updated!', temp: true, type: 'success'})
    }
  }

  if (error) {
    return (
      <div className='edit-bar'><div className='edit-title'>{error} {login && <Link to={`/login?return=${window.location.pathname}`}><button className='secondary-submit'>Login</button></Link>}</div></div>
    )
  }

  return (
    <div className=''>
      <div className='edit-bar'>
        <div className='edit-title'>Editing <em>{page.title}</em></div>
        <button className='main-submit' onClick={submit}>Save Changes</button>
      </div>
      <PageForm setPage={setPage} page={page} />
    </div>
  )
}
