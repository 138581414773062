import React, { useState, useEffect, useRef } from 'react';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import ProductForm from '../../components/ProductEditForm';
import { uploadFile } from '../../Utils.js';
import slugify from 'slugify';

export default function AccountProductAdd({ showMessage }) {
  const [product, setProduct] = useState({});
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState();
  const [login, setLogin] = useState(false);
  const [valid, setValid] = useState('');

  document.title = 'Add a New Product | Asterism Admin'

  let history = useHistory();

  const newCover = useRef(null);
  const previewFile = useRef(null);
  const ebookFile = useRef(null);
  const newFile = useRef(null);

  const setDefaultProduct = () => {
    setProduct({
      "title": "",
      "subtitle": "",
      "slug": "",
      "public": true,
      "authors": [],
      "editors": [],
      "translators": [],
      "illustrators": [],
      "description": "",
      "format": "Paperback",
      "productId": "",
      "idType": "ISBN",
      "featuredExcerpt": "",
      "supplements": [],
      "pages": 0,
      "release": new Date().toString(),
      "categories": [],
      "prices": {
        "list": 0.00,
        "retail": 0.00,
        "sale": 0.00,
        "enableSale": false,
        "retailStripe": '',
        "saleStripe": ''
      },
      "inventory": 0,
      "asterismLink": "",
      "dimension": {
        "height": 7,
        "width": 4.5,
        "thickness": "0",
        "weight": "0"
      },
      "ebook": {
        "price": 0.00,
        "stripePrice": "",
        "file": ""
      },
      "cover": "",
      "related": "",
      "hideSubscribe": false
    });
  }

  useEffect(() => {
    setDefaultProduct();
  }, []);

  const intoSlug = () => {
    if (product.title && product.authors.length === 1) {
      return slugify(`${product.title} ${product.authors[0]}`, { lower: true, strict: true, })
    } else if (product.title) {
      return slugify(product.title, { lower: true, strict: true, });
    } else {
      return '';
    }
  }

  const submit = async (another) => {
    if (!product.productId || !product.title) {
      showMessage({ text: 'Product must have an ID and a title', temp: true, type: 'warn' });
      return;
    }
    let newBook = { ...product }
    newBook.slug = intoSlug();
    setUploading(true);
    if ((previewFile.current && previewFile.current.files.length > 0) || (newCover.current && newCover.current.files.length > 0) || (ebookFile.current && ebookFile.current.files.length > 0)) {
      showMessage({ text: 'Hold tight! We\'re uploading your files', temp: true, type: 'info' });
    }
    if (previewFile.current && previewFile.current.files.length > 0) {
      newBook.preview = await window.ASTERISM.uploadFile(previewFile);
    }
    if (newCover.current && newCover.current.files.length > 0) {
      newBook.cover = await window.ASTERISM.uploadFile(newCover);
    }
    if (ebookFile.current && ebookFile.current.files.length > 0) {
      newBook.ebook.file = await window.ASTERISM.uploadFile(ebookFile);
    }
    setUploading(false);
    let data = await window.ASTERISM.makeRequest('create-product', 'POST', newBook)
    if (data.ok) {
      showMessage({ text: 'Product successfully created!', temp: true, type: 'success' });
      if (another) {
        setDefaultProduct();
      } else {
        history.push(`/my-account/product/${data.id}`);
      }
    } else {
      showMessage({ text: 'Something went wrong. Check your product ID to make sure a product with this ID doesn\'t already exist.', temp: true, type: 'warn' });
    }
  }

  if (error || !product.authors) {
    return (
      <div className='edit-bar'><div className='edit-title'>{error} {login && <Link to={`/login?return=${window.location.pathname}`}><button className='secondary-submit'>Login</button></Link>}</div></div>
    )
  }

  return (
    <div className=''>
      <div className='edit-bar'>
        <Link className='back-link' to='/my-account/products'>⟵ Back to products</Link>
        <div>
          <button className='main-submit' onClick={() => { submit(false) }}>Save</button>
          <button className='main-submit' onClick={() => { submit(true) }}>Save and Create Another</button>
        </div>
      </div>
      <ProductForm setProduct={setProduct} product={product} previewFile={previewFile} newCover={newCover} ebookFile={ebookFile} uploading={uploading} newFile={newFile} showMessage={showMessage} />
    </div>
  )
}
