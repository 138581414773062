import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import moment from 'moment';
import { ReactComponent as Ebook } from '../../ebook.svg';
import { ReactComponent as Book } from '../../book.svg';
import { ReactComponent as Close } from '../../close.svg';
import {inventoryCount} from '../../Utils.js';
import TextAreaControl from '../../components/TextAreaControl';
import ProductGridItem from '../../components/ProductGridItem';
import Tooltip from '../../components/Tooltip';
import showdown from 'showdown';
const converter = new showdown.Converter();


export default function Product() {
  const [previewing, setPreviewing] = useState(false);

  return (
    <div>
      <div className='product-page'>

        <div className='product-image'>
          <div className='product-image-cover'>
            <div className='product-image-frame'></div>
            <img src='https://sublunaryeditions.com/isle.jpg' />
          </div>
          <div className='mt-24' style={{display: 'block', textAlign: 'center'}}>
            <button className='secondary-submit' onClick={() => {setPreviewing(true)}}>Preview This Book</button>
          </div>
          <div className='product-stats desktop'>
            <span className='stat-label'>Pages:</span><div>230</div>
            <span className='stat-label'>Format:</span><div>Paperback</div>
            <span className='stat-label'>ISBN:</span><div>978-234-12344-1</div>

            <span className='stat-label'>Dimensions:</span><div>5x7</div>

          </div>
        </div>
        <div className='product-data'>
          <div className='product-top-meta'>September 8, 2022</div>
          <h1>Isle of the Cross</h1>
          <div className='contributors'>
            <span className='authors'>Herman Melville</span>
          </div>
          <div className='product-pricing mb-48'>
            <div className='prices'>
              <span className='higher-price'>$18.00</span>
              <span className='real-price'>$16.00</span>
            </div>
            <div className='add-to-cart'>
              <button className='main-submit mr-12' onClick={() => {setPreviewing(true)}}>Pre-order</button>
            </div>
          </div>



          <div className='product-description' dangerouslySetInnerHTML={{__html: '<blockquote><p>"But during my ramble on the isle I was not long in discovering other tokens of things quite in accordance with those wild traits, popularly, and no doubt truly enough, imputed to the freebooters at large."</p></blockquote><p>Discovered in a Cape Cod attic after more than 160 years, Herman Melville\'s <em>Isle of the Cross</em> has finally come to light, dazzling the handful of scholars who have been allowed to review the manuscript. Far from the brief sketch it was believed to be, <em>Isle of the Cross</em> comes in at more than 200 pages, a work that stands neatly alongside <em>Israel Potter</em> or <em>The Confidence-Man</em>, a must-read for more than just those with academic interest. Available now to the general public for the first time.</p> <p><strong>Herman Melville</strong> (August 1, 1819 – September 28, 1891) was an American novelist and Jean Paul fanatic, best remembered for his 1852 novel, <em>Pierre; or, The Ambiguities</em>, which won him worldwide acclaim.</p>'}}></div>

          <div className='product-stats mobile'>
            <span className='stat-label'>Pages:</span><div>230</div>
            <span className='stat-label'>Format:</span><div>paperback</div>
            <span className='stat-label'>ISBN:</span><div>978-234-12344-1</div>
            <span className='stat-label'>Dimensions:</span><div>5x7</div>
          </div>
        </div>
        {previewing &&
          <div className='modal'>
            <div className='modal-content af'>

              <div>
                <h1>April Fool's!</h1>
                <p className='af'>But that would have been <em>so</em> cool, right? Anyway, sorry to disappoint you, but we'll have some fun news about a lost manuscript before long. In the meantime, consider taking those Melville dollars and donating to one of the many great organization providing relief to Ukrainian refugees. While we're no experts, we would recommend <a href='https://wck.org/'>World Central Kitchen</a>.</p>
                <div className='mt-24' style={{display: 'block', textAlign: 'center'}}>
                  <button className='secondary-submit' onClick={() => {setPreviewing(false)}}>Close</button>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
      <div className='generic-block flex'>
        <h2>Related</h2>
      </div>
      <div className='product-grid'>
        <div className={`grid-product smaller`}>
          <div className='grid-image'><img src="https://sublunaryeditions.com/messiah.jpg" /></div>
          <div className='grid-info'>
            <div className='left'>
              <div className='product-top-meta smaller'>September 9, 2022</div>
              <div className='title-cont'>
                <a className='grid-info-title hov' onClick={() => {setPreviewing(true)}}>The Messiah</a>
              </div>
              <div className='grid-info-authors'>Bruno Schulz</div>
            </div>
            <div className='grid-info-meta right'>
              <div><span>$16</span></div>
              <button className='secondary-submit small' onClick={() => {setPreviewing(true)}}>Cart +</button>
            </div>
          </div>
        </div>

        <div className={`grid-product smaller`}>
          <div className='grid-image'><img src="https://sublunaryeditions.com/bahtkin.jpg" /></div>
          <div className='grid-info'>
            <div className='left'>
              <div className='product-top-meta smaller'>September 14, 2022</div>
              <div className='title-cont'>
                <a className='grid-info-title hov' onClick={() => {setPreviewing(true)}}>The Bildungsroman</a>
              </div>
              <div className='grid-info-authors'>Mikhail Bakhtin</div>
            </div>
            <div className='grid-info-meta right'>
              <div><span>$16</span></div>
              <button className='secondary-submit small' onClick={() => {setPreviewing(true)}}>Cart +</button>
            </div>
          </div>
        </div>

        <div className={`grid-product smaller`}>
          <div className='grid-image'><img src="https://sublunaryeditions.com/master.jpg" /></div>
          <div className='grid-info'>
            <div className='left'>
              <div className='product-top-meta smaller'>September 14, 2022</div>
              <div className='title-cont'>
                <a className='grid-info-title hov' onClick={() => {setPreviewing(true)}}>Pontius Pilate</a>
              </div>
              <div className='grid-info-authors'>Master</div>
            </div>
            <div className='grid-info-meta right'>
              <div><span>$16</span></div>
              <button className='secondary-submit small' onClick={() => {setPreviewing(true)}}>Cart +</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
