import React, {useState, useEffect} from 'react';
import {
  Link
} from "react-router-dom";


export const getMenus = (roles) => {
  const menus = {
    publisher: [
      {
        label: 'Products',
        path: '/my-account/products',
        external: false,
      },
      {
        label: 'Orders',
        path: '/my-account/orders',
        external: false,
      },
      {
        label: 'Returns',
        path: '/my-account/incoming-returns',
        external: false,
      },
      {
        label: 'Shipments',
        path: '/my-account/shipments',
        external: false,
      },
      {
        label: 'Posts',
        path: '/my-account/posts',
        external: false,
      },
      {
        label: 'ARC Requests',
        path: '/my-account/arc-requests',
        external: false,
      },
      {
        label: 'Knowledge Base',
        path: '/knowledge',
        external: false,
      }
    ],
    buyer: [
      {
        label: 'My Orders',
        path: '/my-account/my-orders',
        external: false,
      },
      {
        label: 'My Returns',
        path: '/my-account/my-returns',
        external: false,
      },
    ],
    reviewer: [
      {
        label: 'ARC Requests',
        path: '/my-account/arc-requests',
        external: false,
      },
    ],
    distributor: [
      {
        label: 'My Orders',
        path: '/my-account/orders',
        external: false,
      },
      {
        label: 'Inventory',
        path: '/my-account/inventory',
        external: false,
      },
      {
        label: 'Shipments',
        path: '/my-account/shipments',
        external: false,
      },
      {
        label: 'Knowledge Base',
        path: '/knowledge',
        external: false,
      }
    ]
  };
  let menu = [];

  if (roles.publisher) {
    return menus['publisher'];
  } else if (roles.distributor) {
    return menus['distributor'];
  } else if (roles.buyer) {
    return menus['buyer'];
  } else if (roles.reviewer) {
    return menus['reviewer'];
  } else {
    return [];
  }


  Object.keys(roles).forEach((role) => {
    if (roles[role]) {
      menu = menu.concat(menus[role]);
    }
  });
  return menu;
}

export const mainMenu = {
  left: [
    {
      label: 'News',
      path: '/news',
    },
    {
      label: 'Our Publishers',
      path: '/our-publishers',
    },
    {
      label: 'All Books',
      path: '/all-books',
    },
    {
      label: 'New Books',
      path: '/new-books',
    },
    {
      label: 'Upcoming Books',
      path: '/upcoming-books',
    },
  ],
  right: [
    {
      label: 'About',
      path: '/about',
    },
    {
      label: 'Apply for an Account',
      path: '/apply',
    },
  ]
}

export const getMainMenu = (open, setMenuOpen, sessionValid, roles={}) => {
  const isAdmin = window.location.pathname.includes('my-account');

  if (window.innerWidth > 1000 && !isAdmin) {
    return (
      <div className='edit-bar'>
        <div className='sub-menu left'>
          {mainMenu.left.map((item) =>
            item.path !== window.location.pathname  ? <Link to={item.path}>{item.label}</Link> : <span>{item.label}</span>
          )}
        </div>
        <div className='sub-menu'>
          {mainMenu.right.map((item) =>
            item.path !== window.location.pathname  ? <Link to={item.path}>{item.label}</Link> : <span>{item.label}</span>
          )}
          {sessionValid ? <Link to='/my-account'>My Account</Link> : <Link to='/login'>Login</Link>}
        </div>
      </div>
    )
  } else if (open && !isAdmin) {
    return (
      <div className='edit-bar'>
        <a onClick={() => {setMenuOpen(false)}}>Menu</a>
        {sessionValid ? <Link to='/my-account'>My Account</Link> : <Link to='/login'>Login</Link>}
          <div className='mobile-menu'>
          {mainMenu.left.concat(mainMenu.right).map((item) =>
            <div className='mobile-menu-item'>
              {item.path !== window.location.pathname  ? <Link to={item.path}>{item.label}</Link> : <span>{item.label}</span>}
            </div>
          )}
          </div>
      </div>
    )
  } else if (!open && !isAdmin) {
    return (
      <div className='edit-bar'>
        <a onClick={() => {setMenuOpen(true)}}>Menu</a>
        {sessionValid ? <Link to='/my-account'>My Account</Link> : <Link to='/login'>Login</Link>}
      </div>
    )
  } else {
    return (
      <div></div>
    )
  }
}
