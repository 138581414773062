import React, { useEffect, useState } from 'react';
import {
  Link
} from "react-router-dom";
import moment from 'moment';
import { returnImageMarkup } from '../../Utils.js';

export default function BundleGridItem({ product, home = false, addToCart, showMessage }) {

  return (
    <div className={`grid-product smaller`} key={product._id}>
      <div className='grid-image'><Link to={`/bundles/${product.slug}`}>
        {returnImageMarkup(product.cover, `Cover art for ${product.title}`, '', 600)}
      </Link></div>
      <div className='grid-info'>
        <div className='left'>
          <div className='title-cont'>
            <Link className='grid-info-title hov' to={`/bundles/${product.slug}`}>{product.title}</Link>
          </div>
          <div className='grid-info-authors'>{product.subtitle}</div>
        </div>
        <div className='grid-info-meta right'>
          <div>{product.prices && !product.prices.enableSale && <span>${product.prices.retail}</span>}</div>
          <button className='secondary-submit small' onClick={() => { addToCart(product._id, 'bundles') }}>Cart +</button>
        </div>
      </div>
    </div>
  )
}
