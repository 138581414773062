import React, { useEffect, useState } from 'react';

export default function TextControl( { value, change, label = '', help = '', placeholder ='', valid = true, type = 'text', classlist = '', disabled = false, min = 0, max = null, step = 1, important = '', required = false, onEnter = null, bypass = false } ) {
  const [localValue, setLocalValue] = useState(value);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && onEnter) {
      change(localValue);
      setTimeout(() => {
        onEnter();
      }, 30)
    }
  }

  useEffect(() => {
    if (value !== localValue) {
      setLocalValue(value);
    }
  }, [value]);

  return (
    <div className='text-control'>
      {label && <label className='form-label'>{label} {required && <span className='required'>*</span>}</label>}
      {important && <div className='form-important'>{important}</div>}
      <input
        disabled={disabled ? 'disabled' : ''}
        step={step}
        type={type}
        min={min}
        className={`${valid && 'valid'} ${classlist}`}
        max={max}
        onKeyDown={handleKeyDown}
        placeholder={placeholder}
        value={localValue}
        onBlur={() => change(localValue)}
        onChange={(e) => {bypass ? change(e.target.value) : setLocalValue(e.target.value)}} />
      {help && <div className='form-help' dangerouslySetInnerHTML={{__html: help}}></div>}
    </div>
  )
}
