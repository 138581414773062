import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import TextControl from '../../components/TextControl';
import SelectControl from '../../components/SelectControl';
import TextAreaControl from '../../components/TextAreaControl';
import MultiSelect from '../../components/MultiSelect';

export default function ReviewRequest({ showMessage }) {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [success, setSuccess] = useState(false);
  const [products, setProducts] = useState([]);
  const [formats, setFormats] = useState([]);

  let history = useHistory();

  const getProducts = async () => {
    let data = await window.ASTERISM.makeRequest(`list-all-publications`, 'GET');
    data.sort((a, b) => {
      return b.value < a.value;
    })
    setProducts(data);
  }

  useEffect(() => {
    getProducts();
  }, []);

  const submit = async () => {
    if (!name || !email) {
      showMessage({ text: 'You need to enter your name and email.', temp: true, type: 'error' });
      return;
    } else if (!title) {
      showMessage({ text: 'Please select a title', temp: true, type: 'error' });
      return;
    } else if (!message) {
      showMessage({ text: 'Message is required', temp: true, type: 'error' });
      return;
    }

    let request = {
      name: name,
      email: email,
      message: message,
      formats: formats,
      title: title,
    }
    let data = await window.ASTERISM.makeRequest('send-arc-request', 'POST', request);
    setSuccess(true);
  }


  document.title = 'Request Review a Copy | Sublunary Editions';
  document.querySelector('meta[name="description"]').setAttribute("content", 'Use this form to request review copies for any of our titles!');


  if (success) {
    return (
      <div className='login-container'>
        <div className='login-box space'>
          <h1 className='page-title mb-24'>Request a Review Copy</h1>
          <p>Success! Thanks for submitting your request. We'll be in touch soon.</p>
        </div></div>
    )
  }

  return (
    <div className='login-container'>
      <div className='login-box space'>
        <h1 className='page-title mb-24'>Request a Review Copy</h1>
        <p style={{ maxWidth: '800px' }}>Use this form to request a review copy of a specific Sublunary Editions title. If you're requesting more than one, or just want to inquire about being on our list of potential reviewers, please get in touch with Publicity Director Vik Shirley at vik@sublunaryeditions.com.</p>
        <TextControl
          classlist='med-input'
          value={name}
          change={setName}
          label={'Name'}
          help={''}
          valid={false}
          type='text'
          required={true}
          disabled={false}
          bypass={true}
        />
        <TextControl
          classlist='med-input'
          value={email}
          change={setEmail}
          label={'Email'}
          help={''}
          valid={email.length > 0 && email.includes('@')}
          type='text'
          disabled={false}
          required={true}
          bypass={true}
        />

        <SelectControl
          classlist='med-input'
          value={title}
          change={setTitle}
          label={'What title are you requesting?'}
          required={true}
          options={[
            {
              value: '--',
              label: '--'
            },
          ].concat(products.map((prod) => {
            return {
              value: prod.value,
              label: prod.value
            }
          }))}
        />

        <TextAreaControl
          classlist='med-input'
          value={message}
          required={true}
          change={setMessage}
          label={'Tell us more...'}
          description={'Let us know a bit about yourself, if you have a review outlet in mind, etc.'}
          help={''}
          valid={email.length > 0 && email.includes('@')}
          type='text'
          disabled={false}
          bypass={true}
        />

        <MultiSelect
          values={formats}
          change={setFormats}
          choices={['Print', 'Digital']}
          label={'Preferred Format(s)'}
          help={'Note that print ARCs are usually limited and may take longer.'}
        />

        <button className='main-submit' onClick={submit}>Request</button>

      </div>

    </div>
  )

}
