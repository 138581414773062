import React, {useState, useEffect} from 'react';
import {
  Link
} from "react-router-dom";
import moment from 'moment';
import {getMainMenu} from '../../Menus.js';
import showdown from 'showdown';
const converter = new showdown.Converter();


export default function Subscribe({ api, addToCart }) {
  const [subs, setSubs] = useState([]);
  const [intsubs, setIntsubs] = useState([]);
  const [canadasubs, setCanadasubs] = useState([]);

  const sortByPrice = (sbs) => {
    return sbs.sort((a, b) => {
      return parseInt(a.prices.retail) - parseInt(b.prices.retail);
    })
  }

  const getSubs = async () => {
    let data = await window.ASTERISM.makeRequest(`list-all-subs?region=US`, 'GET');
    setSubs(data);
    let data2 = await window.ASTERISM.makeRequest(`list-all-subs?region=Int`, 'GET');
    setIntsubs(data2);
    let data3 = await window.ASTERISM.makeRequest(`list-all-subs?region=CA`, 'GET');
    setCanadasubs(data3);
  }

  useEffect(() => {
    getSubs();
  }, [])

  document.title = 'Subscribe | Sublunary Editions';
  document.querySelector('meta[name="description"]').setAttribute("content", 'Subscribe to Sublunary Editions, the best way to support a small, independent press.');


  return (
    <div>
      <div className='page-header-new'>
        <h1>Subscribe</h1>
        <p>Subscribing is the best way to support Sublunary Editions and make sure you don't miss out on any of our upcoming titles! Plans for the US and Canada are below (shipping rates at present keep us from offering International options, but do check back occasionally). If you're looking to <Link to='/gift-subscriptions'>gift a subscription, you can do so here</Link>!</p>
      </div>
      <div className='admin-full'>
        <div className='standard-header'>
          <h2>Subscription Offers (US)</h2>
        </div>
        <div className='post-grid'>
          {subs.map((sub) =>
            <div key={sub._id} className='post-grid-item sub'>
              <div className='post-grid-frame'></div>
              <div className='post-grid-item-content'>
                <h3>{sub.title}</h3>
                <div className='subtitle'>{sub.subtitle}</div>
                <div className='excerpt' dangerouslySetInnerHTML={{__html: converter.makeHtml(sub.description)}}></div>
                <div className='sub-price'>${sub.prices.retail}/{sub.prices.period}</div>
                <button className='main-submit' onClick={() => {addToCart(sub._id, 'subs')}}>Add to Cart</button>
              </div>
            </div>
          )}
        </div>

        <div className='standard-header'>
          <h2>Subscription Offers (Canada)</h2>
        </div>
        <div className='post-grid'>
          {canadasubs.map((sub) =>
            <div key={sub._id} className='post-grid-item sub'>
              <div className='post-grid-frame'></div>
              <div className='post-grid-item-content'>
                <h3>{sub.title}</h3>
                <div className='subtitle'>{sub.subtitle}</div>
                <div className='excerpt' dangerouslySetInnerHTML={{__html: converter.makeHtml(sub.description)}}></div>
                <div className='sub-price'>${sub.prices.retail}/{sub.prices.period}</div>
                <button className='main-submit' onClick={() => {addToCart(sub._id, 'subs')}}>Add to Cart</button>
              </div>
            </div>
          )}
        </div>

        <div className='standard-header'>
          <h2>Subscription Offers (International)</h2>
        </div>
        <div className='post-grid'>
          {intsubs.map((sub) =>
            <div key={sub._id} className='post-grid-item sub'>
              <div className='post-grid-frame'></div>
              <div className='post-grid-item-content'>
                <h3>{sub.title}</h3>
                <div className='subtitle'>{sub.subtitle}</div>
                <div className='excerpt' dangerouslySetInnerHTML={{__html: converter.makeHtml(sub.description)}}></div>
                <div className='sub-price'>${sub.prices.retail}/{sub.prices.period}</div>
                <button className='main-submit' onClick={() => {addToCart(sub._id, 'subs')}}>Add to Cart</button>
              </div>
            </div>
          )}
        </div>

      </div>
    </div>
  )
}
