import React, {useState, useEffect} from 'react';
import {
  Link
} from "react-router-dom";
import moment from 'moment';
import {getMainMenu} from '../../Menus.js';
import ProductGridItem from '../../components/ProductGridItem';
import { ReactComponent as Triangle } from '../../triangle.svg';


export default function Firmament({ api, showMessage, addToCart }) {
  const [books, setBooks] = useState([]);

  const getBooks = async () => {
    let data = await window.ASTERISM.makeRequest('list-all-books?category=Firmament&per=20&page=0', 'GET');
    setBooks(data.books);
  }

  useEffect(() => {
    getBooks();
  }, [])

  document.title = 'Firmament | Sublunary Editions';
  document.querySelector('meta[name="description"]').setAttribute("content", 'All issues of Firmament, the official magazine of Sublunary Editions');


  return (
    <div>
      <div className='page-header-new'>
        <h1>Firmament</h1>
        <p>In the early days of Sublunary Editions, we mailed envelopes full of new stories, essays, and poems to subscribers every month. As papercuts took their toll and our ambitions grew, we introduced <em>Firmament</em>, a quarterly magazine of considered miscellany, featuring new and rediscovered works, often in translation, from around the world. You can find individual issues below, or subscribe for just $5-7/month (which includes a monthly subscriber-only newsletter) <a href=''>here</a></p>
      </div>
      <div className='generic-block flex'>
        <div className='standard-header'>
          <h2>Issues</h2>
        </div>
      </div>
      <div className='product-grid'>
        {books && books.map((product) =>
          <ProductGridItem key={product._id} product={product} showMessage={showMessage} addToCart={addToCart} />
        )}
      </div>
    </div>
  )
}
