import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import moment from 'moment';
import showdown from 'showdown';
const converter = new showdown.Converter();

export default function Page({ api, addToCart }) {
  const [page, setPage] = useState({});

  let { slug } = useParams();

  const getPage = async () => {
    let response = await fetch (`${api}public-page?slug=${slug}`);
    let data = await response.json();
    document.title = `${data.title} | Sublunary Editions`;
    document.querySelector('meta[name="description"]').setAttribute("content", data.content);

    setPage(data);
  }

  useEffect(() => {
    getPage();
  }, [slug]);

  if (!page._id) {
    return (
      <div>
      </div>
    )
  }

  return (
    <div>
      <div className='page-header-new'>
        <h1>{page.title}</h1>
        <p></p>
      </div>
      <div className='admin-two'>
        <div className='admin-col-large'>
          <div className='page-content' dangerouslySetInnerHTML={{__html: converter.makeHtml(page.content)}}></div>
        </div>
        <div className='admin-col-small'>
          {page.sidebarTitle && <h2>{page.sidebarTitle}</h2>}
          <div className='product-description' dangerouslySetInnerHTML={{__html: converter.makeHtml(page.sidebarContent)}}></div>
        </div>
      </div>
    </div>
  )
}
