import React, {useState, useEffect, useRef} from 'react';
import TextControl from '../TextControl';
import NumberControl from '../NumberControl';
import FileControl from '../FileControl';
import SelectControl from '../SelectControl';
import BinaryControl from '../BinaryControl';
import RichControl from '../RichControl';
import MultiSelect from '../MultiSelect';
import MarkdownControl from '../MarkdownControl';
import DateTimeControl from '../DateTimeControl';
import slugify from 'slugify';
import moment from 'moment';

const useForceUpdate = () => useState()[1];

export default function SubEditForm({ setSub, sub, newCover, uploading = false }) {
  const [newPerson, setNewPerson] = useState('');
  const [personType, setPersonType] = useState('authors');
  const [error, setError] = useState('Checking access...');
  const [login, setLogin] = useState(false);

  const updateSub = (field, value) => {
    let newSub = {...sub};
    newSub[field] = value;
    setSub(sub => newSub);
  }

  const updateSubObj = (obj, field, value) => {
    let newSub = {...sub};
    if (!newSub[obj]) {
      newSub[obj] = {};
    }
    newSub[obj][field] = value;
    setSub(newSub);
  }

  if (!sub.prices) {
    return (<div></div>)
  }


  return (
    <div className='admin-two'>
      <div className='admin-col-large'>
        <div className='standard-header mb-24'>
          <h2>General Info</h2>
        </div>
        <TextControl
          value={sub.title}
          change={(value) => {updateSub('title', value)}}
          label={'Subscription Title'}
          valid={false}
          type='text'
          disabled={uploading}
          required={true}
        />
        <TextControl
          value={sub.subtitle}
          change={(value) => {updateSub('subtitle', value)}}
          label={'Subtitle'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <BinaryControl
          value={sub.public}
          change={(value) => {updateSub('public', value)}}
          label={'Make Product Public'}
          help={'If no, it will only be visible to you.'}
          disabled={uploading}
        />


          <SelectControl
            classlist='med-input'
            value={sub.region}
            change={(value) => {updateSub('region', value)}}
            label={'Region'}
            help={''}
            disabled={uploading}
            options={[
              {
                value: 'US',
                label: 'United States'
              },
              {
                value: 'Int',
                label: 'International'
              },
              {
                value: 'CA',
                label: 'Canada'
              }
            ]}
          />

        <div className='standard-header mt-24 mb-24'>
          <h2>Pricing & Terms</h2>
        </div>
        <NumberControl
          classlist='med-input'
          value={sub.prices.list}
          change={(value) => {updateSubObj('prices', 'list', value)}}
          label={'List Price'}
          help={'Face value of the book; may or may not be encoded in your barcode.'}
          valid={false}
          disabled={uploading}
          step='.01'
        />
        <NumberControl
          classlist='med-input'
          value={sub.prices.retail}
          change={(value) => {updateSubObj('prices', 'retail', value)}}
          label={'Retail Price'}
          help={'What you sell it for.'}
          valid={false}
          disabled={uploading}
          step='.01'
        />
        <TextControl
          classlist='small-input'
          value={sub.prices && sub.prices.retailStripe}
          change={(value) => {updateSubObj('prices', 'retailStripe', value)}}
          label={'Stripe Price ID'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
          required={true}
        />

        <TextControl
          classlist='small-input'
          value={sub.prices && sub.prices.period}
          change={(value) => {updateSubObj('prices', 'period', value)}}
          label={'Period of subscription (display)'}
          help={''}
          valid={false}
          type='text'
          required={true}
        />

        <BinaryControl
          value={sub.prices.recurring}
          change={(value) => {updateSubObj('prices', 'recurring', value)}}
          label={'Recurring?'}
          help={'Exactly what it says'}
          disabled={uploading}
        />

        <BinaryControl
          value={sub.prices.gift}
          change={(value) => {updateSubObj('prices', 'gift', value)}}
          label={'Gift Subscription?'}
          help={'Exactly what it says'}
          disabled={uploading}
        />

        <hr />

        <div className='standard-header mt-24 mb-24'>
          <h2>Description</h2>
        </div>

        <MarkdownControl
          label='Description'
          disabled={false}
          value={sub.description}
          change={(value) => {updateSub('description', value)}}
          disabled={uploading}
          rows={14}
        />

      </div>
      <div className='admin-col-small'>
        <div className='standard-header mb-24'>
          <h2>Cover</h2>
        </div>
        {!sub.cover &&
          <FileControl
            classlist=''
            label={'Cover Image'}
            help={'.'}
            valid={false}
            reference={newCover}
            disabled={uploading}
            image={true}
            accept="image/png, image/jpeg"
          />
        }

        {sub.cover && <img className='cover-preview' src={sub.cover} />}
        {sub.cover && <button className='secondary-submit' onClick={() => {updateSub('cover', '')}}>Remove/Change Cover</button>}

      </div>
    </div>
  )
}
