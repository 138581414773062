import React, {useState, useEffect, useRef} from 'react';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import OutletForm from '../../components/OutletEditForm';
import {uploadFile} from '../../Utils.js';
import slugify from 'slugify';
import moment from 'moment';

export default function AccountOutletAdd({ showMessage, userId }) {
  const defaultOutlet = {
    name: '',
    description: '',
    notes: '',
    primaryContact: '',
    primaryEmail: '',
    comments: [],
    website: '',
    twitter: '',
    facebook: '',
    instagram: '',
    reviewCopyLead: 0,
    categories: [],
    featureTypes: []
  };
  const [outlet, setOutlet] = useState(defaultOutlet);
  const [error, setError] = useState('');
  const [login, setLogin] = useState(false);

  let history = useHistory();

  document.title = 'Creating Outlet | Sublunary Admin';


  const submit = async () => {
    let data = await window.ASTERISM.makeRequest(`create-outlet?id=${userId}`, 'POST', outlet);
    if (data.ok) {
      showMessage({text: 'Outlet successfully created!', temp: true, type: 'success'});
      history.push(`/my-account/outlets/${data.id}`);
    }
  }

  if (error) {
    return (
      <div className='edit-bar'><div className='edit-title'>{error} {login && <Link to={`/login?return=${window.location.pathname}`}><button className='secondary-submit'>Login</button></Link>}</div></div>
    )
  }

  return (
    <div className=''>
      <div className='edit-bar'>
        <div className='edit-title'>Creating a New Outlet</div>
        <button className='main-submit' onClick={submit}>Create Outlet</button>
      </div>
      <OutletForm setPage={setOutlet} page={outlet} />
    </div>
  )
}
