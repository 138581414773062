import React, {useState, useEffect, useRef} from 'react';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import PostForm from '../../components/PostEditForm';
import slugify from 'slugify';
import moment from 'moment';


export default function AccountPostEdit({ showMessage, userId }) {
  const [post, setPost] = useState({});
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [login, setLogin] = useState(false);

  const updatePost = (field, value) => {
    let newPost = {...post};
    newPost[field] = value;
    setPost(newPost);
  }

  let { id } = useParams();

  const featuredImage = useRef();


  document.title = 'Editing Post | Asterism';

  const getPost = async () => {
    let data = await window.ASTERISM.makeRequest(`private-post?id=${id}`, 'GET');
    setPost(data);
  }


  useEffect(() => {
    getPost();
  }, []);


  const submit = async () => {
    let updatedPost = {...post}
    if (featuredImage.current && featuredImage.current.files.length > 0) {
      updatedPost.featuredImage = await window.ASTERISM.uploadFile(featuredImage, true);
    }
    updatedPost.updated = moment().format('LLL');
    let data = await window.ASTERISM.makeRequest('update-post', 'POST', updatedPost);
    if (data.ok) {
      updatePost('_rev', data.rev);
      showMessage({text: 'Post successfully updated!', temp: true, type: 'success'})
    }
  }

  if (error) {
    return (
      <div className='edit-bar'><div className='edit-title'>{error} {login && <Link to={`/login?return=${window.location.pathname}`}><button className='secondary-submit'>Login</button></Link>}</div></div>
    )
  }

  return (
    <div className=''>
      <div className='edit-bar'>
        <div className='edit-title'>Editing <em>{post.title}</em></div>
        <button className='main-submit' onClick={submit}>Save Changes</button>
      </div>
      <PostForm setPost={setPost} post={post} featuredImage={featuredImage} uploading={uploading}/>
    </div>
  )
}
