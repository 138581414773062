import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import moment from 'moment';
import showdown from 'showdown';
const converter = new showdown.Converter();

export default function Catalogue() {

  window.title = 'Empyrean Series catalogue | Sublunary Editions'

  return (
    <div>
      <div className='page-header-new'>
        <h1>Empyrean Series Catalogue</h1>
        <p>All Empyrean Series titles are available at wholesale rates through <a href="https://asterismbooks.com/press/sublunary-editions">Asterism Books</a>.</p>
        <p>Download the catalogue <a href="https://sublunaryeditions.com/Empyrean_Catalogue_1-20_HighOutput.pdf">here</a>.</p>
      </div>
      <div className='admin-full'>
        <div className='pdf-viewer'>
          <object
              data="https://sublunaryeditions.com/Empyrean_Catalogue_1-20_HighOutput.pdf"
              width="100%"
              style={{aspectRatio: '16/9'}}>
          </object>
        </div>
      </div>
    </div>
  )
}
