import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import Paginator from '../../components/Paginator';
import SelectControl from '../../components/SelectControl';
import PersonEditModal from '../../components/PersonEditModal';
import PlanFormEditor from '../../components/PlanFormEditor';
import OutletEditModal from '../../components/OutletEditModal';
import { ReactComponent as Book } from '../../book.svg';
import moment from 'moment';

export default function AccountPitches({ userId, roles, userMenu, userName, showMessage }) {
  const [profile, setProfile] = useState({});

  const [error, setError] = useState('Unauthorized');
  const [orders, setOrders] = useState([]);
  const [descending, setDescending] = useState(true);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [sum, setSum] = useState(0);
  const [per, setPer] = useState(20);
  const [message, setMessage] = useState('');

  const [pitches, setPitches] = useState([]);

  const [outlets, setOutlets] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [publications, setPublications] = useState([]);

  const [publication, setPublication] = useState('');
  const [outlet, setOutlet] = useState('');
  const [contact, setContact] = useState('');
  const [status, setStatus] = useState('');

  const [creatingNew, setCreatingNew] = useState(false);
  const [creatingPerson, setCreatingPerson] = useState(false);
  const [creatingOutlet, setCreatingOutlet] = useState(false);
  const [editing, setEditing] = useState(-1);
  const [editingOutlet, setEditingOutlet] = useState(false);
  const [editingContact, setEditingContact] = useState(false);

  const getOutlets = async() => {
    let data = await window.ASTERISM.makeRequest(`list-all-outlets`, 'GET');
    setOutlets(data);
  }

  const getContacts = async() => {
    let data = await window.ASTERISM.makeRequest(`list-all-contacts`, 'GET');
    setContacts(data);
  }

  const getPublications = async() => {
    let data = await window.ASTERISM.makeRequest(`list-all-publications`, 'GET');
    setPublications(data);
  }

  useEffect(() => {
    getOutlets();
    getContacts();
    getPublications();
  }, []);

  const parseOptions = async () => {
    setPitches([]);
    setMessage('Searching for pitches...');
    let queryString = '';
    if (contact) {
      queryString += `&contact=${contact}`;
    }
    if (outlet) {
      queryString += `&outlet=${outlet}`;
    }
    if (publication) {
      queryString += `&publication=${publication}`;
    }
    if (status) {
      queryString += `&status=${status}`;
    }
    let data = await window.ASTERISM.makeRequest(`list-all-pitches?page=${page}&per=20${queryString}`, 'GET');
    setPitches(Object.values(data));
    if (Object.values(data).length == 0) {
      setMessage('No pitches found.');
    } else {
      setMessage('');
    }
  }

  useEffect(() => {
    parseOptions();
  }, [outlet, contact, status, publication, editing, creatingNew, page]);

  useEffect(() => {
    if (!creatingPerson) {
      getContacts();
      document.body.classList.remove('fixed');
    } else {
      document.body.classList.add('fixed');
    }
  }, [creatingPerson]);

  useEffect(() => {
    if (!creatingOutlet) {
      getOutlets();
      document.body.classList.remove('fixed');
    } else {
      document.body.classList.add('fixed');
    }
  }, [creatingOutlet]);

  useEffect(() => {
    if (creatingNew || editing >= 0 || editingContact || editingOutlet) {
      document.body.classList.add('fixed');
    } else {
      document.body.classList.remove('fixed');
    }
  }, [creatingNew, editing, editingContact, editingOutlet]);




  document.title = 'All Pitches | Sublunary Admin';

  const closeEditing = () => {
    setEditing(-1);
  }

  if (!userId || !roles.publisher) {
    return (
      <div className='space'>You are not authorized to view this page.</div>
    )
  }

  return (
    <div>
      <div className='admin-full '>
        <div className='admin-controls'>
          <div className='admin-buttons'>
            <button className='main-submit' onClick={() => setCreatingNew(true)}>+ Pitch</button>
            <button className='secondary-submit' onClick={() => setCreatingPerson(true)}>+ Contact</button>
            <button className='secondary-submit' onClick={() => setCreatingOutlet(true)}>+ Outlet</button>
          </div>
          <div className='admin-buttons'>
            <button className="main-submit" disabled={page == 0} onClick={() => {setPage(page - 1)}}>{'<'}</button>
            <button className="main-submit" disabled={pitches.length < 20} onClick={() => {setPage(page + 1)}}>{'>'}</button>
          </div>
        </div>
        <div className='admin-table-with-controls'>
          <div className='admin-table-controls'>
            <SelectControl
              value={contact}
              change={(value) => {setContact(value)}}
              label={'Contact'}
              options={[{value: '', label: '--'}].concat(contacts.sort((a, b) => {
                return b.value.toUpperCase() < a.value.toUpperCase();
              }).map((con) => {
                return {value: con.key,
                label: con.value};
              }))}
            />
            {contact && <a className='small-block-label mb-12' style={{marginTop: '-12px'}} onClick={() => {setEditingContact(contact)}}>View/Edit Contact</a>}

            <SelectControl
              value={outlet}
              change={(value) => {setOutlet(value)}}
              label={'Outlet'}
              options={[{value: '', label: '--'}].concat(outlets.sort((a, b) => {
                return b.value.toUpperCase() < a.value.toUpperCase();
              }).map((out) => {
                return {value: out.key,
                label: out.value};
              }))}
            />
            {outlet && <a className='small-block-label mb-12' style={{marginTop: '-12px'}} onClick={() => {setEditingOutlet(outlet)}}>View/Edit Outlet</a>}

            <SelectControl
              value={publication}
              change={(value) => {setPublication(value)}}
              label={'Title'}
              options={[{value: '', label: '--'}].concat(publications.sort((a, b) => {
                return b.value.toUpperCase() < a.value.toUpperCase();
              }).map((pub) => {
                return {value: pub.key,
                label: pub.value};
              }))}
            />

            <SelectControl
              value={status}
              change={(value) => {setStatus(value)}}
              label={'Status'}
              help={''}
              options={[
                {value: '', label: '--'},
                {value: 'Pitched', label: 'Pitched'},
                {valued: 'Requires Action', label: 'Requires Action'},
                {valued: 'Waiting', label: 'Waiting'},
                {valued: 'On Hold', label: 'On Hold'},
                {valued: 'Success', label: 'Success'},
                {valued: 'Declined', label: 'Declined'},
                {valued: 'Cancelled', label: 'Cancelled'}
              ]}
            />
          </div>
          <div className='admin-table-body'>
            <div className='table-header table-item admin-table'>
              <div className='order-col flex-small-nf bold'>Status</div>
              <div className='order-col flex-1 bold'>Contact / Outlet</div>
              <div className='order-col flex-1 bold'>Book</div>
              <div className='order-col flex-1 bold'>Type</div>
              <div className='order-col flex-small bold'><span className='small-book-icon mg'><Book /></span> Rq.</div>
              <div className='order-col flex-1 bold'>Created</div>
            </div>
            <div className='admin-orders'>
            {message && <div className='mt-24'>{message}</div>}
            {pitches.map((pitch, index) =>
              <div className='table-item' key={pitch._id} >
                <div className='order-col flex-small-nf'>
                  {pitch.status}
                  <a className='small-block-label' onClick={() => {setEditing(index)}}>Edit</a>
                </div>
                <div className='order-col flex-1 bold'>
                  {pitch.contact.name}
                  {pitch.outlet ? <span className='small-block-label'>{pitch.outlet.name}</span> : ''}
                  <span className='small-block-label'><a onClick={() => {setEditingContact(pitch.contact._id)}}>View/Edit Contact</a> | <a onClick={() => {setEditingOutlet(pitch.outlet._id)}}>View/Edit Outlet</a></span>
                </div>
                <div className='order-col flex-1'>{pitch.publication.title} <span className='small-block-label'>{pitch.publication.authors.join(', ')}</span></div>
                <div className='order-col flex-1'>{pitch.features.join(', ')}</div>
                <div className='order-col flex-small'>{!pitch.copyRequested && !pitch.copySent ? '' : pitch.copySent ? <span className='small-book-icon sent'><Book /></span> : <span className='small-book-icon not-sent'><Book /></span>}</div>
                <div className='order-col flex-1'>
                  {moment(pitch.created).format('LLL')}
                  {pitch.created !== pitch.updated && <span className='small-block-label'>Updated: {moment(pitch.updated).format('LLL')}</span>}
                </div>
              </div>
            )}
          </div>
        </div>
        {orders.length > 0 && <div className='mt-24 mb-24 text-center'><Paginator value={page} maxPages={Math.ceil(total / per)} change={setPage} /></div>}
        {creatingNew && <PlanFormEditor contactList={contacts} outletList={outlets} publicationList={publications} setClosed={setCreatingNew} showMessage={showMessage} />}
        {editing >= 0 && <PlanFormEditor data={pitches[editing]} contactList={contacts} outletList={outlets} publicationList={publications} setClosed={closeEditing} showMessage={showMessage} />}
        {creatingPerson && <PersonEditModal showMessage={showMessage} setClosed={setCreatingPerson} />}
        {editingContact && <PersonEditModal showMessage={showMessage} setClosed={setEditingContact} person={editingContact} />}
        {creatingOutlet && <OutletEditModal showMessage={showMessage} setClosed={setCreatingOutlet} />}
        {editingOutlet && <OutletEditModal showMessage={showMessage} setClosed={setEditingOutlet} outlet={editingOutlet} />}
        </div>
      </div>
    </div>
  )
}
