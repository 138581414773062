import React, {useState, useEffect} from 'react';
import {
  Link
} from "react-router-dom";
import Paginator from '../../components/Paginator';
import moment from 'moment';
import showdown from 'showdown';
const converter = new showdown.Converter();

export default function News({ api }) {
  const [posts, setPosts] = useState([]);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const getPosts = async () => {
    let perPage = 12;
    let data = await window.ASTERISM.makeRequest(`list-all-news?page=${page}&per=${perPage}`);
    let newPosts = posts.concat(data);
    setPosts(newPosts);
  }

  document.title = 'News | Sublunary Editions';
  document.querySelector('meta[name="description"]').setAttribute("content", 'The latest news from Sublunary Editions.');

  useEffect(() => {
    getPosts();
  }, [page]);

    return (
      <div>
        <div className='page-header-new'>
          <h1>News</h1>
          <p></p>
        </div>
        <div className='admin-full'>
          <div className='post-grid'>
          {posts.map((post) =>
            <div key={post._id} className='post-grid-item'>
              <div className='post-grid-frame'></div>
              <div className='post-grid-item-content'>
                <div className='subtitle'>{post.published}</div>
                <span class='title'><Link className='hov' to={`/news/${post.slug}`}>{post.title}</Link></span>
                <div className='subtitle'>by {post.byline}</div>
                <div className='excerpt' dangerouslySetInnerHTML={{__html: post.excerpt}}></div>
                <Link to={`/news/${post.slug}`} class='read-more-link'>Read More</Link>
              </div>
            </div>
          )}
          </div>
          <button className='secondary-submit big-secondary' onClick={() => setPage(page + 1)}>Load More</button>
        </div>
      </div>
    )
}
