import React, {useState, useEffect, useRef} from 'react';
import TextControl from '../TextControl';
import NumberControl from '../NumberControl';
import FileControl from '../FileControl';
import SelectControl from '../SelectControl';
import BinaryControl from '../BinaryControl';
import RichControl from '../RichControl';
import MultiSelect from '../MultiSelect';
import MarkdownControl from '../MarkdownControl';
import DateTimeControl from '../DateTimeControl';
import slugify from 'slugify';
import moment from 'moment';

const useForceUpdate = () => useState()[1];

export default function PageForm({ setPage, page, uploading = false }) {

  const updatePage = (field, value) => {
    let newPage = {...page};
    newPage[field] = value;
    setPage(page => newPage);
  }

  const intoSlug = (string) => {
    if (page.title) {
      return slugify(page.title, {lower: true, strict: true, });
    } else {
      return '';
    }
  }

  return (
    <div className='admin-two'>
      <div className='admin-col-large'>
        <TextControl
          value={page.title}
          change={(value) => {updatePage('title', value)}}
          label={'Page Title'}
          help={page.slug}
          valid={false}
          type='text'
          disabled={uploading}
        />
        <BinaryControl
          value={page.public}
          disabled={uploading}
          change={(value) => {updatePage('public', value)}}
          label={'Page Published'}
          help={'If not, it will only be visible to you.'}
        />
        <MarkdownControl
          label='Page Content'
          disabled={uploading}
          value={page.content}
          change={(value) => {updatePage('content', value)}}
          rows={20}
        />
      </div>
      <div className='admin-col-small'>
        <TextControl
          value={page.sidebarTitle}
          change={(value) => {updatePage('sidebarTitle', value)}}
          label={'Sidebar Title'}
          valid={false}
          type='text'
          disabled={uploading}
        />
        <MarkdownControl
          label='Sidebar Content'
          disabled={uploading}
          value={page.sidebarContent}
          change={(value) => {updatePage('sidebarContent', value)}}
          rows={10}
        />
      </div>
    </div>
  )
}
