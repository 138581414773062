import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams,
} from "react-router-dom";
import Paginator from '../../components/Paginator';
import ProductGridItem from '../../components/ProductGridItem';

export default function AllEbooks({ api, showMessage, addToCart }) {
  const [books, setBooks] = useState([]);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  const getBooks = async () => {
    setBooks([]);
    let newbooks = await fetch (`${api}list-all-ebooks?page=${page}&per=${perPage}`);
    let newdata = await newbooks.json();
    setBooks(newdata.books);
    setTotal(newdata.total);
  }

  let { category } = useParams();

  document.title = 'Browse All eBooks | Sublunary Editions';
  document.querySelector('meta[name="description"]').setAttribute("content", 'All eBook options from Sublunary Editions.');


  useEffect(() => {
    getBooks();
  }, []);

  useEffect(() => {
    getBooks();
  }, [page])

  return (
    <div>
      <div className='page-header-new mb-48'>
        <h1>All eBooks</h1>
      </div>
      <div className='product-grid'>
        {books.length > 0 && books.map((product) =>
          <ProductGridItem product={product} home={false} showMessage={showMessage} addToCart={addToCart} ebooks={true} />
        )}
      </div>
      {books.length > 0 && <div className='mt-24 mb-24 text-center'><Paginator value={page} maxPages={Math.ceil(total / perPage)} change={setPage} /></div>}

    </div>
  )
}
