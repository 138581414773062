import React, {useState, useEffect, useRef} from 'react';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import PersonForm from '../../components/PersonEditForm';
import PlanFormEditor from '../../components/PlanFormEditor';
import slugify from 'slugify';
import moment from 'moment';


export default function AccountPersonEdit({ showMessage, userId }) {
  const [person, setPerson] = useState({});
  const [error, setError] = useState('');
  const [login, setLogin] = useState(false);
  const [planning, setPlanning] = useState(true);

  const updatePerson = (field, value) => {
    let newPerson = {...person};
    newPerson[field] = value;
    setPerson(newPerson);
  }

  let { id } = useParams();

  document.title = 'Editing Person | Asterism';

  const getPerson = async () => {
    let data = await window.ASTERISM.makeRequest(`get-person?id=${id}`, 'GET');
    setPerson(data);
  }


  useEffect(() => {
    getPerson();
  }, []);

  useEffect(() => {
    if (planning) {
      document.body.classList.add('fixed');
    } else {
      document.body.classList.remove('fixed');
    }
  }, [planning]);


  const submit = async () => {
    let data = await window.ASTERISM.makeRequest('update-person', 'POST', person);
    if (data.ok) {
      updatePerson('_rev', data.rev);
      showMessage({text: 'Person successfully updated!', temp: true, type: 'success'})
    }
  }

  if (error) {
    return (
      <div className='edit-bar'><div className='edit-title'>{error} {login && <Link to={`/login?return=${window.location.pathname}`}><button className='secondary-submit'>Login</button></Link>}</div></div>
    )
  }

  return (
    <div className=''>
      <div className='edit-bar'>
        <div className='edit-title'>Editing <em>{person.name}</em></div>
        <div>
          <button className='main-submit' onClick={submit}>Save Changes</button>
          <button className='main-submit' onClick={() => {setPlanning(!planning)}}>Start Pitch</button>
        </div>
      </div>
      <PersonForm setPage={setPerson} page={person} />
      {planning && <PlanFormEditor person={person} setClosed={setPlanning} showMessage={showMessage} />}
    </div>
  )
}
