import React, {useState, useEffect, useRef} from 'react';
import moment from 'moment';
import TextControl from '../TextControl';
import NumberControl from '../NumberControl';
import FileControl from '../FileControl';
import SelectControl from '../SelectControl';
import SelectAndAddControl from '../SelectAndAddControl';
import BinaryControl from '../BinaryControl';
import RichControl from '../RichControl';
import MultiSelect from '../MultiSelect';
import MarkdownControl from '../MarkdownControl';
import DateTimeControl from '../DateTimeControl';
import OutletForm from '../OutletEditForm';

const defaultOutlet = {
  name: '',
  description: '',
  notes: '',
  primaryContact: '',
  primaryEmail: '',
  comments: [],
  website: '',
  twitter: '',
  facebook: '',
  instagram: '',
  reviewCopyLead: 0,
  categories: [],
  featureTypes: []
};

export default function OutletModal({ showMessage, setClosed, outlet = null  }) {
  const [newOutlet, setNewOutlet] = useState({});

  const getOutlet = async (id) => {
    let data = await window.ASTERISM.makeRequest(`get-outlet?id=${id}`, 'GET');
    setNewOutlet(data);
  }

  useEffect(() => {
    if (outlet) {
      getOutlet(outlet)
    } else {
      setNewOutlet(defaultOutlet)
    }
  }, [])

  const createOutlet = async () => {
    if (!newOutlet._id) {
      let data = await window.ASTERISM.makeRequest(`create-outlet`, 'POST', newOutlet);
      if (data.ok) {
        showMessage({text: 'Outlet successfully created!', temp: true, type: 'success'});
        setClosed();
      }
    } else {
      let data = await window.ASTERISM.makeRequest('update-outlet', 'POST', newOutlet);
      if (data.ok) {
        showMessage({text: 'Outlet successfully updated!', temp: true, type: 'success'});
        setClosed();
      }
    }
  };

  return(
    <div className='modal full'>
      <div className='modal-content full-sized'>
        <div className='standard-header'>
          <h2>{newOutlet._id ? 'Editing Outlet' : 'Create a New Outlet'}</h2>
        </div>

        <div className='modal-form'>
          {typeof(newOutlet.name) == 'string' && <OutletForm setPage={setNewOutlet} page={newOutlet} />}

        </div>

        <div className='button-controls'>
          <button className='main-submit' onClick={createOutlet}>{newOutlet._id ? 'Save Changes' : 'Create'}</button>
          <button className='secondary-submit' onClick={() => {setClosed(false)}}>Cancel</button>
        </div>
      </div>
    </div>
  )
}
