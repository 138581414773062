import React, { useEffect, useState } from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import TextControl from '../TextControl';
import SelectControl from '../SelectControl';
import BinaryControl from '../BinaryControl';
import TextAreaControl from '../TextAreaControl';

export default function Barcode() {
  const [no, setNo] = useState('');
  const [valid, setValid] = useState('');
  const [svg, setSvg] = useState('');
  const [font, setFont] = useState('Univers LT W01_45 Light1475944');
  const [customFont, setCustomFont] = useState('');
  const [topSize, setTopSize] = useState(12);
  const [bottomSize, setBottomSize] = useState(12);
  const [displayTop, setDisplayTop] = useState(true);
  const [displayBottom, setDisplayBottom] = useState(true);
  const [longHeight, setLongHeight] = useState(.54);
  const [shortHeight, setShortHeight] = useState(.50);
  const [marginTop, setMarginTop] = useState(.04);
  const [marginBottom, setMarginBottom] = useState(.08);
  const [w, setW] = useState(15);
  const [background, setBackground] = useState('#ffffff')

  const calcHeight = () => {
    let height = 0;
    height += parseFloat(marginTop);
    height += parseFloat(marginBottom);
    if (displayTop) {
      height += 0.010416666666819 * parseFloat(topSize);
    }
    if (displayBottom) {
      height += 0.010416666666819 * parseFloat(bottomSize);
    }
    height += parseFloat(longHeight);
    return height;
  }

  const calcTextTop = () => {
    return parseFloat(marginTop) + (0.010416666666819 * parseFloat(topSize));
  }

  const calcBarTop = () => {
    let height = 0.04;
    height += parseFloat(marginTop);
    if (displayTop) {
      height += 0.010416666666819 * parseFloat(topSize);
    }
    return height;
  }

  const calcTextBottom = () => {
    return calcHeight() - parseFloat(marginBottom);
  }

  const ranges = {
          "978": {
              "0": [ /// English speaking
                  {s: "00", e: "19"},
                  {s: "200", e: "699"},
                  {s: "7000", e: "8499"},
                  {s: "85000", e: "89999"},
                  {s: "900000", e: "949999"},
                  {s: "9500000", e: "9999999"},
              ],
              "1": [ /// English speaking
                  {s: "00", e: "09"},
                  {s: "100", e: "327"},
                  {s: "328", e: "329"},
                  {s: "330", e: "399"},
                  {s: "4000", e: "5499"},
                  {s: "55000", e: "86979"},
                  {s: "869800", e: "998999"},
                  {s: "9990000", e: "9999999"},
              ],
          },
          "979": {}
      };
      const svg_head = `<?xml version=\"1.0\" encoding=\"UTF-8\"?><svg xmlns=\"http://www.w3.org/2000/svg\" width=\"2in\" height=\"${calcHeight()}in\" version=\"1.1\"><g id=\"isbn\"><rect width=\"2in\" height=\"${calcHeight()}in\" x=\"0\" y=\"0\" style=\"fill:${background};fill-opacity:1;stroke:none\"/>`;
      const svg_foot = "</g></svg>";
      const svg_bars_head = "<g id=\"bars\" style=\"fill:#000000;fill-opacity:1;stroke:none\">";
      const svg_bars_foot = "</g>";
      const svg_nums_head = `<g id=\"nums\" style=\"font-family:${font === 'other' ? customFont : font};font-size:${bottomSize}px;font-style:normal;font-variant:normal;font-weight:100;font-stretch:normal;letter-spacing:0px;word-spacing:0px;text-anchor:middle;fill:#000000;fill-opacity:1;stroke:none\">`;
      const svg_nums_foot = "</g>";
      const svg_text_head = `<g id=\"text\" style=\"font-family:${font === 'other' ? customFont : font};font-size:${topSize}px;font-style:normal;font-variant:normal;font-weight:100;font-stretch:normal;letter-spacing:0px;word-spacing:0px;text-anchor:middle;fill:#000000;fill-opacity:1;stroke:none\">`;
      const svg_text_foot = "</g>";
      const ean_13_structure = ["LLLLLL", "LLGLGG", "LLGGLG", "LLGGGL", "LGLLGG", "LGGLLG", "LGGGLL", "LGLGLG", "LGLGGL", "LGGLGL"];
      const ean_13_structure2 = "RRRRRR";
      const codes = {
          L: ["0001101", "0011001", "0010011", "0111101", "0100011", "0110001", "0101111", "0111011", "0110111", "0001011"],
          R: ["1110010", "1100110", "1101100", "1000010", "1011100", "1001110", "1010000", "1000100", "1001000", "1110100"],
          G: ["0100111", "0110011", "0011011", "0100001", "0011101", "0111001", "0000101", "0010001", "0001001", "0010111"]
      };
      const code_bars = {
          L: [],
          R: [],
          G: []
      };
      const y = `${calcBarTop()}in`;

  const clean_isbn = (isbn) => {
    ///NOTE: The checksum value for ISBN 10 can be an X (repersenting the number 10).
    return String(isbn).toUpperCase().replace(/[^\dX]+/g, "");
  }

  const startX = () => {
    return 280;
  }

  const calculate_check_10 = (isbn) => {
      let tmp = 0;
      for (let i = 0; i < 9; i += 1) {
          tmp += isbn[i] * (10 - i);
      }
      tmp = 11 - (tmp) % 11;
      if (tmp === 10) {
          tmp = "X";
      }
      return tmp;
  }

  const calculate_check_13 = (isbn) => {
    let tmp = 0;
    for (let i = 0; i < 12; i += 1) {
        tmp += isbn[i] * (i % 2 ? 3 : 1);
    }
    return (10 - tmp % 10) % 10;
  }

  const check_10 = (isbn) => {
    isbn = clean_isbn(isbn);
    if (isbn.length !== 10) {
        return false;
    }

    ///NOTE: Since the checksum can be "X", we need to make sure it's a string.
    return String(calculate_check_10(isbn)) === isbn[9];
  }

  const check_13 = (isbn) => {
    isbn = clean_isbn(isbn);
    if (isbn.length !== 13) {
        return false;
    }

    return calculate_check_13(isbn) === Number(isbn[12]);
  }

  const validate = (isbn) => {
    return check_10(isbn) || check_13(isbn);
  }

  const format_num = (num) => {
    return (num / 1000) + "in";
  };

  /**
  * get_marker
  */
  const get_marker = (x) => {
    return "<rect width=\"" + format_num(w) + "\" height=\"" + longHeight + "in\" x=\"" + format_num(x * w + startX()) + "\" y=\"" + y + "\"/>" +
           "<rect width=\"" + format_num(w) + "\" height=\"" + longHeight + "in\" x=\"" + format_num((x + 2) * w + startX()) + "\" y=\"" + y + "\"/>";
  }

  /**
  * determine bars
  */
  const determine_bars = (col, digit) => {
      let bars = [],
          code = codes[col][digit],
          which = 0;

      for (let i = 0; i < 7; i += 1) {
          if (code[i] === "1") {
              /// Did it find a new line?
              if (!bars[which]) {
                  bars[which] = {offset: i, len: 1};
              } else {
                  bars[which].len += 1;
              }
          } else {
              /// Is it at the end of a line?
              if (bars[which]) {
                  which += 1;
                  /// Did it find both?
                  if (which > 1) {
                      break;
                  }
              }
          }
      }

      /// Cache results.
      code_bars[col][digit] = bars;

      return bars;
  }

  /**
  * get bars
  */
  const get_bars = (col, digit, offset) =>  {
      let bars = code_bars[col][digit] || determine_bars(col, digit),
          svg = "";

      bars.forEach((bar) => {
          svg += "<rect width=\"" + format_num(w * bar.len) + "\" height=\"" + shortHeight + "in\" x=\"" + format_num((offset + bar.offset) * w + startX()) + "\" y=\"" + y + "\"/>";;
      });

      return svg;
  }

  /**
  * Geenrate the bars
  */
  const generate_bars = (isbn) => {
    let bars = get_marker(0), /// Create start marker
        structure = ean_13_structure[Number(isbn[0])] + ean_13_structure2,
        offset = 3; ///NOTE: The start marker is 3 width.

    ///NOTE: The first digit is skipped because it changes the structure.
    for (let i = 1; i < 13; i += 1) {
        bars += get_bars(structure[i - 1], isbn[i], offset);
        offset += 7; ///NOTE: Each set of bars take up 7 places.
        if (i === 6) {
            bars += get_marker(offset + 1);
            offset += 5;
        }
    }

    /// Create end marker
    bars += get_marker(offset);

    return bars;
  }

  const make_mono = (isbn) => {
      return isbn.replace(/0/g, "O"); /// O's look better than zeros.
  }

  /**
  * generate _nums
  */
  const generate_nums = (isbn) =>  {

    let y = calcTextBottom() + 'in';

      return `
        <text><tspan x=".20in" y="${y}">${isbn[0]}</tspan></text>
        <text><tspan x=".62in" y="${y}">${isbn.substr(1, 6)}</tspan></text>
        <text><tspan x="1.33in" y="${y}">${isbn.substr(7, 6)}</tspan></text>
        <text><tspan x="1.8in" y="${y}">&gt;</tspan></text>
      `;
  }

  const convert = (isbn, which) =>  {
      isbn = clean_isbn(isbn);
      if (isbn.length === 10) {
          /// If we really want the 10, just calculate the checksum.
          if (which === 10) {
              return isbn.substr(0, 9) + calculate_check_10(isbn);
          }
          return "978" + isbn.substr(0, 9) + calculate_check_13("978" + isbn);
      } else if (isbn.length === 13) {
          /// If we really want the 13, just calculate the checksum.
          if (which === 13) {
              return isbn.substr(0, 12) + calculate_check_13(isbn);
          }
          if (isbn.substr(0, 3) === "978") {
              return isbn.substr(3, 9) + calculate_check_10(isbn.substr(3, 9));
          } else {
              return isbn; /// Unconvertable
          }
      }
  }

  /**
  * Hyphenate 10
  */
  const hyphenate_10 = (isbn, prefix) => {
      let i,
          group,
          group_len,
          publisher_len,
          tmp_str;

      if (isbn.length !== 10) {
          return isbn;
      }

      if (!prefix) {
          prefix = "978";
      }

      if (!ranges[prefix]) {
          return isbn;
      }

      for (i = 1; i <= 7; i += 1) {
          tmp_str = isbn.substr(0, i);
          if (ranges[prefix][tmp_str]) {
              group = ranges[prefix][tmp_str];
              group_len = i;
              break;
          }
      }

      for (i = group_len; i <= 7; i += 1) {
          tmp_str = isbn.substr(group_len, i);
          group.some(function onsome(subrange)
          {
              if (subrange.s.length === i) {
                  if (Number(tmp_str) >= subrange.s && Number(tmp_str) <= subrange.e) {
                      publisher_len = i;
                      return true; /// break
                  }
              }
          });
          if (publisher_len) {
              break;
          }
      }

      if (group_len && publisher_len) {
          return isbn.substr(0, group_len) + "-" + isbn.substr(group_len, publisher_len) + "-" + isbn.substr(group_len + publisher_len, 10 - (group_len + publisher_len) - 1) + "-" + isbn[9];
      }

      return isbn;
  }

  /**
  * Hyphenate
  */
  const hyphenate = (isbn) =>  {
      isbn = clean_isbn(isbn);
      if (isbn.length === 10) {
          return hyphenate_10(isbn);
      } else if (isbn.length === 13) {
          return isbn.substr(0, 3) + "-" + hyphenate_10(isbn.substr(3, 10), isbn.substr(0, 3));
      } else {
          return isbn;
      }
  }

  /**
  * Generate Text
  */
  const generate_text = (isbn) => {
      let y = "y=\"0.13in\"";
      ///NOTE: ISBN 10 is commonly used above.
      ///NOTE: N-dashes look better than hyphens.
      isbn = hyphenate(isbn).replace(/-/g, "–");

      return `<text><tspan x="1in" y="${calcTextTop()}in">ISBN ${isbn}</tspan></text>`
  }

  /**
  * Parent generator function
  */
  const generate = () => {
    let svg = "";

    let isbn = clean_isbn(no);

    if (isbn.length === 10) {
        ///NOTE: We just create 13 barcodes.
        //isbn = convert(isbn);
    }

    if (displayTop) {
      svg += svg_text_head + generate_text(isbn) + svg_text_foot;
    }

    svg += svg_bars_head + generate_bars(isbn) + svg_bars_foot;

    if (displayBottom) {
      svg += svg_nums_head + generate_nums(isbn) + svg_nums_foot;
    }

    return svg_head + svg + svg_foot;
  }

  const save = (type, url) => {
    let link = document.createElement('a');
    link.download = `${no}.${type}`;
    link.style.opacity = "0";
    document.body.appendChild(link);
    link.href = url;
    link.click();
    link.remove();
  }

  const download = async (type) => {
    let width = 2 * 96 * 3;
    let height = calcHeight() * 96 * 3;
    let svg = generate();
    let blob = new Blob([svg],{type:'image/svg+xml;charset=utf-8'});
    let URL = window.URL || window.webkitURL || window;
    let blobURL = URL.createObjectURL(blob);
    if (type === 'svg') {
      save(type, blobURL);
      return;
    }
    let image = new Image();
    image.src = blobURL;
    image.onload = () => {
      let canvas = document.createElement('canvas');
      canvas.width =  width;
      canvas.height = height;
      console.log(canvas);
      let context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, width, height );
      let png = canvas.toDataURL("image/png");
      save(type, png);
    };
  }

  return (
    <div className='calc'>
      <div className=''>
        <h3>Barcode Generator</h3>
          <div className='barcode-section mb-24'>
            {!validate(no) && 'Enter a valid ISBN to generate a barcode image.'}
            {validate(no) &&
              <div className='barcode-preview'>
                <label className='form-label'>Preview</label>
                <div dangerouslySetInnerHTML={{__html: generate()}}></div>
                <TextAreaControl
                  value={generate()}
                  change={setSvg}
                  label={'SVG Code'}
                  valid={false}
                  disabled={true}
                  rows='10'
                />

                <button className='main-submit mr-24' onClick={() => {download('svg')}}>Download SVG</button>
                <button className='main-submit' onClick={() => {download('png')}}>Download PNG</button>
              </div>
            }
          </div>
        </div>
        <div className='main-form'>
          <TextControl
            value={no}
            change={setNo}
            label={'ISBN Number'}
            help={'Enter ISBN 13 with or without dashes.'}
            valid={validate(no)}
          />
          <SelectControl value={font}
            change={setFont}
            label={'Font'}
            help={'Select a font or choose the Other option to set your own'}
            options={[
              {
                value: 'Univers LT W01_75 Black1475980',
                label: 'Univers Black'
              },
              {
                value: 'Univers LT W01_45 Light1475944',
                label: 'Univers Light'
              },
              {
                value: 'Verdana',
                label: 'Verdana',
              },
              {
                value: 'Arial',
                label: 'Arial',
              },
              {
                value: 'Courier',
                label: 'Courier',
              },
              {
                value: 'other',
                label: 'Other',
              }
            ]}
          />
          {font === 'other' &&
            <TextControl
              value={customFont}
              change={setCustomFont}
              label={'Custom Font'}
              help={'Enter the name of the custom font you\'d like to use. Note that it must be installed on your computer to work.'}
              valid={false}
            />
          }
          <TextControl
            value={background}
            change={setBackground}
            label={'Background Color'}
            valid={false}
          />

          <TextControl
            value={topSize}
            change={setTopSize}
            label={'Top Text Font Size'}
            type='number'
            step={'.01'}
            valid={false}
          />
          <BinaryControl
            value={displayTop}
            change={setDisplayTop}
            label={'Display ISBN at the top?'}
          />

          <TextControl
            value={bottomSize}
            change={setBottomSize}
            label={'Bottom Text Font Size'}
            type='number'
            step={'.01'}
            valid={false}
          />

          <BinaryControl
            value={displayBottom}
            change={setDisplayBottom}
            label={'Display EAN at the bottom?'}
          />

          <TextControl
            value={marginTop}
            change={setMarginTop}
            label={'Top margin of the barcode'}
            help={'White space between the ISBN/bars and the border'}
            type='number'
            valid={false}
            step='0.01'
          />

          <TextControl
            value={marginBottom}
            change={setMarginBottom}
            label={'Bottom margin of the barcode'}
            help={'White space between the ISBN/bars and the border'}
            type='number'
            valid={false}
            step='0.01'
          />

          <TextControl
            value={longHeight}
            change={setLongHeight}
            label={'Height in inches of marker bars'}
            type='number'
            valid={false}
            step='0.01'
          />

          <TextControl
            value={shortHeight}
            change={setShortHeight}
            label={'Height in inches of short bars'}
            type='number'
            valid={false}
            step='0.01'
          />

          <TextControl
            value={w / 1000}
            change={(value) => {
              setW(value * 1000)
            }}
            label={'Baseline bar width in in'}
            type='number'
            step='0.001'
            valid={false}
          />
        </div>
    </div>
  )
}
