import React, { useEffect, useState } from 'react';

const useForceUpdate = () => useState()[1];

export default function FileControl( {reference, image = false, label = '', help = '', valid = true, type = 'text', classlist = '', disabled = false, accept = '', multiple = false } ) {
  const forceUpdate = useForceUpdate();

  return (
    <div className={`text-control ${classlist}`}>
      {label && <label className='form-label'>{label}</label>}
      <input onChange={forceUpdate} disabled={disabled ? 'disabled' : ''} type='file' className={`${valid && 'valid'}`} accept={accept} ref={reference} multiple={multiple ? 'multiple' : false} />
      {help && <div className='form-help'>{help}</div>}
      {image && reference.current && reference.current.files.length === 1 &&
        <img className='cover-preview' src={URL.createObjectURL(reference.current.files[0])} />
      }
      {image && reference.current && reference.current.files.length > 1 &&
        Array.from(reference.current.files).map((img) =>
        <img key={URL.createObjectURL(img)} className='cover-preview small' src={URL.createObjectURL(img)} />
      )
      }
    </div>
  )
}
