import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import moment from 'moment';
import {getMenus} from '../../Menus.js';
import { ReactComponent as Book } from '../../book.svg';

export default function AccountSubs({ showMessage, userId, roles, userMenu, userName }) {
  const [subs, setSubs] = useState([]);
  const [error, setError] = useState('');
  const [login, setLogin] = useState(false);

  document.title = 'All Subscription Plans | Asterism Admin';

  const getProducts = async () => {
    let data = await window.ASTERISM.makeRequest('private-subs', 'GET');
    setSubs(data);
  }

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div>
      <div className='admin-full'>

        <div className='standard-header'>
          <h2>All Subs</h2>
          <Link to={`/my-account/add-sub`}><button className='secondary-submit'>+ Add Subscription</button></Link>
        </div>
        <div className='table-header table-item'>
          <div className='flex-1'>Title</div>
          <div className='flex-1'>ID</div>
          <div className='flex-1'>Status</div>
        </div>
        <div className='admin-orders'>
        {subs.map((product, index) =>
          <div key={product._id} className='cart-item'>
            <span className='col'>
              <div className='product-list-title'>
                <Link to={`/my-account/sub/${product._id}`}>{product.title}</Link>
              </div>
            </span>
            <span className='col'>{product.prices ? product.prices.retailStripe : ''}</span>
            <span className='col'>{product.public ? 'Public' : 'Private'}</span>
          </div>
        )}
        {subs.length == 0 && <div className='mt-24'><em>You don't have any subscriptions yet.</em></div>}

        </div>
      </div>

    </div>
  )
}
