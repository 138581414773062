import React, {useEffect, useState} from 'react';
import NumberControl from '../../components/NumberControl';
import SelectControl from '../../components/SelectControl';
import TextControl from '../../components/TextControl';
import TextAreaControl from '../../components/TextAreaControl';
import DateTimeControl from '../../components/DateTimeControl';
import moment from 'moment';

export default function EditOrder({order, keys, setExpanded, updateOrder}) {
  const defaultOrder = {
    "created": "",
    "customer": "M",
    "name": "",
    "address": {
      "city": "",
      "country": "US",
      "line1": "",
      "line2": "",
      "postal_code": "",
      "state": ""
    },
    "email": "",
    "items": [],
    "notes": ""
  };
  const [editedOrder, setEditedOrder] = useState(order ? {...order} : {...defaultOrder});
  const [selected, setSelected] = useState('');

  const updateQuantity = (index, value) => {
    let newOrder = {...editedOrder};
    newOrder.items[index].quantity = value;
    setEditedOrder(newOrder);
  }

  const updateProperty = (value, property, object = null) => {
    let newOrder = {...editedOrder};
    if (object) {
      newOrder[object][property] = value;
    } else {
      newOrder[property] = value;
    }
    setEditedOrder(newOrder);
  }

  const toggleShipped = (index) => {
    let newOrder = {...editedOrder};
    newOrder.items[index].shipped = !editedOrder.items[index].shipped;
    setEditedOrder(newOrder);
  }

  const addBook = () => {
    let newOrder = {...editedOrder};
    let extant = editedOrder.items.map((item) => item.product);
    let index = extant.indexOf(selected);
    if (index >= 0) {
      newOrder.items[index].quantity ++;
    } else {
      newOrder.items.push({
        name: keys.byProd[selected],
        product: selected,
        quantity: 1,
        shipped: false,
      })
    }
    setEditedOrder(newOrder)
  }

  return (
    <div className='modal'>
      <div className='modal-content auto-height'>
        <div className='standard-header'>
          <h2>{order && order._id ? `Edit Order ${order._id}` : 'Add New Order'}</h2>
        </div>

        <div className='modal-form'>
          <div className='addy'>
            <div><strong>{editedOrder.name}</strong></div>
            {editedOrder.address &&
              <div>
                <div>{editedOrder.address.line1}</div>
                {editedOrder.address.line2 && <div>{editedOrder.address.line2}</div>}
                <div>{editedOrder.address.city} {editedOrder.address.state} {editedOrder.address.postal_code}</div>
                <div>{editedOrder.address.country}</div>
              </div>
            }
          </div>

          <hr />

          <DateTimeControl
            value={editedOrder.created ? editedOrder.created : new Date().toString()}
            change={(value) => {updateProperty(value, 'created')}}
            label={'Created Date/Time'}
            time={true}
          />

          <div className='input-group'>
            <TextControl
              value={editedOrder.name}
              change={(value) => {updateProperty(value, 'name')}}
              label={'Shipping Name'}
              valid={false}
              type='text'
            />

            <TextControl
              value={editedOrder.email}
              change={(value) => {updateProperty(value, 'email')}}
              label={'Order Email'}
              valid={false}
              type='text'
            />
          </div>

          <div className='input-group'>
            <TextControl
              value={editedOrder.address.line1}
              change={(value) => {updateProperty(value, 'line1', 'address')}}
              label={'Shipping Address 1'}
              valid={false}
              type='text'
            />

            <TextControl
              value={editedOrder.address.line2}
              change={(value) => {updateProperty(value, 'line2', 'address')}}
              label={'Shipping Address 2'}
              valid={false}
              type='text'
            />
          </div>

          <div className='input-group'>
            <TextControl
              value={editedOrder.address.city}
              change={(value) => {updateProperty(value, 'city', 'address')}}
              label={'Shipping City'}
              valid={false}
              type='text'
            />

            <TextControl
              value={editedOrder.address.state}
              change={(value) => {updateProperty(value, 'state', 'address')}}
              label={'Shipping State/Region'}
              valid={false}
              type='text'
            />

            <TextControl
              value={editedOrder.address.postal_code}
              change={(value) => {updateProperty(value, 'postal_code', 'address')}}
              label={'Shipping Postal Code'}
              valid={false}
              type='text'
            />

            <TextControl
              value={editedOrder.address.country}
              change={(value) => {updateProperty(value, 'country', 'address')}}
              label={'Shipping Country Code'}
              valid={false}
              type='text'
            />
          </div>

          <TextControl
            value={editedOrder.shipping}
            change={(value) => {updateProperty(value, 'shipping')}}
            label={'Shipping'}
            valid={false}
            type='text'
          />

          <TextAreaControl
            value={editedOrder.notes}
            change={(value) => {updateProperty(value, 'notes')}}
            label={'Notes'}
            valid={false}
            type='text'
          />

          <div className='order-line-items'>
            <strong>Line Items</strong>
            {editedOrder.items && editedOrder.items.map((item, index) =>
              <div key={item.product} className='cart-item'>
                <div className='flex-1'>{item.name}</div>
                <div className='flex-1'>{item.product}</div>
                <div className='flex-1'><a onClick={() => {toggleShipped(index)}}>{item.shipped ? 'Shipped' : 'Not Shipped'}</a></div>
                <div className='flex-1 col'>
                  <NumberControl
                    classlist='cart-counter'
                    value={item.quantity}
                    change={(value) => {updateQuantity(index, parseInt(value))}}
                    min={0}
                  />
                </div>
              </div>
            )}
          </div>

          <div className='add-title'>
            <SelectControl
              label={'Add a Book'}
              value={selected}
              change={(value) => {setSelected(value)}}
              options={[
                {
                  value: '',
                  label: '--'
                }
              ].concat(Object.entries(keys.byName).map((opt) => { return {value: opt[1], label: [opt[0]]} }))}
            />
            {selected && <a onClick={addBook}>Add</a>}
          </div>
        </div>

        <div className='button-controls'>
          <button className='main-submit' onClick={() => {updateOrder(editedOrder)}}>Save Changes</button>
          <button className='secondary-submit' onClick={() => {setExpanded(null)}}>Cancel</button>
        </div>
      </div>
    </div>
  )
}
