import React, { useState, useEffect, useRef } from 'react';
import TextControl from '../TextControl';
import NumberControl from '../NumberControl';
import FileControl from '../FileControl';
import SelectControl from '../SelectControl';
import BinaryControl from '../BinaryControl';
import RichControl from '../RichControl';
import MultiSelect from '../MultiSelect';
import MarkdownControl from '../MarkdownControl';
import DateTimeControl from '../DateTimeControl';
import slugify from 'slugify';
import moment from 'moment';
import { Link } from "react-router-dom";
import { returnImageMarkup } from '../../Utils';

const useForceUpdate = () => useState()[1];

export default function PostForm({ setPost, post, featuredImage, uploading = false }) {

  const updatePost = (field, value) => {
    let newPost = { ...post };
    newPost[field] = value;
    setPost(post => newPost);
  }

  const postcats = ['News', 'Poetry', 'Fiction', 'Essay', 'Firmament', 'Empyrean'];

  const intoSlug = (string) => {
    if (post.title) {
      return slugify(post.title, { lower: true, strict: true, });
    } else {
      return '';
    }
  }

  return (
    <div className='admin-two'>
      <div className='admin-col-large'>
        {post._id && <Link className='smaller' to={`/magazine/${post.slug}`}>View Post</Link>}
        <TextControl
          value={post.title}
          change={(value) => { updatePost('title', value) }}
          label={'Post Title'}
          help={post.slug}
          valid={false}
          type='text'
          disabled={uploading}
        />
        <TextControl
          value={post.subtitle}
          change={(value) => { updatePost('subtitle', value) }}
          label={'Subtitle'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
        />
        <TextControl
          value={post.byline}
          change={(value) => { updatePost('byline', value) }}
          label={'Byline'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
        />
        <BinaryControl
          value={post.public}
          disabled={uploading}
          change={(value) => { updatePost('public', value) }}
          label={'Post Published'}
          help={'If not, it will only be visible to you.'}
        />
        <DateTimeControl
          value={post.published}
          change={(value) => { updatePost('published', value) }}
          label={'Publish Date/Time'}
          help={'When the post will display as having been originally published'}
          disabled={uploading}
          time={true}
        />
        <MultiSelect
          classList=''
          values={post.categories ? post.categories : []}
          change={(value) => { updatePost('categories', value) }}
          choices={postcats}
          label={'Categories'}
          help={'Select any useful category designations'}
          disabled={uploading}
        />
        <MarkdownControl
          label='Post Content'
          disabled={uploading}
          value={post.content}
          change={(value) => { updatePost('content', value) }}
          rows={20}
        />

        <MarkdownControl
          label='Contrib Bio'
          disabled={uploading}
          value={post.contribBio}
          change={(value) => { updatePost('contribBio', value) }}
          rows={5}
        />

        <BinaryControl
          value={post.dropCap}
          disabled={uploading}
          change={(value) => { updatePost('dropCap', value) }}
          label={'Use Dropcap'}
        />

        <MarkdownControl
          label='Citations'
          disabled={uploading}
          value={post.citation}
          change={(value) => { updatePost('citation', value) }}
          rows={3}
        />
      </div>
      <div className='admin-col-small'>
        <div className='image-section'>
          {!post.featuredImage &&
            <FileControl
              classlist=''
              label={'Featured Image'}
              help={'.'}
              valid={false}
              reference={featuredImage}
              disabled={uploading}
              image={true}
              accept="image/png, image/jpeg"
            />
          }

          {post.featuredImage && returnImageMarkup(post.featuredImage, `Featured image for ${post.title}`, 'cover-preview')}
          {post.featuredImage && <button className='secondary-submit' onClick={() => { updatePost('featuredImage', '') }}>Remove/Change Image</button>}
        </div>
        <MarkdownControl
          label='Excerpt'
          disabled={uploading}
          value={post.excerpt}
          change={(value) => { updatePost('excerpt', value) }}
          rows={5}
        />

        <TextControl
          value={post.issue}
          change={(value) => { updatePost('issue', value) }}
          label={'Issue'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <TextControl
          value={post.related}
          change={(value) => { updatePost('related', value) }}
          label={'Related Products'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
        />
      </div>
    </div>
  )
}
