import React, { useState, useEffect, useRef } from 'react';
import TextControl from '../TextControl';
import NumberControl from '../NumberControl';
import FileControl from '../FileControl';
import SelectControl from '../SelectControl';
import BinaryControl from '../BinaryControl';
import RichControl from '../RichControl';
import MultiSelect from '../MultiSelect';
import MarkdownControl from '../MarkdownControl';
import DateTimeControl from '../DateTimeControl';
import slugify from 'slugify';
import moment from 'moment';
import { Link } from "react-router-dom";
import { getSource, returnImageMarkup } from '../../Utils';


const useForceUpdate = () => useState()[1];

export default function ProductForm({ setProduct, product, previewFile, newCover, newFile, ebookFile, uploading = false, showMessage }) {
  const [newPerson, setNewPerson] = useState('');
  const [personType, setPersonType] = useState('authors');
  const [newFileName, setNewFileName] = useState('');
  const [newFileDescription, setNewFileDescription] = useState('');
  const [error, setError] = useState('Checking access...');
  const [login, setLogin] = useState(false);

  const updateProduct = (field, value) => {
    let newProduct = { ...product };
    newProduct[field] = value;
    setProduct(product => newProduct);
  }

  const updateProductObj = (obj, field, value) => {
    let newProduct = { ...product };
    if (!newProduct[obj]) {
      newProduct[obj] = {};
    }
    newProduct[obj][field] = value;
    setProduct(newProduct);
  }

  const addFile = async () => {
    if (!newFileName) {
      showMessage({ text: 'Give your file a name', temp: true, type: 'error' });
      return;
    } else if (!newFile.current || !newFile.current.files || !newFile.current.files[0]) {
      showMessage({ text: 'You must choose a file', temp: true, type: 'error' });
      return;
    }
    showMessage({ text: 'Hold tight! We\'re uploading your file', temp: true, type: 'info' });
    let sups = product.supplements ? [...product.supplements] : [];
    let filelocation = await await window.ASTERISM.uploadFile(newFile);
    let file = {
      name: newFileName,
      location: filelocation,
      description: newFileDescription,
    }
    sups.push(file);
    updateProduct('supplements', sups);

    newFile.current.value = [];
    setNewFileDescription('');
    setNewFileName('');
  }

  const removeFile = (index) => {
    let sups = [...product.supplements];
    sups.splice(index, 1);
    updateProduct('supplements', sups);
  }

  const productcats = ['Poetry', 'Short Stories', 'Compilation', 'Fiction', 'Non-Fiction', 'Essays', 'Hybrid', 'Art/Visual', 'Novella', 'Novel', 'Books', 'Firmament', 'Empyrean'];

  const addPerson = () => {
    let newProduct = { ...product };
    newProduct[personType].push(newPerson);
    setProduct(newProduct);
    setNewPerson('');
  }

  const removePerson = (type, index) => {
    let newProduct = { ...product };
    let newPeople = [...product[type]];
    newPeople.splice(index, 1);
    newProduct[type] = newPeople;
    setProduct(newProduct);
  }

  const intoSlug = (string) => {
    if (product.title && product.authors.length === 1) {
      return slugify(`${product.title} ${product.authors[0]}`, { lower: true, strict: true, })
    } else if (product.title) {
      return slugify(product.title, { lower: true, strict: true, });
    } else {
      return '';
    }
  }

  const getDiscountRate = (price) => {
    if (parseInt(product.prices.retail) == 0) {
      return '';
    } else {
      return (100 * (1 - (parseFloat(price) / parseFloat(product.prices.retail)))).toFixed(1);
    }
  }

  return (
    <div className='admin-two'>
      <div className='admin-col-large'>
        <div className='standard-header mb-24'>
          <h2>General Info</h2>
          {product._id && <Link className='smaller' to={`/products/${product.slug}`}>View Product Page</Link>}
        </div>
        <TextControl
          value={product.title}
          change={(value) => { updateProduct('title', value) }}
          label={'Book Title'}
          help={intoSlug(product.title)}
          valid={false}
          type='text'
          disabled={uploading}
          required={true}
        />
        <TextControl
          value={product.subtitle}
          change={(value) => { updateProduct('subtitle', value) }}
          label={'Subtitle'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <BinaryControl
          value={product.public}
          change={(value) => { updateProduct('public', value) }}
          label={'Make Product Public'}
          help={'If no, it will only be visible to you.'}
          disabled={uploading}
        />


        <div className='text-control'>
          <label className='form-label'>Add a Person</label>
          <div className='person-adder'>
            <input value={newPerson} onChange={(e) => { setNewPerson(e.target.value) }} />
            <select value={personType} onChange={(e) => { setPersonType(e.target.value) }}>
              <option value='authors'>Author</option>
              <option value='editors'>Editor</option>
              <option value='translators'>Translator</option>
              <option value='illustrators'>Illustrator</option>
            </select>
            {!uploading && <button className='form-button' onClick={addPerson}>Add</button>}
          </div>
          <div className='form-help'>Add people in the order you want them to appear.</div>

          <div className='text-control'>
            <div className='people'>
              {product.authors.map((author, index) =>
                <div className='person-pill pill' key={author + index}>
                  <span className='type'>Author:</span>
                  <span>{author}</span>
                  <span className='remove' onClick={() => removePerson('authors', index)}>X</span>
                </div>
              )}
              {product.editors.map((author, index) =>
                <div className='person-pill pill' key={author + index}>
                  <span className='type'>Editor:</span>
                  <span>{author}</span>
                  <span className='remove' onClick={() => removePerson('editors', index)}>X</span>
                </div>
              )}
              {product.translators.map((author, index) =>
                <div className='person-pill pill' key={author + index}>
                  <span className='type'>Translator:</span>
                  <span>{author}</span>
                  <span className='remove' onClick={() => removePerson('translators', index)}>X</span>
                </div>
              )}
              {product.illustrators.map((author, index) =>
                <div className='person-pill pill' key={author + index}>
                  <span className='type'>Illustrator:</span>
                  <span>{author}</span>
                  <span className='remove' onClick={() => removePerson('illustrators', index)}>X</span>
                </div>
              )}
            </div>
          </div>
        </div>

        <TextControl
          classlist='small-input'
          value={product.productId}
          change={(value) => { updateProduct('productId', value.replace(/-/g, '')) }}
          label={'Product ID'}
          help={'Once you have saved the product, this cannot be changed. If you need to alter it, you will need to create a new product.'}
          valid={false}
          type='text'
          disabled={uploading || product._rev}
          required={true}
        />

        <SelectControl
          classlist='med-input'
          value={product.idType}
          change={(value) => { updateProduct('idType', value) }}
          label={'ID Type'}
          help={''}
          disabled={uploading}
          options={[
            {
              value: 'ISBN',
              label: 'ISBN'
            },
            {
              value: 'ISSN',
              label: 'ISSN'
            },
            {
              value: 'Custom',
              label: 'Publisher ID'
            }
          ]}
        />

        <SelectControl
          classlist='med-input'
          value={product.format}
          change={(value) => { updateProduct('format', value) }}
          label={'Format'}
          help={''}
          disabled={uploading}
          options={[
            {
              value: 'Paperback',
              label: 'Paperback'
            },
            {
              value: 'Hardcover',
              label: 'Hardcover'
            }
          ]}
        />

        <DateTimeControl
          time={false}
          label={'Publication Date'}
          disabled={uploading}
          change={(value) => { updateProduct('release', value) }}
          value={product.release}
        />

        <MultiSelect
          classList=''
          values={product.categories ? product.categories : []}
          change={(value) => { updateProduct('categories', value) }}
          choices={productcats}
          label={'Categories'}
          help={'Select any useful category designations'}
          disabled={uploading}
        />

        <NumberControl
          classlist='small-input'
          value={product.pages}
          change={(value) => { updateProduct('pages', value) }}
          label={'Page Count'}
          help={''}
          valid={false}
          disabled={uploading}
        />

        <div className='standard-header mt-24 mb-24'>
          <h2>Pricing & Terms</h2>
        </div>
        <NumberControl
          classlist='med-input'
          value={product.prices.list}
          change={(value) => { updateProductObj('prices', 'list', value) }}
          label={'List Price'}
          help={'Face value of the book; may or may not be encoded in your barcode.'}
          valid={false}
          disabled={uploading}
          step='.01'
        />
        <NumberControl
          classlist='med-input'
          value={product.prices.retail}
          change={(value) => { updateProductObj('prices', 'retail', value) }}
          label={'Retail Price'}
          help={'What you sell it for.'}
          valid={false}
          disabled={uploading}
          step='.01'
        />
        <TextControl
          classlist='small-input'
          value={product.prices && product.prices.retailStripe}
          change={(value) => { updateProductObj('prices', 'retailStripe', value) }}
          label={'Stripe Price ID'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
          required={true}
        />

        <BinaryControl
          value={product.prices.enableSale}
          change={(value) => { updateProductObj('prices', 'enableSale', value) }}
          label={'Enable Sale Price'}
          help={'Exactly what it says'}
          disabled={uploading}
        />

        <NumberControl
          classlist='med-input'
          value={product.prices.sale}
          change={(value) => { updateProductObj('prices', 'sale', value) }}
          label={'Sale Price'}
          help={'What you sell it for.'}
          valid={false}
          disabled={uploading}
          step='.01'
        />
        <TextControl
          classlist='small-input'
          value={product.prices && product.prices.saleStripe}
          change={(value) => { updateProductObj('prices', 'saleStripe', value) }}
          label={'Sale Stripe Price ID'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
          required={true}
        />

        <BinaryControl
          value={product.hideSubscribe ? product.hideSubscribe : false}
          change={(value) => { updateProduct('hideSubscribe', value) }}
          label={'Hide subscribe button on product page.'}
          help={'Exactly what it says'}
          disabled={uploading}
        />



        <div className='standard-header mt-24 mb-24'>
          <h2>Dimensions</h2>
        </div>
        <NumberControl
          classlist='small-input'
          value={product.dimension.weight}
          change={(value) => { updateProductObj('dimension', 'weight', value) }}
          label={'Weight (oz)'}
          help={''}
          valid={product.dimension.weight > 0}
          disabled={uploading}
          step='.01'
        />
        <NumberControl
          classlist='small-input'
          value={product.dimension.height}
          change={(value) => { updateProductObj('dimension', 'height', value) }}
          label={'Height (in)'}
          help={''}
          valid={product.dimension.height > 0}
          disabled={uploading}
          step='.01'
        />
        <NumberControl
          classlist='small-input'
          value={product.dimension.width}
          change={(value) => { updateProductObj('dimension', 'width', value) }}
          label={'Width (in)'}
          help={''}
          valid={product.dimension.width > 0}
          disabled={uploading}
          step='.01'
        />
        <NumberControl
          classlist='small-input'
          value={product.dimension.thickness}
          change={(value) => { updateProductObj('dimension', 'thickness', value) }}
          label={'Thickness (in)'}
          help={''}
          valid={product.dimension.thickness > 0}
          disabled={uploading}
          step='.01'
        />

        <div className='standard-header mt-24 mb-24'>
          <h2>Description</h2>
        </div>

        <MarkdownControl
          label='Product Description'
          disabled={false}
          value={product.description}
          change={(value) => { updateProduct('description', value) }}
          rows={14}
        />

        <div className='standard-header mt-24 mb-24'>
          <h2>Supplements</h2>
        </div>

        <div className='mt-24 mb-24'>{!product.supplements || product.supplements.length == 0 ? <em>No supplemental files uploaded yet.</em> : <strong>Supplemental files</strong>}</div>

        {product.supplements && product.supplements.map((file, index) => {
          return (
            <div className='mb-24 file-item' key={file.location}>
              <TextControl
                value={file.name}
                change={(value) => {
                  let sups = [...product.supplements];
                  sups[index].name = value;
                  updateProduct('supplements', sups);
                }}
                label={'File Name'}
                help={''}
                valid={false}
                type='text'
                disabled={uploading}
                required={true}
              />
              <TextControl
                value={file.description}
                change={(value) => {
                  let sups = [...product.supplements];
                  sups[index].description = value;
                  updateProduct('supplements', sups);
                }}
                label={'File Description'}
                help={''}
                valid={false}
                type='text'
                disabled={uploading}
                required={true}
              />
              <a href={file.location} target='_blank'>View / Download</a> | <a onClick={removeFile}>Remove</a>
            </div>
          )
        })}

        <TextControl
          value={newFileName}
          change={(value) => { setNewFileName(value) }}
          label={'Title of New File'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
          required={true}
        />

        <TextControl
          value={newFileDescription}
          change={(value) => { setNewFileDescription(value) }}
          label={'Description of New File'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
          required={true}
        />

        <FileControl
          classlist='mt-24'
          label={'Upload a Supplemental File'}
          valid={false}
          reference={newFile}
          disabled={uploading}
        />
        <button className='main-submit' onClick={addFile}>Upload</button>


        {/*<RichControl
          label='Product Description'
          value={product.description ? product.description : ''}
          change={(value) => {updateProduct('description', value)}}
        />*/}

      </div>
      <div className='admin-col-small'>
        <div className='standard-header mb-24'>
          <h2>Cover</h2>
        </div>
        {!product.cover &&
          <FileControl
            classlist=''
            label={'Cover Image'}
            help={'.'}
            valid={false}
            reference={newCover}
            disabled={uploading}
            image={true}
            accept="image/png, image/jpeg"
          />
        }

        {product.cover && returnImageMarkup(product.cover, `Cover art for ${product.title}`, 'cover-preview')}
        {product.cover && <button className='secondary-submit' onClick={() => { updateProduct('cover', '') }}>Remove/Change Cover</button>}

        <div className='standard-header mb-24 mt-48'>
          <h2>Preview</h2>
        </div>

        {!product.preview &&
          <FileControl
            classlist='mt-24'
            label={'Preview PDF'}
            valid={false}
            reference={previewFile}
            disabled={uploading}
          />
        }
        {product.preview &&
          <div className='text-control mt-24'>
            <label className='form-label'>Preview PDF</label>
            <div>{product.preview}</div>
          </div>
        }
        {product.preview && <button className='secondary-submit' onClick={() => { updateProduct('preview', '') }}>Remove/Change Preview</button>}


        <hr />

        <div className='standard-header mb-24 mt-48'>
          <h2>Inventory</h2>
        </div>
        <NumberControl
          classlist='small-input'
          value={product.inventory}
          change={(value) => { updateProduct('inventory', value) }}
          label={'Number in Stock'}
          help={''}
          valid={false}
          disabled={uploading}
          step='1'
        />
        <TextControl
          value={product.inventoryMessage}
          change={(value) => { updateProduct('inventoryMessage', value) }}
          label={'Message to Display When Out of Stock'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
        />
        <TextControl
          value={product.asterismLink}
          change={(value) => { updateProduct('asterismLink', value) }}
          label={'Link to Asterism'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <hr />

        <div className='standard-header mb-24 mt-48'>
          <h2>Related Products</h2>
        </div>

        <TextControl
          value={product.related}
          change={(value) => { updateProduct('related', value ? value.replace(/-/g, '') : '') }}
          label={'Related Products'}
          help={'Comma-separated list of ISBNs or product identifiers'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <hr />

        <div className='standard-header mb-24 mt-48'>
          <h2>eBook</h2>
        </div>

        <NumberControl
          classlist='med-input'
          value={product.ebook && product.ebook.price ? product.ebook.price : ''}
          change={(value) => { updateProductObj('ebook', 'price', value) }}
          label={'Retail Price'}
          help={'What you sell it for.'}
          valid={false}
          disabled={uploading}
          step='.01'
        />

        <TextControl
          value={product.ebook && product.ebook.stripePrice ? product.ebook.stripePrice : ''}
          change={(value) => { updateProductObj('ebook', 'stripePrice', value) }}
          label={'Stripe Price'}
          help={'Price ID from the stripe admin'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <TextControl
          value={product.ebook && product.ebook.isbn ? product.ebook.isbn : ''}
          change={(value) => { updateProductObj('ebook', 'isbn', value) }}
          label={'eBook ISBN'}
          help={'The ISBN of the eBook'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        {!product.ebook || !product.ebook.file &&
          <FileControl
            classlist='mt-24'
            label={'eBook file'}
            valid={false}
            reference={ebookFile}
            disabled={uploading}
          />
        }
        {product.ebook && product.ebook.file &&
          <div className='text-control mt-24'>
            <label className='form-label'>eBook file</label>
            <div>{product.ebook.file}</div>
          </div>
        }
        {product.ebook && product.ebook.file && <button className='secondary-submit' onClick={() => { updateProductObj('ebook', 'file', '') }}>Remove/Change File</button>}


      </div>
    </div>
  )
}
