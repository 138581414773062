import React, {useState, useEffect} from 'react';

const emailUrl = 'https://sublunaryeditions.us20.list-manage.com/subscribe/post?u=af2cfc275bcf72ef055452c20&amp;id=867a149374';

export default function Newsletter({ showMessage, classes }) {
  const [message, setMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [email, setEmail] = useState('');

  const subscribe = async () => {
    setSubmitting(true);
    let data = await window.ASTERISM.makeRequest(`newsletter?email=${email}`);
    if (data && !data.error) {
      showMessage({ text: 'You have successfully signed up for our email list!', temp: true, type: 'success', label: 'Thanks!'});
      setEmail('');
      window.plausible('EmailSignup');
    } else if (data && data.error) {
      showMessage({ text: 'Something went wrong. You may already be signed up for this list, or your email may be incorrect', temp: true, type: 'error', label: 'Oops'});
    } else {
      showMessage({ text: 'Sorry, but something went wrong.', temp: true, type: 'error', label: 'Oops'});
    }
    setSubmitting(false);
  }

  return (
    <div className={`newsletter-form ${classes}`}>
      <div className='description'>Sign up for our mailing list to receive all of our announcements!</div>
      <input disabled={submitting} value={email} className='email-input' onChange={(e) => setEmail(e.target.value)} placeholder='Enter your email address' />
      <button disabled={submitting} className='email-button' onClick={subscribe}>Subscribe</button>
    </div>
  )
}
