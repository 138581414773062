import React, { useState, useEffect } from 'react';
import {
  Link
} from "react-router-dom";
import Paginator from '../../components/Paginator';
import moment from 'moment';
import showdown from 'showdown';
import { returnImageMarkup } from '../../Utils';
import './style.css';

const converter = new showdown.Converter();

export default function Magazine({ api }) {
  const [posts, setPosts] = useState([]);

  const getPosts = async () => {
    let perPage = 18;
    let data = await window.ASTERISM.makeRequest(`list-all-else?page=${0}&per=${perPage}`);
    let newPosts = posts.concat(data);
    setPosts(newPosts);
  }

  useEffect(() => {
    getPosts();
  }, []);

  document.title = 'Firmament Magazine Online | Sublunary Editions';
  document.querySelector('meta[name="description"]').setAttribute("content", 'The official magazine of Sublunary Editions. Essays, poetry, fiction, and more every week.');

  const getTitleSize = (title) => {
    let min = 32;
    let max = 48;
    if (title.length < 16) {
      return max;
    } else if (title.length > 32) {
      return min;
    } else {
      return ((1 - ((title.length - 16) / 16)) * (max - min)) + min;
    }
  }

  const getExcerptSize = (text) => {
    let min = 17;
    let max = 28;
    if (text.length < 200) {
      return max;
    } else if (text.length > 550) {
      return min;
    } else {
      return ((1 - ((text.length - 350) / 350)) * (max - min)) + min;
    }
  }

  return (
    <div>
      <div className='magazine-home'>
        <div className='magazine-header'>
          <div className='magazine-name'>Firmament</div>
          <div className='magazine-tagline'><span>A magazine of considered miscellany from Sublunary Editions.</span> <Link to={`/magazine/page/2`} class='read-more-link'>Browse the Archive</Link></div>
        </div>
        <div className='mag-grid'>
          {posts.map((post, index) =>
            <div key={post._id} className={`mag-item`}>
              <div className='mag-item-content'>
                <div className='mag-subtitle'>{post.byline}</div>
                <div>
                  <span class='mag-title' style={{ fontSize: `${getTitleSize(post.title)}px` }}><Link className='hov' to={`/magazine/${post.slug}`}>{post.title}</Link></span>
                </div>
                <div className='mag-excerpt' style={{ fontSize: `${getExcerptSize(post.excerpt)}px` }} dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>

              </div>
            </div>
          )}
        </div>
        <div className='magMore'><Link to={`/magazine/page/2`} class='magMoreLink'>Browse the Archive</Link></div>
      </div>
    </div>
  )
}
