import React, {useState, useEffect, useRef} from 'react';
import TextControl from '../TextControl';
import NumberControl from '../NumberControl';
import FileControl from '../FileControl';
import SelectControl from '../SelectControl';
import SelectAndAddControl from '../SelectAndAddControl';
import BinaryControl from '../BinaryControl';
import RichControl from '../RichControl';
import MultiSelect from '../MultiSelect';
import MarkdownControl from '../MarkdownControl';
import DateTimeControl from '../DateTimeControl';
import slugify from 'slugify';
import moment from 'moment';
import {Link} from "react-router-dom";

const useForceUpdate = () => useState()[1];

export default function PersonEditForm({ setPage, page, featuredImage, uploading = false }) {
  const [outlets, setOutlets] = useState([]);

  const updatePage = (field, value) => {
    let newPage = {...page};
    newPage[field] = value;
    setPage(post => newPage);
  }

  const getOutlets = async() => {
    let data = await window.ASTERISM.makeRequest(`list-all-outlets`, 'GET');
    setOutlets(data);
  }

  useEffect(() => {
    getOutlets();
  }, [])

  const categories = ['Print', 'Digital', 'Podcast', 'Radio'];
  const features = ['Interviews', 'Reviews', 'Features', 'Excerpts'];
  const genres = ['Novels', 'Short Stories', 'Visual', 'Poetry', 'Contemporary', 'Philosophy', 'Classic'];

  return (
    <div className='two-col'>
      <div className='modal-form-col'>
        <TextControl
          value={page.name}
          change={(value) => {updatePage('name', value)}}
          label={'Name'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <TextControl
          value={page.email}
          change={(value) => {updatePage('email', value)}}
          label={'Email Address'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <TextControl
          value={page.phone}
          change={(value) => {updatePage('phone', value)}}
          label={'Phone Number'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <TextControl
          value={page.website}
          change={(value) => {updatePage('website', value)}}
          label={'Website'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <MarkdownControl
          label='Address'
          disabled={uploading}
          value={page.address}
          change={(value) => {updatePage('address', value)}}
          rows={5}
        />

        <TextControl
          value={page.twitter}
          change={(value) => {updatePage('twitter', value)}}
          label={'Twitter'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <TextControl
          value={page.instagram}
          change={(value) => {updatePage('instagram', value)}}
          label={'Instagram'}
          valid={false}
          type='text'
          disabled={uploading}
        />

      </div>
      <div className='modal-form-col'>

      <SelectAndAddControl
        classlist='med-input'
        values={page.outlets}
        change={(value) => {updatePage('outlets', value)}}
        label={'Associated Outlets'}
        help={''}
        disabled={uploading}
        options={outlets.map((outlet) => {
          return {value: outlet.key,
          label: outlet.value};
        })}
      />

      <MultiSelect
        classList=''
        values={page.features ? page.features : []}
        change={(value) => {updatePage('features', value)}}
        choices={features}
        label={'Feature Types'}
        help={'Select types of features done by this publication that are relevant to us'}
        disabled={uploading}
      />

      <MultiSelect
        classList=''
        values={page.genres ? page.genres : []}
        change={(value) => {updatePage('genres', value)}}
        choices={genres}
        label={'Genre Preferences'}
        help={'Select any useful taste designations'}
        disabled={uploading}
      />

      <MarkdownControl
        label='Notes'
        disabled={uploading}
        value={page.notes}
        change={(value) => {updatePage('notes', value)}}
        rows={10}
      />


      </div>
    </div>
  )
}
