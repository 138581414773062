import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import moment from 'moment';
import ProductGridItem from '../../components/ProductGridItem';
import PostGridItem from '../../components/PostGridItem';

export default function Search({ showMessage, addToCart }) {
  const [results,setResults] = useState([]);
  const [hits, setHits] = useState([]);
  const [message, setMessage] = useState('');

  let { term } = useParams();

  document.title = `Search results for "${term}" | Sublunary Editions`

  const search = async () => {
    const data = await window.ASTERISM.makeRequest(`search?term=${term}`, 'GET');
    setMessage(`${data.total.value} search result${data.total.value === 1 ? '' : 's'} for "${term}"`)
    setResults(data.hits.map(hit => hit._source));
    setHits(data.total.value);
  }

  useEffect(() => {
    if (term) {
      setMessage(`Searching for "${term}"...`)
      search();
    }
  }, []);


  return (
    <div>
      <div className='page-header-new mb-48'>
        <h1>{message}</h1>
      </div>
      <div className='product-grid'>
        {results.map((result) => {
          if (result.cover) {
            return (
              <ProductGridItem key={result._id} product={result} home={false} showMessage={showMessage} addToCart={addToCart} />
            )
          } else {
            return (
              <PostGridItem post={result} />
            )
          }
        })}
      </div>

    </div>
  )
}
