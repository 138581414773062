import React, { useEffect, useState } from 'react';

export default function Paginator( { value, change, maxPages } ) {
  return (
    <div className='paginator'>
      <button disabled={value === 0 ? 'disabled' : ''} onClick={() => {change(0); window.scrollTo(0, 0);}}>&#xab;</button>
      <button disabled={value === 0 ? 'disabled' : ''} onClick={() => {change(value - 1); window.scrollTo(0, 0);}}>&#8249;</button>
      <div className='label'><span>Page {value + 1} of {maxPages}</span></div>
      <button disabled={value === (maxPages - 1)} onClick={() => {change(value + 1); window.scrollTo(0, 0);}}>&#8250;</button>
      <button disabled={value === (maxPages - 1)} onClick={() => {change(maxPages - 1); window.scrollTo(0, 0);}}>&#xbb;</button>
    </div>
  )
}
