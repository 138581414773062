import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import moment from 'moment';
import {getMenus} from '../../Menus.js';
import NumberControl from '../../components/NumberControl';
import Barcode from '../../components/Barcode';
import Copyright from './copyright.js';

export default function AccountHome() {
  const [profile, setProfile] = useState({});
  const [error, setError] = useState('Checking access...');
  const [login, setLogin] = useState(false);
  const [menu, setMenu] = useState([]);
  const [pages, setPages] = useState(100);
  const [spine, setSpine] = useState(0);
  const [interior, setInterior] = useState(.005);
  const [cover, setCover] = useState(.0143);

  let { id } = useParams();

  const getPub = async () => {
    document.title = 'My Account | Sublunary Editions';

    let data = await window.ASTERISM.makeRequest(`private-user`, 'GET');
    if (data.error) {
      setError(data.error);
      setLogin(data.login);
    } else {
      setError('');
      setProfile(data);
      setMenu(getMenus(data.roles));
    }
  }

  useEffect(() => {
    getPub();
  }, []);

  const editProfile = async () => {
    let session = await window.ASTERISM.makeRequest('create-customer-portal-session', 'GET');
    if (session.url) {
      window.location.href = session.url;
    }
  }

  if (error || !profile.roles) {
    return (
      <div></div>
    )
  } else if (profile.roles.customer){

    return (
      <div>
        <div className='admin-two'>
          <div className='admin-col'>
            <div className='standard-header'>
              <h2>Profile</h2>
            </div>
            <div className='text-control'>
              <label className='form-label'>My Customer ID</label>
              <div>{profile.customer}</div>
            </div>
            <div className='text-control'>
              <label className='form-label'>My Email</label>
              <div>{profile.customerData.email}</div>
            </div>
            {profile.customerData.shipping &&
              <div className='text-control'>
                <label className='form-label'>Shipping Info</label>
                <div>{profile.customerData.shipping.name}</div>
                <div>{profile.customerData.shipping.address.line1}</div>
                <div>{profile.customerData.shipping.address.line2}</div>
                <div>{profile.customerData.shipping.address.city} {profile.customerData.shipping.address.state} {profile.customerData.shipping.address.postcal_code}</div>
                <div>{profile.customerData.shipping.address.country}</div>
              </div>
            }
            <div className='text-control'>
              <label className='form-label'>Customer Since</label>
              <div>{moment(profile.customerData.created * 1000).format('LLL')}</div>
            </div>

            <button className='secondary-submit mb-24' onClick={editProfile}>Update My Info</button>

            <div><Link to='/change-password'>Change my password</Link></div>
          </div>

          <div className='admin-col'>
            <div className='standard-header'>
              <h2>Subscriptions</h2>
            </div>
            {profile.customerData.subscriptions && profile.customerData.subscriptions.data && profile.customerData.subscriptions.data.map((sub) =>
              <div className='' key={sub.id}>
                <div className='text-control'>
                  <label className='form-label'>Plan</label>
                  <div>${sub.plan.amount / 100} USD / {sub.plan.interval}</div>
                </div>
                <div className='text-control'>
                  <label className='form-label'>Since</label>
                  <div>{moment(sub.created * 1000).format('LLLL')}</div>
                </div>
                <div className='text-control'>
                  <label className='form-label'>Next Invoice</label>
                  <div>{moment(sub.current_period_end * 1000).format('LLLL')}</div>
                </div>
                <button className='secondary-submit' onClick={editProfile}>Change Subscription</button>

              </div>
            )}
            {(!profile.customerData.subscriptions || !profile.customerData.subscriptions.data || profile.customerData.subscriptions.data.length == 0) && <em>You do not have any active subscriptions</em>}
          </div>

        </div>
      </div>
    )
  } else {


  return (
    <div>
      <div className='admin-two'>
        <div className='admin-col'>
          <div className='calc'>
            <h3>Spine Thickness Calculator</h3>
            <NumberControl
              classlist='small-input'
              value={interior}
              change={(value) => {setInterior(value)}}
              label={'Interior Page Caliper Thickness'}
              help={''}
              step={.0001}
              valid={false}
            />
            <NumberControl
              classlist='small-input'
              value={cover}
              change={(value) => {setCover(value)}}
              label={'Cover Stock Caliper Thickness'}
              help={''}
              step={.0001}
              valid={false}
            />
            <NumberControl
              classlist='small-input'
              value={pages}
              change={(value) => {setPages(value)}}
              label={'Page Count'}
              help={''}
              valid={false}
            />
            <div><strong>Spine:</strong> {(Math.ceil(parseInt(pages) / 2) * parseFloat(interior)) + (2 * parseFloat(cover))} in</div>
            <div className='mt-24'>70lb conservation: .005 / 80 pound cougar matte: ???</div>
          </div>

          <Copyright />

          <div className='calc'>
            <div className='admin-link-list'>
              <a href="https://www.loc.gov/publish/pcn/faqs/" target="_blank">Library of Congress Control Number FAQ</a>
              <a href="https://locexternal.servicenowservices.com/pub" target="_blank">Library of Congress Publisher Portal</a>
              <a href="https://exhibits.stanford.edu/copyrightrenewals" target="_blank">Stanford Copyright Renewal Database</a>
            </div>
          </div>

        </div>
        <div className='admin-col'>
          <Barcode />


        </div>
      </div>

    </div>
  )
  }
}
