import React, { useState } from 'react';
import './labelstyle.css';

export default function LabelsModal({ selected, orders, setViewingLabels }) {
  const [skip, setSkip] = useState([]);

  const getSizeClass = (order) => {
    if (!order.address.state) {
      order.address.state = '';
    }
    if (order.address.line2 ||
      order.name.length > 32 ||
      order.address.line1.length > 32 ||
      (order.address.line2 && order.address.line2.length > 32) ||
      (order.address.city.length + order.address.state.length + order.address.postal_code.length + 2) > 32
    ) {
      return 'smallest';
    } else if (order.name.length > 25 ||
      order.address.line1.length > 25 ||
      (order.address.line2 && order.address.line2.length > 25) ||
      (order.address.city.length + order.address.state.length + order.address.postal_code.length + 2) > 25
    ) {
      return 'small';
    } else {
      return 'normal';
    }
  }

  return (
    <div className='modal address-labels'>
      <div className='modal-content auto-height'>
        <a className='close-me' onClick={() => { setViewingLabels(false) }}>Close</a>
        <div className='page'>
          {selected.map((index) =>
            <div className={`addy ${getSizeClass(orders[index])}`}>
              {orders[index].address &&
                <div className={`addy-wrapper ${getSizeClass(orders[index])}`}>
                  <div>{orders[index].name}</div>
                  <div>{orders[index].address.line1}</div>
                  {orders[index].address.line2 && <div>{orders[index].address.line2}</div>}
                  <div>{orders[index].address.city} {orders[index].address.state} {orders[index].address.postal_code}</div>
                  <div>{orders[index].address.country}</div>
                </div>
              }
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
