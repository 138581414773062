import React, {useState, useEffect, useRef} from 'react';
import TextControl from '../TextControl';
import NumberControl from '../NumberControl';
import FileControl from '../FileControl';
import SelectControl from '../SelectControl';
import BinaryControl from '../BinaryControl';
import RichControl from '../RichControl';
import MultiSelect from '../MultiSelect';
import MarkdownControl from '../MarkdownControl';
import DateTimeControl from '../DateTimeControl';
import slugify from 'slugify';
import moment from 'moment';
import {Link} from "react-router-dom";

const useForceUpdate = () => useState()[1];

export default function OutletEditForm({ setPage, page, featuredImage, uploading = false }) {

  const updatePage = (field, value) => {
    let newPage = {...page};
    newPage[field] = value;
    setPage(post => newPage);
  }

  const categories = ['Print', 'Digital', 'Podcast', 'Radio'];
  const features = ['Interviews', 'Reviews', 'Features', 'Excerpts', 'Events'];

  return (
    <div className='two-col'>
      <div className='modal-form-col'>
        <TextControl
          value={page.name}
          change={(value) => {updatePage('name', value)}}
          label={'Name'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <TextControl
          value={page.website}
          change={(value) => {updatePage('website', value)}}
          label={'Website'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <TextControl
          value={page.twitter}
          change={(value) => {updatePage('twitter', value)}}
          label={'Twitter'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <TextControl
          value={page.instagram}
          change={(value) => {updatePage('instagram', value)}}
          label={'Instagram'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <MarkdownControl
          label='Notes'
          disabled={uploading}
          value={page.notes}
          change={(value) => {updatePage('notes', value)}}
          rows={10}
        />

      </div>
      <div className='modal-form-col'>
        <MultiSelect
          classList=''
          values={page.categories ? page.categories : []}
          change={(value) => {updatePage('categories', value)}}
          choices={categories}
          label={'Categories'}
          help={'Select any useful category designations'}
          disabled={uploading}
        />

        <MultiSelect
          classList=''
          values={page.features ? page.features : []}
          change={(value) => {updatePage('features', value)}}
          choices={features}
          label={'Feature Types'}
          help={'Select types of features done by this publication that are relevant to us'}
          disabled={uploading}
        />

      </div>
    </div>
  )
}
