import React, {useState, useEffect, useRef} from 'react';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import OutletForm from '../../components/OutletEditForm';
import slugify from 'slugify';
import moment from 'moment';


export default function AccountOutletEdit({ showMessage, userId }) {
  const [outlet, setOutlet] = useState({});
  const [error, setError] = useState('');
  const [login, setLogin] = useState(false);

  const updateOutlet = (field, value) => {
    let newOutlet = {...outlet};
    newOutlet[field] = value;
    setOutlet(newOutlet);
  }

  let { id } = useParams();

  document.title = 'Editing Outlet | Asterism';

  const getOutlet = async () => {
    let data = await window.ASTERISM.makeRequest(`get-outlet?id=${id}`, 'GET');
    setOutlet(data);
  }


  useEffect(() => {
    getOutlet();
  }, []);


  const submit = async () => {
    let data = await window.ASTERISM.makeRequest('update-outlet', 'POST', outlet);
    if (data.ok) {
      updateOutlet('_rev', data.rev);
      showMessage({text: 'Outlet successfully updated!', temp: true, type: 'success'})
    }
  }

  if (error) {
    return (
      <div className='edit-bar'><div className='edit-title'>{error} {login && <Link to={`/login?return=${window.location.pathname}`}><button className='secondary-submit'>Login</button></Link>}</div></div>
    )
  }

  return (
    <div className=''>
      <div className='edit-bar'>
        <div className='edit-title'>Editing <em>{outlet.name}</em></div>
        <button className='main-submit' onClick={submit}>Save Changes</button>
      </div>
      <OutletForm setPage={setOutlet} page={outlet} />
    </div>
  )
}
