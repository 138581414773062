import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import moment from 'moment';
import {getMenus} from '../../Menus.js';
import { ReactComponent as Book } from '../../book.svg';

export default function AccountBundles({ showMessage, userId, roles, userMenu, userName }) {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState('');
  const [login, setLogin] = useState(false);
  const [updating, setUpdating] = useState(-1);

  document.title = 'All Bundles | Asterism Admin';

  const getProducts = async () => {
    let data = await window.ASTERISM.makeRequest('private-bundles', 'GET');
    setProducts(data);
  }

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div>
      <div className='admin-full'>

        <div className='standard-header'>
          <h2>All Products</h2>
          <Link to={`/my-account/add-bundle`}><button className='secondary-submit'>+ Add Bundle</button></Link>
        </div>
        <div className='table-header table-item'>
          <div className='image icon'>Cover</div>
          <div className='flex-1'>Title</div>
          <div className='flex-1'>Products</div>
          <div className='flex-1'>Status</div>
          <div className='flex-1'>Retail</div>
          <div className='flex-1'>Inventory</div>
        </div>
        <div className='admin-orders'>
        {products.map((product, index) =>
          <div key={product._id} className='cart-item'>
            <div className='image'>{product.cover ? <img src={product.cover} /> : <Book/>}</div>
            <span className='col'>
              <div className='product-list-title'>
                {roles.publisher ? <Link to={`/my-account/bundle/${product._id}`}>{product.title}</Link> :
                  <span>{product.title}</span>
                }
              </div>
              <span className='authors'>{product.subtitle}</span>
            </span>
            <span className='col'>{product.included}</span>
            <span className='col'>{product.public ? 'Public' : 'Private'}</span>
            <span className='col'>${product.prices.retail}</span>
            <span className='col'>{product.inventory}</span>
          </div>
        )}
        {products.length == 0 && <div className='mt-24'><em>You don't have any products yet.</em></div>}

        </div>
      </div>

    </div>
  )
}
