import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import moment from 'moment';
import ProductGridItem from '../../components/ProductGridItem';
import showdown from 'showdown';
const converter = new showdown.Converter();

export default function Post({ api, addToCart }) {
  const [post, setPost] = useState({});
  const [related, setRelated] = useState([]);
  const [latest, setLatest] = useState([]);

  let { slug } = useParams();

  const getLatest = async (categories) => {
    let url = categories.indexOf('News') < 0 ? `list-all-else?page=0&per=3` : `list-all-news?page=0&per=3`;
    let data = await window.ASTERISM.makeRequest(url);
    setLatest(data);
  }

  const getPost = async () => {
    let response = await fetch(`${api}public-post?slug=${slug}`);
    let data = await response.json();
    getLatest(data.categories);
    document.title = `${data.title} | Sublunary Editions`;
    document.querySelector('meta[name="description"]').setAttribute("content", data.excerpt);

    setPost(data);
    if (data.related && data.related.length > 0) {
      let relatedBooks = data.related.split(',');
      let toadd = [];
      for (let i = 0; i < relatedBooks.length; i++) {
        let reldata = await window.ASTERISM.makeRequest(`private-product?id=${relatedBooks[i]}`);
        if (reldata) {
          toadd.push(reldata);
        }
      }
      setRelated(toadd);
    }
  }

  useEffect(() => {
    getPost();
  }, [slug]);

  if (!post._id) {
    return (
      <div>
      </div>
    )
  }

  return (
    <div>
      <div className='post-header'>
        <div className='product-top-meta'>{moment(post.published).format('LLL')}</div>
        <h1>{post.title}</h1>
        <div className='subtitle'>{post.subtitle}</div>
        <div className='byline'>by {post.byline}</div>
      </div>
      <div className='admin-two'>
        <div className='admin-col-large'>
          <div className='post-body' dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.content) }}></div>
        </div>
        <div className='admin-col-small'>
          <div className='citations' dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.contribBio) }}></div>

          <div className='citations' dangerouslySetInnerHTML={{ __html: converter.makeHtml(post.citation) }}></div>

        </div>
      </div>
      {related.length > 0 && <div className='generic-block flex'>
        <h2>Related</h2>
      </div>}
      <div className='product-grid'>
        {related.map((product) =>
          <ProductGridItem key={product._id} product={product} home={true} />
        )}
      </div>
      <div className='admin-full no-v'>
        <h2>Latest</h2>
        <div className='post-grid'>
          {latest.map((post) =>
            <div key={post._id} className='post-grid-item'>
              <div className='post-grid-frame'></div>
              <div className='post-grid-item-content'>
                <div className='subtitle'>{post.published}</div>
                <span className='title'><Link className='hov' to={`/magazine/${post.slug}`}>{post.title}</Link></span>
                <div className='subtitle'>by {post.byline}</div>
                <div className='excerpt' dangerouslySetInnerHTML={{ __html: post.excerpt }}></div>
                <Link to={`/magazine/${post.slug}`} className='read-more-link'>Read More</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
