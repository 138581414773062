import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import moment from 'moment';
import Labels from './labels.js';
import SelectControl from '../../components/SelectControl';

export default function AccountStatements({ userId, roles, userMenu, userName }) {
  const [profile, setProfile] = useState({});

  const [error, setError] = useState('Unauthorized');
  const [login, setLogin] = useState();
  const [orders, setOrders] = useState([]);
  const [descending, setDescending] = useState(true);
  const [total, setTotal] = useState(0);
  const [sum, setSum] = useState(0);
  const [product, setProduct] = useState('');
  const [selected, setSelected] = useState([]);
  const [per, setPer] = useState(20);
  const [keys, setKeys] = useState({ byName:{}, byProd: {}});
  const [quarterlyData, setQuarterlyData] = useState({});

  document.title = 'All Orders | Sublunary Admin';

  const sortBreakdown = (breakdown) => {
    let sortKeys = {};
    Object.keys(breakdown).forEach((key) => {
      let tempKeyArray = key.split(' ');
      let tempKey = `${tempKeyArray[1]}${tempKeyArray[0].replace('Q', '')}`;
      sortKeys[tempKey] = key;
    });
    let sortedKeys = Object.keys(sortKeys).sort();
    let sortedData = {};
    sortedKeys.forEach((sk) => {
      sortedData[sortKeys[sk]] = breakdown[sortKeys[sk]];
    })
    return sortedData;
  }

  const getOrders = async () => {
    let data = await window.ASTERISM.makeRequest(`really-list-all-orders?descending=${descending}&product=${product}`, 'GET');
    setOrders(data.orders);
    setTotal(data.total);
    setSum(data.sum ? data.sum : 0);
    let monthkey = {
      '1': 'Q1',
      '2': 'Q1',
      '3': 'Q1',
      '4': 'Q2',
      '5': 'Q2',
      '6': 'Q2',
      '7': 'Q3',
      '8': 'Q3',
      '9': 'Q3',
      '10': 'Q4',
      '11': 'Q4',
      '12': 'Q4',
    }
    let breakdown = {};
    data.orders.forEach((order) => {
      let key = `${monthkey[moment(order.created).format('M')]} ${moment(order.created).format('YYYY')}`;
      let qtys = order.items.map((item) => {
        if (item.product == product) {
          return item.quantity;
        } else {
          return 0;
        }
      })
      const sum = qtys.reduce((partialSum, a) => partialSum + a, 0);
      if (breakdown[key]) {
        breakdown[key] += sum
      } else {
        breakdown[key] = sum;
      }
    });
    let sorted = sortBreakdown(breakdown);

    setQuarterlyData(sorted);
  }

  const getPub = async () => {
    let data = await window.ASTERISM.makeRequest(`private-user`, 'GET');
    if (data.error) {
      setError(data.error);
    } else {
      setError('');
      setProfile(data);
    }
  }

  const getKeys = async () => {
    let data = await window.ASTERISM.makeRequest(`all-prices`, 'GET');
    setKeys(data);
  }

  useEffect(() => {
    if (userId && roles.publisher && product) {
      getOrders();
    }
  }, [product]);

  useEffect(() => {
    if (userId && roles.publisher) {
      getKeys();
    }
  }, [userId]);

  const exportSelected = () => {

  };

  if (!userId || !roles.publisher) {
    return (
      <div className='space'>You are not authorized to view this page.</div>
    )
  }

  const getTotal = () => {
    return Object.values(quarterlyData).reduce((partialSum, a) => partialSum + a, 0);
  }

  return (
    <div>
      <div className='admin-full '>
        <div className='standard-header'>
          <div className='all-wide'>
            <span></span>
            <div>
              <button className='secondary-submit mr-12' onClick={exportSelected}>Export</button>
            </div>
          </div>
          <div className='table-page-controls'>
            <SelectControl
              label={'Book'}
              value={product}
              change={(value) => {setProduct(value)}}
              options={[
                {
                  value: '',
                  label: '--'
                }
              ].concat(Object.entries(keys.byName).map((opt) => { return {value: opt[1], label: [opt[0]]} }))}
            />
          </div>

        </div>
        <div className='table-header table-item admin-table'>
          <div className='order-col flex-small'>
          </div>
          <div className='order-col flex-1'>Quarter</div>
          <div className='order-col flex-1'>Sales</div>
          <div className='order-col flex-1'>Country</div>
          <div className='order-col flex-2'>Items</div>
        </div>
        <div className='admin-orders'>
          {product && Object.keys(quarterlyData) && Object.keys(quarterlyData).map((key) =>
            <div className='table-item' key={key} >
              <div className='order-col flex-1'>{key}</div>
              <div className='order-col flex-1'>{quarterlyData[key]}</div>
            </div>
          )}
          <div className='table-item'>
            <div className='order-col flex-1 bold'>Total</div>
            <div className='order-col flex-1 bold'>{getTotal()}</div>
          </div>
        </div>
      </div>
    </div>
  )
}
