import React, {useState, useEffect} from 'react';
import {
  Link
} from "react-router-dom";
import moment from 'moment';
import {getMainMenu} from '../../Menus.js';
import showdown from 'showdown';
const converter = new showdown.Converter();


export default function GiftSubs({ api, addToCart }) {
  const [subs, setSubs] = useState([]);
  const [intsubs, setIntsubs] = useState([]);

  const getSubs = async () => {
    let data = await window.ASTERISM.makeRequest(`list-all-gift-subs?region=US`, 'GET');
    setSubs(data);
    let data2 = await window.ASTERISM.makeRequest(`list-all-gift-subs?region=CA`, 'GET');
    setIntsubs(data2);
  }

  useEffect(() => {
    getSubs();
  }, [])

  document.title = 'Gift Subscriptions | Sublunary Editions';
  document.querySelector('meta[name="description"]').setAttribute("content", 'See all gift subscription offers.');


  return (
    <div>
      <div className='page-header-new'>
        <h1>Gift Subscriptions</h1>
        <p>Give the Gift of Portable Literature</p>
      </div>
      <div className='admin-full'>
        <div className='standard-header'>
          <h2>Gift Subscription Offers (US)</h2>
        </div>
        <div className='post-grid'>
          {subs.map((sub) =>
            <div key={sub._id} className='post-grid-item'>
              <div className='post-grid-frame'></div>
              <div className='post-grid-item-content'>
                <h3>{sub.title}</h3>
                <div className='subtitle'>{sub.subtitle}</div>
                <div className='excerpt' dangerouslySetInnerHTML={{__html: converter.makeHtml(sub.description)}}></div>
                <div className='sub-price'>${sub.prices.retail}/{sub.prices.period}</div>
                <button className='main-submit' onClick={() => {addToCart(sub._id, 'subs')}}>Add to Cart</button>
              </div>
            </div>
          )}
        </div>

        <div className='standard-header'>
          <h2>Gift Subscription Offers (Canada)</h2>
        </div>
        <div className='post-grid'>
          {intsubs.map((sub) =>
            <div key={sub._id} className='post-grid-item'>
              <div className='post-grid-frame'></div>
              <div className='post-grid-item-content'>
                <h3>{sub.title}</h3>
                <div className='subtitle'>{sub.subtitle}</div>
                <div className='excerpt' dangerouslySetInnerHTML={{__html: converter.makeHtml(sub.description)}}></div>
                <div className='sub-price'>${sub.prices.retail}/{sub.prices.period}</div>
                <button className='main-submit' onClick={() => {addToCart(sub._id, 'subs')}}>Add to Cart</button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
