import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import moment from 'moment';
import { ReactComponent as Ebook } from '../../ebook.svg';
import { ReactComponent as Book } from '../../book.svg';
import { ReactComponent as Close } from '../../close.svg';
import { inventoryCount } from '../../Utils.js';
import TextAreaControl from '../../components/TextAreaControl';
import ProductGridItem from '../../components/ProductGridItem';
import { returnImageMarkup } from '../../Utils.js';
import Tooltip from '../../components/Tooltip';
import showdown from 'showdown';
const converter = new showdown.Converter();


export default function Bundle({ api, addToCart, showMessage, sessionValid }) {
  const [product, setProduct] = useState({});
  const [related, setRelated] = useState([]);

  let { slug } = useParams();

  let history = useHistory();

  const getProduct = async () => {
    let data = await window.ASTERISM.makeRequest(`public-bundle?slug=${slug}`);
    document.title = `${data.title} | Sublunary Editions`;
    document.querySelector('meta[name="description"]').setAttribute("content", data.description);

    setProduct(data);
    data.related = data.includes;
    if (data.related && data.related.length > 0) {
      let relatedBooks = data.related.split(',');
      let toadd = [];
      for (let i = 0; i < relatedBooks.length; i++) {
        let reldata = await window.ASTERISM.makeRequest(`public-product?id=${relatedBooks[i]}`);
        if (reldata && reldata.cover) {
          toadd.push(reldata);
        }
      }
      setRelated(toadd);
    }
  }

  useEffect(() => {
    getProduct();
  }, [slug]);

  if (!product.title) {
    return (
      <div></div>
    )
  }

  return (
    <div>
      <div className='product-page'>

        <div className='product-image'>
          <div className='product-image-cover'>
            <div className='product-image-frame'></div>
            {product.cover && returnImageMarkup(product.cover, `Cover art for ${product.title}`)}
          </div>
        </div>
        <div className='product-data'>
          <h1>{product.title}</h1>
          <div className='contributors'>
            {product.subtitle}
          </div>
          <div className='product-pricing'>
            <div className='prices'>
              {product.prices && product.prices.list !== product.prices.retail && <span className='higher-price'>${product.prices.list}</span>}
              {product.prices && !product.prices.enableSale && <span className='real-price'>${product.prices.retail}</span>}
              {product.prices && product.prices.enableSale && <span className='real-price sale'>${product.prices.sale} <span className='on-sale'>On sale!</span></span>}
            </div>
            <div className='add-to-cart mb-48 '>
              <button className='main-submit mr-12' onClick={() => { addToCart(product._id, 'bundles') }}>Add to cart</button>
            </div>
          </div>
          <div className='product-description' dangerouslySetInnerHTML={{ __html: converter.makeHtml(product.description) }}></div>

        </div>
      </div>
      {related.length > 0 && <div className='generic-block flex'>
        <h2>Included</h2>
      </div>}
      <div className='product-grid'>
        {related.map((product) =>
          <ProductGridItem key={product._id} product={product} home={true} />
        )}
      </div>
    </div>
  )
}
