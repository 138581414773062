import React, { useEffect, useState } from 'react';
import MarkdownControl from '../../components/MarkdownControl';
import TextControl from '../../components/TextControl';
import SelectControl from '../../components/SelectControl';
import NumberControl from '../../components/NumberControl';
import TextAreaControl from '../../components/TextAreaControl';
import './style.css';
import showdown from 'showdown';
import slugify from 'slugify';
import html2canvas from 'html2canvas';
const converter = new showdown.Converter();

export default function SocialTool() {
  const [background, setBackground] = useState('#1f282f');
  const [quoteColor, setQuoteColor] = useState('#fcfcfc');
  const [workTitle, setWorkTitle] = useState('');
  const [workTitleColor, setWorkTitleColor] = useState('#ce482d');
  const [accentColor, setAccentColor] = useState('#f45737');
  const [quoteText, setQuoteText] = useState('');
  const [quoteFontSize, setQuoteFontSize] = useState(38);
  const [quoteLineHeight, setQuoteLineHeight] = useState(46);
  const [quoteAttribution, setQuoteAttribution] = useState('');
  const [quoteTranslator, setQuoteTranslator] = useState('');
  const [labelText, setLabelText] = useState('Sublunary Editions');

  const colors = {
    'Sublunary Orange': '#f45737',
    'Sublunary Yellow': '#d8a031',
    'Sublunary Deep Blue': '#1f282f',
    'Sublunary Light Blue': '#92a9b1',
    'Sublunary Deep Teal': '#31454b',
    'New Green': '#769064',
    'Minty Green': '#92b1a1',
    'Brown/Grey': '#b1a892',
    'Empyrean Red': '#d3222a',
    'Off-Black': '#222',
    'Off-White': '#fcfcfc',
    'Vellum White': '#ede4d5',
  }

  const save = (url) => {
    let link = document.createElement('a');
    link.download = `sublunry-quote-${slugify(workTitle, { lower: true, strict: true, })}.png`;
    link.style.opacity = "0";
    document.body.appendChild(link);
    link.href = url;
    link.click();
    link.remove();
  }

  const download = async () => {
    let container = document.querySelector('.socialCard');
    container.height = '800px';
    container.width = '800px';
    html2canvas(container).then(canvas => {
      let url = canvas.toDataURL("image/png");
      save(url);
    });
  }

  useEffect(() => {

  })

  return (
    <div>
      <div className='page-header-new'>
        <h1>Social Quote Generator</h1>
      </div>
      <div className='socialTool'>
        <div className='socialToolPreview'>
          <div className='socialPreviewContainer'>
            <div className='socialCard' style={{ background: background }}>
              <div className='borderBox borderBox-above' style={{ borderColor: accentColor }}></div>
              <div className='cardContentContainer'>
                <div className='quoteContainer' style={{ fontSize: `${quoteFontSize}px`, lineHeight: `${quoteFontSize < 40 ? quoteFontSize * 1.4 : quoteFontSize * 1.2}px` }}>
                  <span style={{ color: accentColor }}>“</span>
                  <span className='quoteText' style={{ color: quoteColor }} dangerouslySetInnerHTML={{ __html: quoteText.replace(/(?:\r\n|\r|\n)/g, '<br>').replace(/\%/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;') }}></span>
                  <span style={{ color: accentColor }}>”</span>
                </div>
                <div className='quoteMeta'>
                  <div className='quoteAttribution' style={{ color: quoteColor }}>{quoteAttribution}</div>
                  <div className='quoteWork' style={{ color: accentColor }}>{workTitle}</div>
                  {quoteTranslator && <div className='quoteTranslator' style={{ color: quoteColor }}>{quoteTranslator}</div>}
                </div>
              </div>
              <div className='borderBox borderBox-below' style={{ borderColor: accentColor }}>
                <div className='subEds' style={{ color: accentColor }}>{labelText}</div>
              </div>

            </div>
          </div>
        </div>
        <div className='socialToolControls'>
          <TextAreaControl
            label='Quote'
            description={'Use a % character to insert a tab.'}
            value={quoteText}
            change={setQuoteText}
            rows={8}
          />
          <TextControl
            label='Attribution'
            value={quoteAttribution}
            change={setQuoteAttribution}
            valid={false}
            bypass={true}
          />
          <TextControl
            label='Title of Work'
            value={workTitle}
            change={setWorkTitle}
            valid={false}
            bypass={true}
          />
          <TextControl
            label='Translator or Secondary Credit'
            value={quoteTranslator}
            change={setQuoteTranslator}
            valid={false}
            bypass={true}
          />
          <TextControl
            label='Label Text'
            value={labelText}
            change={setLabelText}
            valid={false}
            bypass={true}
          />
          <SelectControl
            label='Background'
            value={background}
            change={setBackground}
            options={
              Object.keys(colors).map((label) => {
                return {
                  label: label,
                  value: colors[label]
                }
              })
            }
          />
          <SelectControl
            label='Accent Color'
            value={accentColor}
            change={setAccentColor}
            options={
              Object.keys(colors).map((label) => {
                return {
                  label: label,
                  value: colors[label]
                }
              })
            }
          />
          <SelectControl
            label='Text Color'
            value={quoteColor}
            change={setQuoteColor}
            options={
              Object.keys(colors).map((label) => {
                return {
                  label: label,
                  value: colors[label]
                }
              })
            }
          />
          <div className='flex-layout'>
            <div className='flex-one'>
              <NumberControl
                value={quoteFontSize}
                change={setQuoteFontSize}
                label={'Font Size (px)'}
                help={''}
                valid={false}
                bypass={true}
              />
            </div>

          </div>
          <button className='main-submit' onClick={download}>Download</button>
        </div>
      </div>
    </div>
  )
}