import React, {useState, useEffect} from 'react';
import moment from 'moment';

export default function DateTimeControl({ value, change, label = '', help = '', placeholder ='', valid = true, type = 'text', classlist = '', disabled = false, time = false, range = null }) {

  const updateHour = (no) => {
    let newHr = parseInt(no);
    let date = moment(value).format('L');

    let time = `${newHr}:${moment(value).minute()} ${moment(value).hour() > 12 ? 'PM' : 'AM'}`;
    console.log(moment(value).hour(), newHr);
    change(moment(`${date} ${time}`).format('LLLL'))
  }

  const updateMinute = (no) => {
    let date = moment(value).format('L');
    let hr = moment(value).hour()
    let time = `${hr > 12 ? hr - 12 : hr}:${no} ${moment(value).hour() > 12 ? 'PM' : 'AM'}`;
    change(moment(`${date} ${time}`).format('LLLL'))

  }

  const updatePeriod = (per) => {
    let date = moment(value).format('L');
    let hr = moment(value).hour()
    let time = `${hr > 12 ? hr - 12 : hr}:${moment(value).minute()} ${per}`;
    change(moment(`${date} ${time}`).format('LLLL'))
  }

  const createArray = (no, zero = false) => {
    let newArray = [];
    for(let i = 0; i < no; i++) {
      if (zero) {
        newArray.push(i < 10 ? `0${i}` : i)
      } else {
        newArray.push(i + 1);
      }
    }
    return newArray;
  }

  const updateMonth = (mo) => {
    let date = `${mo} ${moment(value).date() > moment(`${mo} 1 ${moment(value).year()}`).daysInMonth() ? moment(`${mo} 1 ${moment(value).year()}`).daysInMonth() : moment(value).date()} ${moment(value).year()}`;
    let time = moment(value).format("hh:mm:ss a");
    change(moment(`${date} ${time}`).format('LLLL'))
  }

  const updateDay = (day) => {
    let date = `${moment(value).format('MMMM')}/${day}/${moment(value).year()}`;
    let time = moment(value).format("hh:mm:ss a");
    change(moment(`${date} ${time}`).format('LLLL'))
  }

  const getDays = () => {
    return createArray(moment(value).daysInMonth());
  }

  const getYearRange = () => {

    let start = range ? range[0] : moment().year() - 10;
    let end = range ? range[1] : start + 20;
    let years = [];
    for (let i = start; i < end; i++) {
      years.push(i);
    }
    return years;
  }

  const updateYear = (yr) => {
    let date = `${moment(value).format('MMMM DD')} ${yr}`;
    let time = moment(value).format("hh:mm:ss a");
    change(moment(`${date} ${time}`).format('LLLL'))
  }

  let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  useEffect(() => {

  }, [])

  return (
    <div className='text-control'>
      {label && <label className='form-label'>{label}</label>}
      <div className='time-chooser'>
        <select className='date-time' value={moment(value).format('MMMM')} onChange={(e) => {updateMonth(e.target.value)}} disabled={disabled}>
          {months.map((mo, index) =>
            <option key={mo} value={mo}>{mo}</option>
          )}
        </select>

        <select className='date-time' value={moment(value).date()} onChange={(e) => {updateDay(e.target.value)}} disabled={disabled}>
          {getDays().map((day) =>
            <option key={day} value={day}>{day}</option>
          )}
        </select>

        <select className='date-time' value={moment(value).year()} onChange={(e) => {updateYear(e.target.value)}} disabled={disabled}>
          {getYearRange().map((year) =>
            <option key={year} value={year}>{year}</option>
          )}
        </select>

        {time && <span>@</span>}

        {time && <select className='date-time' type='number' value={moment(value).hour() > 12 ? moment(value).hour() - 12 : moment(value).hour()} onChange={(e) => {updateHour(e.target.value)}} disabled={disabled}>
          {createArray(12).map((no) =>
            <option key={`hour-${no}`} value={no}>{no}</option>
          )}
        </select>}
        {time && <span> : </span>}
        {time && <select className='date-time' type='number' value={moment(value).minute() < 10 ? `0${moment(value).minute()}` : moment(value).minute()} onChange={(e) => {updateMinute(e.target.value)}} disabled={disabled}>
          {createArray(60, true, true).map((no) =>
            <option key={`hour-${no}`} value={no}>{no}</option>
          )}
        </select>}

        {time && <select className='date-time' value={moment(value).hour() > 12 ? 'PM' : 'AM'} onChange={(e) => {updatePeriod(e.target.value)}} disabled={disabled}>
          <option value='AM'>AM</option>
          <option value='PM'>PM</option>
        </select>
        }
      </div>
      {help && <div className='form-help'>{help}</div>}
    </div>
  )
}
