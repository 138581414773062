import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import TextControl from '../../components/TextControl';

export default function Change({ sessionValid, showMessage }) {
  const [profile, setProfile] = useState({});
  const [pass, setPass] = useState('');
  const [newPass, setNewPass] = useState('');
  const [confirmNewPass, setConfirmNewPass] = useState('');
  const [error, setError] = useState('');

  let history = useHistory();

  document.title = 'Change Password | Sublunary Editions';

  const getPub = async () => {
    let data = await window.ASTERISM.makeRequest(`private-user`, 'GET');
    if (data.error) {
      setError(data.error);
    } else {
      setError('');
      setProfile(data);
    }
  }

  useEffect(() => {
    getPub();
  }, []);

  const login = async () => {
    let data = await window.ASTERISM.makeRequest('login', 'POST', {email: profile.email, password: pass});
    if (data.status !== 'good') {
      setError(data.message);
    } else {
      reset();
    }
  }

  const reset = async () => {
    if (newPass !== confirmNewPass) {
      setError('Passwords must match');
    } else if (newPass.length < 6) {
      setError('Password should be at least 6 characters');
    } else {
      let info = {
        email: profile.email,
        password: newPass,
      };
      let data = await window.ASTERISM.makeRequest('change-password', 'POST', info);
      if (!data.error) {
        history.push('/my-account');
        showMessage({text: 'Success! You can now log in with your new password.', temp: true, type: 'success'})
      }
    }
  }

  if (!sessionValid) {
    return (
      <div className='space'>
        <h4>You can't do that without being <Link to='/forgot-password'>logged in</Link>.</h4>
      </div>
    )
  }

  return(
    <div className='login-container'>
      <div className='login-box space'>
        <h1 className='page-title mb-24'>Change Password for {profile.email}</h1>
        <TextControl
          classlist='med-input'
          value={pass}
          change={setPass}
          label={'Current Password'}
          help={''}
          type='password'
          disabled={false}
        />

        <TextControl
          classlist='med-input'
          value={newPass}
          change={setNewPass}
          label={'New Password'}
          help={''}
          type='password'
          disabled={false}
        />

        <TextControl
          classlist='med-input'
          value={confirmNewPass}
          change={setConfirmNewPass}
          label={'Confirm Password'}
          help={''}
          type='password'
          disabled={false}
        />

        <button className='main-submit' onClick={login}>Change Password</button>

        {error && <div className='error'>{error}</div>}
      </div>

    </div>
  )

}
