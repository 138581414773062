import React, {useState, useEffect, useRef} from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import moment from 'moment';
import TextControl from '../../components/TextControl';
import SelectControl from '../../components/SelectControl';
import FileControl from '../../components/FileControl';
import BinaryControl from '../../components/BinaryControl';
import MarkdownControl from '../../components/MarkdownControl';

export default function AccountEdit({ showMessage }) {
  const [profile, setProfile] = useState({});
  const [changingImage, setChangingImage] = useState(false);
  const [oldimage, setOldimage] = useState('');
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('Checking access...');
  const [login, setLogin] = useState(false);

  let { id } = useParams();

  document.title = 'Edit Profile | Asterism Admin';

  const getPub = async () => {
    let data = await window.ASTERISM.makeRequest('private-user', 'GET');
    if (data.error) {
      setError(data.error);
      setLogin(data.login);
    } else {
      setError('');
      setProfile(data);
    }
  }

  const updateProfile = (field, value) => {
    let newProfile = {...profile};
    newProfile[field] = value;
    setProfile(newProfile);
  }

  const updateProfileObj = (obj, field, value) => {
    let newProfile = {...profile};
    newProfile[obj][field] = value;
    setProfile(newProfile);
  }

  const logoImage = useRef(null);

  useEffect(() => {
    getPub();
  }, []);

  const submit = async () => {
    let updatedProfile = {...profile};
    setUploading(true);
    if (logoImage.current && logoImage.current.files.length > 0) {
      updatedProfile.image = await window.ASTERISM.uploadFile(logoImage);
    }
    setUploading(false);
    let data = await window.ASTERISM.makeRequest('update-user', 'POST', updatedProfile);
    if (data.id) {
      showMessage({ text: 'Your profile has been updated!', type: 'success', temp: true});
      updateProfile('_rev', data.rev);
    }
  }

  if (error || !profile._id) {
    return (
      <div className='edit-bar'><div className='edit-title'>{error} {login && <Link to={`/login?return=${window.location.pathname}`}><button className='secondary-submit'>Login</button></Link>}</div></div>
    )
  }

  return (
    <div>
      <div className='edit-bar'>
        <div className='edit-title'>Editing {profile.name}</div>
        <button className='main-submit' onClick={submit}>Save Changes</button>
      </div>
      <div className='admin-two'>
        <div className='admin-col-large'>
          <div className='standard-header mb-24'>
            <h2>General Info</h2>
          </div>
          <TextControl
            value={profile.twitter}
            change={(value) => {updateProfile('twitter', value)}}
            label={'Twitter Handle'}
            help={'Dont\' include the @ symbol'}
            valid={false}
            type='text'
            disabled={false}
          />
          <TextControl
            value={profile.instagram}
            change={(value) => {updateProfile('instagram', value)}}
            label={'Instagram Handle'}
            help={'Dont\' include the @ symbol'}
            valid={false}
            type='text'
            disabled={false}
          />
          <TextControl
            value={profile.facebook}
            change={(value) => {updateProfile('facebook', value)}}
            label={'Facebook Handle'}
            help={'Dont\' include the @ symbol'}
            valid={false}
            type='text'
            disabled={false}
          />
          <div className='standard-header mt-24 mb-24'>
            <h2>Address</h2>
          </div>
          <TextControl
            value={profile.address.attn}
            change={(value) => {updateProfileObj('address', 'attn', value)}}
            label={'ATTN'}
            help={'If you need to include a value for ATTN:, i.e. "Receiving"'}
            valid={false}
            type='text'
            disabled={uploading}
          />
          <TextControl
            value={profile.address.line1}
            change={(value) => {updateProfileObj('address', 'line1', value)}}
            label={'Address Line One'}
            valid={false}
            type='text'
            disabled={uploading}
          />
          <TextControl
            value={profile.address.line2}
            change={(value) => {updateProfileObj('address', 'line2', value)}}
            label={'Address Line Two'}
            valid={false}
            type='text'
            disabled={uploading}
          />
          <TextControl
            value={profile.address.city}
            change={(value) => {updateProfileObj('address', 'city', value)}}
            label={'City'}
            valid={false}
            type='text'
            disabled={uploading}
          />
          <TextControl
            value={profile.address.state}
            change={(value) => {updateProfileObj('address', 'state', value)}}
            label={'State / Region / Province'}
            valid={false}
            type='text'
            disabled={uploading}
          />
          <TextControl
            value={profile.address.postal}
            change={(value) => {updateProfileObj('address', 'postal', value)}}
            label={'Postal Code'}
            valid={false}
            type='text'
            disabled={uploading}
          />
          <TextControl
            value={profile.address.country}
            change={(value) => {updateProfileObj('address', 'country', value)}}
            label={'Country'}
            valid={false}
            type='text'
            disabled={uploading}
          />
          <div className='standard-header mt-24 mb-24'>
            <h2>Contact Info</h2>
          </div>
          <TextControl
            value={profile.primaryContact.name}
            change={(value) => {updateProfileObj('primaryContact', 'name', value)}}
            label={'Primary Contact Name'}
            help={'Who should we contact if there\'s a problem with an order?'}
            valid={false}
            type='text'
            disabled={uploading}
          />
          <TextControl
            value={profile.primaryContact.email}
            change={(value) => {updateProfileObj('primaryContact', 'email', value)}}
            label={'Primary Contact Email'}
            valid={false}
            type='text'
            disabled={uploading}
          />
          <TextControl
            value={profile.primaryContact.phone}
            change={(value) => {updateProfileObj('primaryContact', 'phone', value)}}
            label={'Primary Contact Phone'}
            valid={false}
            type='text'
            disabled={uploading}
          />
          {(profile.roles.publisher || profile.roles.distributor) &&
            <div>
              <div className='standard-header mt-24 mb-24'>
              <h2>Stripe Integration</h2>

              </div>
              <TextControl
                value={profile.stripe && profile.stripe.public ? profile.stripe.public : ''}
                change={(value) => {updateProfileObj('stripe', 'public', value)}}
                label={'Stripe Public Key'}
                valid={false}
                type='text'
                help={'You can find this API key <a href="https://dashboard.stripe.com/apikeys" target="_blank">here</a>'}
                disabled={uploading}
              />
            </div>
        }
        </div>
        <div className='admin-col-small'>
          <div className='standard-header mb-24'>
            <h2>Website Banner</h2>
          </div>
          <BinaryControl
            value={profile.banner.on}
            change={(value) => {updateProfileObj('banner', 'on', value)}}
            label={'Turn Banner On'}
            disabled={uploading}
          />
          <TextControl
            value={profile.banner.text}
            change={(value) => {updateProfileObj('banner', 'text', value)}}
            label={'Banner Text'}
            type='text'
            valid={false}
            disabled={uploading}
          />
          <TextControl
            value={profile.banner.link}
            change={(value) => {updateProfileObj('banner', 'link', value)}}
            label={'Banner Link (Optional)'}
            type='text'
            valid={false}
            disabled={uploading}
          />
        </div>
      </div>
    </div>
  )
}
