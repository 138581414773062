import React, {useState, useEffect, useRef} from 'react';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import SubForm from '../../components/SubEditForm';
import {uploadFile} from '../../Utils.js';

export default function AccountSubEdit({ showMessage }) {
  const [sub, setSub] = useState({});
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState();
  const [login, setLogin] = useState(false);

  document.title = 'Edit Subscription | Sublunary Admin'

  const {id} = useParams();

  const updateSub = (field, value) => {
    let newSub = {...sub};
    newSub[field] = value;
    setSub(newSub);
  }

  const getProduct = async () => {
    let data = await window.ASTERISM.makeRequest(`private-sub?id=${id}`, 'GET');
    if (!data.error) {
      setSub(data);
    } else {
      setError(data.error)
    }
  }

  const newCover = useRef(null);

  useEffect(() => {
    getProduct();
  }, []);

  const submit = async () => {
    let updatedSub = {...sub}
    setUploading(true);
    if (newCover.current && newCover.current.files.length > 0) {
      updatedSub.cover = await window.ASTERISM.uploadFile(newCover);
    }
    setUploading(false);
    let data = await window.ASTERISM.makeRequest('update-sub', 'POST', updatedSub);
    if (data.ok) {
      updateSub('_rev', data.rev);
      showMessage({text: 'Product successfully updated!', temp: true, type: 'success'})
    }
  }

  if (error || !sub._id) {
    return (
      <div className='edit-bar'><div className='edit-title'>{error} {login && <Link to={`/login?return=${window.location.pathname}`}><button className='secondary-submit'>Login</button></Link>}</div></div>
    )
  }

  return (
    <div className=''>
      <div className='edit-bar'>
        <Link className='back-link' to='/my-account/subscriptions'>⟵ Back to subs</Link>
        <button className='main-submit' onClick={submit}>Save Changes</button>
      </div>
      <SubForm setSub={setSub} sub={sub} newCover={newCover} uploading={uploading}/>
    </div>
  )
}
