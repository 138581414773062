import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import { returnImageMarkup } from '../../Utils';
import TextControl from '../../components/TextControl';
import SelectControl from '../../components/SelectControl';
import NumberControl from '../../components/NumberControl';
import BinaryControl from '../../components/BinaryControl';
import moment from 'moment';

export default function Cart({ setCartCount, addToCart, sessionValid, roles, clearCart, showMessage, settings }) {
  const [books, setBooks] = useState({});
  const [subs, setSubs] = useState({});
  const [bundles, setBundles] = useState({});
  const [ebooks, setEbooks] = useState({});

  const [cartdata, setCartdata] = useState({});
  const [cartTotal, setCartTotal] = useState(0);
  const [email, setEmail] = useState('');
  const [needShipping, setNeedShipping] = useState(false);
  const [shippingCountry, setShippingCountry] = useState('US');

  const [shipping, setShipping] = useState(3.65);
  const [shippingMethod, setShippingMethod] = useState('');
  const [express, setExpress] = useState(false);

  const [optin, setOptin] = useState(true);

  const [fetching, setFetching] = useState(false);

  const countries = require('./countries.json');

  let history = useHistory();

  const buildOrder = async (data) => {
    let thedata = await window.ASTERISM.makeRequest('build-order', 'POST', data);
    if (thedata && thedata.books) {
      setBooks(thedata.books);
    }
    if (thedata && thedata.subs) {
      setSubs(thedata.subs);
    }
    if (thedata && thedata.bundles) {
      setBundles(thedata.bundles);
    }
    if (thedata && thedata.ebooks) {
      setEbooks(thedata.ebooks);
    }
  }

  useEffect(() => {
    getShipping();
  }, [books, bundles, cartdata, shippingCountry, express]);

  useEffect(() => {
    const data = localStorage.getItem('seCart');
    let cart = JSON.parse(data);
    if (cart && cart.count) {
      // Deal with undefined
      if (cart.books['undefined']) {
        delete cart.books['undefined'];
      }
      //
      setCartdata(cart);
      buildOrder(cart);
    }
  }, []);

  const isShippingNeeded = () => {
    let baseline = Object.keys(books).length > 0 || Object.keys(bundles).length > 0;
    if (settings && settings.freeShipping && cartTotal >= settings.freeShippingThreshhold) {
      baseline = false;
    }
    setNeedShipping(baseline)
  }

  useEffect(() => {
    isShippingNeeded();
  }, [books, bundles, settings, cartTotal]);

  document.title = 'Cart | Sublunary Editions';

  const isPreorder = (product) => {
    return new Date(product.release).getTime() > new Date().getTime();
  }

  const updateQuantity = async (id, qty, type) => {
    let diff = isNaN(cartdata[type][id] % qty) ? 0 : qty - cartdata[type][id];
    let updated = await addToCart(id, type, diff);
    setCartdata(updated);
  }

  useEffect(() => {
    getOrderTotal();
  }, [cartdata, shipping]);

  useEffect(() => {
    getOrderTotal();
  }, [books, bundles, ebooks, subs])

  const removeFromCart = async (id, type) => {
    if (type == 'books') {
      let newbooks = { ...books };
      delete newbooks[id];
      setBooks(newbooks);
    } else if (type == 'bundles') {
      let newbooks = { ...bundles };
      delete newbooks[id];
      setBundles(newbooks);
    } else if (type == 'subs') {
      let newbooks = { ...subs };
      delete newbooks[id];
      setSubs(newbooks);
    } else if (type == 'ebooks') {
      let newbooks = { ...ebooks };
      delete newbooks[id];
      setEbooks(newbooks);
    }
    let updated = await addToCart(id, type, 0, true)
    setCartdata(updated);
  }

  const getTotal = (id, type) => {
    let total = 0;
    let source = type == 'books' ? books : type == 'bundles' ? bundles : subs;
    total += cartdata[type][id] * parseFloat(source[id].prices.enableSale ? source[id].prices.sale : source[id].prices.retail);
    return total;
  }

  const getEbookTotal = (id) => {
    let total = 0;
    total += cartdata['ebooks'][id] * ebooks[id].ebook.price;
    return total;
  }

  const getOrderTotal = () => {
    let total = 0;
    Object.keys(books).forEach((id) => {
      total += getTotal(id, 'books');
    });
    Object.keys(subs).forEach((id) => {
      total += getTotal(id, 'subs');
    });
    Object.keys(bundles).forEach((id) => {
      total += getTotal(id, 'bundles');
    });
    Object.keys(ebooks).forEach((id) => {
      total += getEbookTotal(id);
    });
    total += shipping;
    setCartTotal(total);
  }

  const placeOrder = async () => {
    window.plausible('CheckoutClick');
    if (!email) {
      showMessage({ text: 'We need your email address', temp: true, type: 'error', label: 'Oops!' });
      return;
    } else {
      window.plausible('ValidCheckoutClick');
      showMessage({ text: 'Hang on! You should be redirected to the checkout screen shortly.', temp: true, type: 'info', label: 'Just a sec!' });
    }
    /** Here we go again **/
    let lineItems = [];
    let subscriptions = [];
    let recurring = false;

    Object.keys(books).forEach((id) => {
      if (cartdata.books[id] > 0) {
        lineItems.push({
          price: books[id].prices.enableSale ? books[id].prices.saleStripe : books[id].prices.retailStripe,
          quantity: cartdata.books[id]
        });
      }
    });
    Object.keys(bundles).forEach((id) => {
      if (cartdata.bundles[id] > 0) {
        lineItems.push({
          price: bundles[id].prices.enableSale ? bundles[id].prices.saleStripe : bundles[id].prices.retailStripe,
          quantity: cartdata.bundles[id]
        });
      }
    });
    Object.keys(ebooks).forEach((id) => {
      if (cartdata.ebooks[id] > 0) {
        lineItems.push({
          price: ebooks[id].ebook.stripePrice,
          quantity: cartdata.ebooks[id]
        });
      }
    });
    Object.keys(subs).forEach((id) => {
      if (cartdata.subs[id] > 0) {
        lineItems.push({
          price: subs[id].prices.retailStripe,
          quantity: cartdata.subs[id]
        });
        if (subs[id].prices.recurring) {
          recurring = true;
        }
      }
    });
    if (shipping > 0) {
      lineItems.push({
        quantity: 1,
        price_data: {
          currency: 'usd',
          unit_amount: Math.round(shipping * 100),
          product_data: {
            name: `Shipping - ${shippingMethod}`,
            description: `${shippingMethod}`,
            images: []
          }
        }
      })
    }

    let customer = false;
    if (recurring) {
      let data = await window.ASTERISM.makeRequest('new-user-auto', 'POST', { email: email });
      customer = data.customer ? data.customer : false;
    }
    let newOrder = {
      lineItems: lineItems,
      source: 'website',
      mode: recurring ? 'subscription' : 'payment',
      countries: needShipping ? [shippingCountry] : countries.map((ct) => ct.code)
    }
    if (customer) {
      newOrder.customer = customer;
    }
    newOrder.customer_email = email;
    newOrder.optin = optin;
    let maybeSource = localStorage.getItem('seSrc');
    if (maybeSource) {
      newOrder.source = maybeSource;
      localStorage.setItem('seSrc', '');
    }
    let order = await window.ASTERISM.makeRequest('checkout', 'POST', newOrder);
    if (!order.session || !order.session.url) {
      window.plausible('CheckoutError');
      showMessage({ text: 'Something went wrong. Try again, and if you consistently get an error, please email support@sublunaryeditions.com', temp: false, type: 'error', label: 'Oops!' });
    } else {
      window.location.href = order.session.url;
    }
  }

  const getShipping = async () => {
    setFetching(true);
    // This is where I calculate weight
    let weight = 0;
    Object.keys(books).forEach((item) => {
      weight += cartdata.books[item] * parseFloat(books[item].dimension.weight);
    });
    Object.keys(bundles).forEach((item) => {
      weight += cartdata.bundles[item] * parseFloat(bundles[item].dimension.weight);
    });
    if (weight == 0) {
      setShipping(0);
      setShippingMethod('n/a');
      setFetching(false);
      return;
    }


    weight += 2;

    let lbs = Math.ceil(weight / 16);
    if (shippingCountry == 'US' && !express && weight < 1120) {
      // We can use media mail!
      let baseline = 2.95;
      let therate = (lbs * .70) + baseline;
      setShipping(therate);
      setShippingMethod('USPS Media Mail');
      setFetching(false);
      return;
    }

    let request = {
      ounces: weight,
      country: shippingCountry,
      carrier: express ? 'UPS' : '',
    }
    let response = await fetch('https://asterismbooks.com/api/shipping-rates', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });
    const rate = await response.json();
    setShipping(rate.shipmentCost);
    setShippingMethod(rate.method);
    setFetching(false);
  }


  return (
    <div>
      <div className='admin-two'>
        <div className='standard-header'>
          <h2>My Cart</h2>
        </div>
        <div className='admin-col-large'>
          {books && Object.keys(books).map((id) =>
            <div key={id} className='cart-item'>
              <div className='image'>{returnImageMarkup(books[id].cover, `Cover art for ${books[id].title}`, '', 300)}</div>
              <span className='col-two'>
                <div className='title'>
                  <Link to={`/products/${books[id].slug}`}>{books[id].title}</Link>
                </div>
                <span className='authors'>{books[id].authors ? books[id].authors.join(', ') : books[id].subtitle}</span>
                <div className='smaller mt-12'>{isPreorder(books[id]) ? `This book will be shipped on or before its publication date of ${moment(books[id].release).format('LL')}` : ''}</div>
              </span>

              <span className='col'>
                <div className='col-label'>Quantity</div>
                <NumberControl
                  classlist='cart-counter'
                  value={cartdata.books[books[id]._id]}
                  change={(value) => { updateQuantity(id, parseInt(value), 'books') }}
                  min={0}
                  valid={false}
                  disabled={false}
                />
                <a className='col-label link' onClick={() => { removeFromCart(id, 'books') }}>Remove</a>
              </span>
              <span className='col'><div className='col-label'>Unit Price</div>{`$${parseFloat(books[id].prices.enableSale ? books[id].prices.sale : books[id].prices.retail).toFixed(2)}`}</span>
              <span className='col'><div className='col-label'>Item Total</div>{`$${parseFloat((books[id].prices.enableSale ? books[id].prices.sale : books[id].prices.retail) * cartdata.books[books[id]._id]).toFixed(2)}`}</span>
            </div>
          )}

          {ebooks && Object.keys(ebooks).map((id) =>
            <div key={id} className='cart-item'>
              <div className='image'><img src={ebooks[id].cover} /></div>
              <span className='col-two'>
                <div className='title'>
                  <Link to={`/products/${ebooks[id].slug}`}>{ebooks[id].title} (ebook)</Link>
                </div>
                <span className='authors'>{ebooks[id].authors ? ebooks[id].authors.join(', ') : ebooks[id].subtitle}</span>
                <div className='smaller mt-12'>This book will be delivered via email after checkout.</div>
              </span>

              <span className='col'>
                <div className='col-label'>Quantity</div>
                <NumberControl
                  classlist='cart-counter'
                  value={cartdata.ebooks[ebooks[id]._id]}
                  change={(value) => { updateQuantity(id, parseInt(value), 'ebooks') }}
                  min={0}
                  valid={false}
                  disabled={false}
                />
                <a className='col-label link' onClick={() => { removeFromCart(id, 'ebooks') }}>Remove</a>
              </span>
              <span className='col'><div className='col-label'>Unit Price</div>{`$${parseFloat(ebooks[id].ebook.price).toFixed(2)}`}</span>
              <span className='col'><div className='col-label'>Item Total</div>{`$${parseFloat((ebooks[id].ebook.price) * cartdata.ebooks[ebooks[id]._id]).toFixed(2)}`}</span>
            </div>
          )}

          {bundles && Object.keys(bundles).map((id) =>
            <div key={id} className='cart-item'>
              <div className='image'></div>
              <span className='col-two'><div className='title'>{bundles[id].title}</div><span className='authors'>{bundles[id].subtitle}</span></span>
              <span className='col'>
                <div className='col-label'>Quantity</div>
                <NumberControl
                  classlist='cart-counter'
                  value={cartdata.bundles[bundles[id]._id]}
                  change={(value) => { updateQuantity(id, parseInt(value), 'bundles') }}
                  min={0}
                  valid={false}
                  disabled={false}
                />
                <a className='col-label link' onClick={() => { removeFromCart(id, 'bundles') }}>Remove</a>
              </span>
              <span className='col'><div className='col-label'>Price</div>${bundles[id].prices.retail} {bundles[id].prices.period}</span>
              <span className='col'><div className='col-label'>Total Today</div>${bundles[id].prices.retail}</span>
            </div>
          )}

          {subs && Object.keys(subs).map((id) =>
            <div key={id} className='cart-item'>
              <div className='image'></div>
              <span className='col-two'><div className='title'>{subs[id].title}</div></span>
              <span className='col'>
                <div className='col-label'>Quantity</div>
                <div>{cartdata.subs[subs[id]._id]}</div>
                <a className='col-label link' onClick={() => { removeFromCart(id, 'subs') }}>Remove</a>
              </span>
              <span className='col'><div className='col-label'>Price</div>${subs[id].prices.retail} {subs[id].prices.period}</span>
              <span className='col'><div className='col-label'>Total Today</div>${subs[id].prices.retail}</span>
            </div>
          )}

          <div className='cart-total'>
            {needShipping && <div className='mb-12'>
              <div className='little-label'>Shipping</div>
              <div>${shipping.toFixed(2)}</div>
            </div>}
            <div>
              <div className='little-label'>Total</div>
              <div className='total-amount'>${cartTotal.toFixed(2)}</div>
            </div>


          </div>
        </div>
        <div className='admin-col-small cart-form'>
          <TextControl
            value={email}
            change={setEmail}
            label={'Email'}
            help={''}
            valid={email.length > 0 && email.includes('@')}
            type='text'
            disabled={false}
          />

          <BinaryControl
            value={optin}
            change={(value) => { setOptin(value) }}
            label={'Get email updates from us?'}
            help={'We will not spam you or sell your data. Ever.'}
          />

          {needShipping &&
            <div>
              <SelectControl value={shippingCountry}
                change={setShippingCountry}
                label={'Shipping To'}
                help={''}
                options={countries.map((country) => {
                  return {
                    value: country.code,
                    label: country.name
                  }
                })}
              />

              <BinaryControl
                value={express}
                change={setExpress}
                label={'Need express shipping?'}
              />
            </div>
          }


          {needShipping && settings && settings.freeShipping && <div className='mb-12'>Add ${(settings.freeShippingThreshhold - cartTotal).toFixed(2)}  to your cart to qualify for free shipping!</div>}

          {!needShipping && <div className='mb-12'>This order qualifies for free shipping!</div>}
          <button className='main-submit mt-24 mb-48' disabled={fetching} onClick={placeOrder}>{fetching ? 'Finding Shipping Rates' : 'Checkout'}</button>
        </div>
      </div>
    </div>
  )
}
