import React, {useEffect, useState} from 'react';
import NumberControl from '../../components/NumberControl';
import SelectControl from '../../components/SelectControl';
import TextControl from '../../components/TextControl';
import TextAreaControl from '../../components/TextAreaControl';
import DateTimeControl from '../../components/DateTimeControl';
import moment from 'moment';

export default function BulkOrder({setExpanded, keys, create}) {
  const [plans, setPlans] = useState([]);
  const [selectedPlans, setSelectedPlans] = useState([]);
  const [book, setBook] = useState('');
  const [items, setItems] = useState([]);

  const getPlans = async () => {
    let data = await window.ASTERISM.makeRequest(`list-sub-options`, 'GET');
    if (data.prices) {
      setPlans(data.prices.data);
    }

  }

  useEffect(() => {
    getPlans();
  }, []);

  const toggleSelected = (index) => {
    let thisIndex = selectedPlans.indexOf(index);
    if (thisIndex >= 0) {
      let newSelected = [...selectedPlans];
      newSelected.splice(thisIndex, 1);
      setSelectedPlans(newSelected);
    } else {
      let newSelected = [...selectedPlans];
      newSelected.push(index);
      setSelectedPlans(newSelected);
    }
  }

  const updateQuantity = (index, value) => {
    let newItems = [...items];
    newItems[index].quantity = value;
    setItems(newItems)
  }

  const readyData = () => {
    const thePlans = selectedPlans.map((index) => { return plans[index].id});
    const data = {
      plans: thePlans,
      items: items
    };
    create(data);
  }

  const addBook = () => {
    let newItems = [...items];
    let extant = items.map((item) => item.product);
    let index = extant.indexOf(book);
    if (index >= 0) {
      newItems[index].quantity ++;
    } else {
      newItems.push({
        name: keys.byProd[book],
        product: book,
        quantity: 1,
        shipped: false,
      })
    }
    setItems(newItems)
  }

  return (
    <div className='modal'>
      <div className='modal-content auto-height'>
        <div className='standard-header'>
          <h2>Create Bulk Orders</h2>
        </div>

        <div className='modal-form'>
          {plans.map((plan, index) =>
            <div className='table-item' key={plan.id} >
              <div className='order-col flex-small'>
                <div
                  className={`checkbox-prototype ${selectedPlans.indexOf(index) !== -1 ? 'selected' : 'not-selected'}`}
                  onClick={() => {toggleSelected(index)}}
                  >
                </div>
              </div>
              <div className='order-col flex-1 bold'>{plan.nickname}</div>
            </div>
          )}

          <div className='order-line-items'>
            <strong>Line Items</strong>
            {items && items.map((item, index) =>
              <div key={item.product} className='cart-item'>
                <div className='flex-1'>{item.name}</div>
                <div className='flex-1'>{item.product}</div>
                <div className='flex-1 col'>
                  <NumberControl
                    classlist='cart-counter'
                    value={item.quantity}
                    change={(value) => {updateQuantity(index, parseInt(value))}}
                    min={0}
                  />
                </div>
              </div>
            )}
          </div>

          <div className='add-title'>
            <SelectControl
              label={'Add a Book'}
              value={book}
              change={(value) => {setBook(value)}}
              options={[
                {
                  value: '',
                  label: '--'
                }
              ].concat(Object.entries(keys.byName).map((opt) => { return {value: opt[1], label: [opt[0]]} }))}
            />
            {book && <a onClick={addBook}>Add</a>}
          </div>

        </div>

        <div className='button-controls'>
          <button className='main-submit' onClick={readyData}>Create</button>
          <button className='secondary-submit' onClick={() => {setExpanded(false)}}>Cancel</button>
        </div>
      </div>
    </div>
  )
}
