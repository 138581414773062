import React, { useState, useEffect } from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import moment from 'moment';
import Paginator from '../../components/Paginator';
import ProductGridItem from '../../components/ProductGridItem';
import { ReactComponent as Triangle } from '../../triangle.svg';
import { ReactComponent as EmpyreanLogo } from './empyrean.svg';


export default function Empyrean({ api, showMessage, addToCart }) {
  const [books, setBooks] = useState([]);
  const [total, setTotal] = useState(0);
  const perPage = 20;

  let { page } = useParams();
  let history = useHistory();

  const getBooks = async () => {
    let data = await window.ASTERISM.makeRequest(`list-all-books?category=Empyrean&per=20&page=${isNaN(page) ? 0 : parseInt(page) - 1}`, 'GET');
    setBooks(data.books);
    setTotal(data.total);
  }

  useEffect(() => {
    getBooks();
  }, [page]);

  const updatePage = (page) => {
    if (parseInt(page) == 0) {
      history.push(`/empyrean`)
    } else {
      history.push(`/empyrean/page/${parseInt(page) + 1}`)
    }
  }

  document.title = 'Empyrean Series | Sublunary Editions';
  document.querySelector('meta[name="description"]').setAttribute("content", 'The Empyrean Series is an imprint of Sublunary Editions dedicated to compiling and curating editions of out-of-print/hard-to-come-by works from the annals of literary history.');

  return (
    <div>
      <div className='page-header-new empyrean-header'>
        <div className='empyrean-logo'><EmpyreanLogo></EmpyreanLogo></div>
        <div>
          <h1>Empyrean Series</h1>
          <p>The Empyrean Series is an imprint of Sublunary Editions dedicated to compiling and curating editions of out-of-print/hard-to-come-by works from the annals of literary history. View the full catalogue <Link to='/empyrean-catalogue'>here</Link>.</p>
        </div>
      </div>
      <div className='generic-block flex'>
      </div>
      <div className='product-grid'>
        {books && books.map((product) =>
          <ProductGridItem key={product._id} product={product} showMessage={showMessage} addToCart={addToCart} />
        )}
      </div>
      <div className='mt-24 mb-24 text-center paginator-sticky'>
        <Paginator value={isNaN(page) ? 0 : parseInt(page) - 1} maxPages={Math.ceil(total / perPage)} change={updatePage} />
      </div>
    </div>
  )
}
