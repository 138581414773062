import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams,
  useHistory,
} from "react-router-dom";
import TextControl from '../../components/TextControl';

export default function Forgot({ sessionValid }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  let history = useHistory();

  const forgot = async () => {
    let data = await window.ASTERISM.makeRequest(`forgot-password?email=${email.toLowerCase()}`, 'GET');
    if (data.error) {
      setError(data.error);
      setMessage('');
    } else {
      setMessage('Check your email. We sent you a token to reset your email, but it is only valid for one hour.')
    }
  }

  document.title = 'Forgot Password | Asterism';

  if (sessionValid) {
    return (
      <div className='space'>
        <h4>Whoa there. You're already logged in. Go to <Link to={'/my-account'}>your account</Link>.</h4>
      </div>
    )
  }

  if (message) {
    return (
      <div className='login-container'>
        <div className='login-box space'>
          <h1 className='page-title mb-24'>Forgot Password</h1>
          <em>{message}</em>
        </div>
      </div>
    )
  }

  return(
    <div className='login-container'>
      <div className='login-box space'>
        <h1 className='page-title mb-24'>Forgot Password</h1>
        <TextControl
          classlist='med-input'
          value={email}
          change={setEmail}
          label={'Email'}
          help={''}
          valid={email.length > 0 && email.includes('@')}
          type='text'
          disabled={false}
          bypass={true}
        />

        <button className='main-submit' onClick={forgot}>Reset</button>

        {error && <div className='error'>{error}</div>}
      </div>

    </div>
  )

}
