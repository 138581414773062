import React, {useState, useEffect, useRef} from 'react';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import BundleForm from '../../components/BundleEditForm';
import {uploadFile} from '../../Utils.js';

export default function AccountBundleEdit({ showMessage }) {
  const [product, setProduct] = useState({});
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState();
  const [login, setLogin] = useState(false);

  document.title = 'Edit Bundle | Sublunary Admin'

  const {id} = useParams();

  const updateProduct = (field, value) => {
    let newProduct = {...product};
    newProduct[field] = value;
    setProduct(newProduct);
  }

  const getProduct = async () => {
    let data = await window.ASTERISM.makeRequest(`private-bundle?id=${id}`, 'GET');
    if (!data.error) {
      setProduct(data);
    } else {
      setError(data.error)
    }
  }

  const newCover = useRef(null);

  useEffect(() => {
    getProduct();
  }, []);

  const submit = async () => {
    let updatedBook = {...product}
    setUploading(true);
    let press = product._id.split(':')[0];
    if (newCover.current && newCover.current.files.length > 0) {
      updatedBook.cover = await window.ASTERISM.uploadFile(newCover);
    }
    setUploading(false);
    let data = await window.ASTERISM.makeRequest('update-bundle', 'POST', updatedBook);
    if (data.ok) {
      updateProduct('_rev', data.rev);
      showMessage({text: 'Product successfully updated!', temp: true, type: 'success'})
    }
  }

  if (error || !product._id) {
    return (
      <div className='edit-bar'><div className='edit-title'>{error} {login && <Link to={`/login?return=${window.location.pathname}`}><button className='secondary-submit'>Login</button></Link>}</div></div>
    )
  }

  return (
    <div className=''>
      <div className='edit-bar'>
        <Link className='back-link' to='/my-account/bundles'>⟵ Back to bundles</Link>
        <button className='main-submit' onClick={submit}>Save Changes</button>
      </div>
      <BundleForm setProduct={setProduct} product={product} newCover={newCover} uploading={uploading}/>
    </div>
  )
}
