import React, { useState, useEffect, useRef } from 'react';
import TextControl from '../TextControl';
import NumberControl from '../NumberControl';
import FileControl from '../FileControl';
import SelectControl from '../SelectControl';
import BinaryControl from '../BinaryControl';
import RichControl from '../RichControl';
import MultiSelect from '../MultiSelect';
import MarkdownControl from '../MarkdownControl';
import DateTimeControl from '../DateTimeControl';
import slugify from 'slugify';
import moment from 'moment';
import { Link } from "react-router-dom";
import { returnImageMarkup } from '../../Utils';

const useForceUpdate = () => useState()[1];

export default function ProductForm({ setProduct, product, newCover, uploading = false }) {
  const [error, setError] = useState('Checking access...');
  const [login, setLogin] = useState(false);

  const updateProduct = (field, value) => {
    let newProduct = { ...product };
    newProduct[field] = value;
    setProduct(product => newProduct);
  }

  const updateProductObj = (obj, field, value) => {
    let newProduct = { ...product };
    if (!newProduct[obj]) {
      newProduct[obj] = {};
    }
    newProduct[obj][field] = value;
    setProduct(newProduct);
  }

  const productcats = ['Poetry', 'Short Stories', 'Compilation', 'Fiction', 'Non-Fiction', 'Essays', 'Hybrid', 'Art/Visual', 'Novella', 'Novel', 'Books', 'Firmament', 'Empyrean'];

  const intoSlug = (string) => {
    if (product.title) {
      return slugify(product.title, { lower: true, strict: true, });
    } else {
      return '';
    }
  }

  const getDiscountRate = (price) => {
    if (parseInt(product.prices.retail) == 0) {
      return '';
    } else {
      return (100 * (1 - (parseFloat(price) / parseFloat(product.prices.retail)))).toFixed(1);
    }
  }

  return (
    <div className='admin-two'>
      <div className='admin-col-large'>
        <div className='standard-header mb-24'>
          <h2>General Info</h2>
          <Link className='smaller' to={`/bundles/${product.slug}`}>View Product Page</Link>
        </div>
        <TextControl
          value={product.title}
          change={(value) => { updateProduct('title', value) }}
          label={'Book Title'}
          help={intoSlug(product.title)}
          valid={false}
          type='text'
          disabled={uploading}
          required={true}
        />
        <TextControl
          value={product.subtitle}
          change={(value) => { updateProduct('subtitle', value) }}
          label={'Subtitle'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <TextControl
          value={product.includes}
          change={(value) => { updateProduct('includes', value) }}
          label={'Products Included'}
          help={'A comma separated list of product IDs include in the bundle'}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <BinaryControl
          value={product.public}
          change={(value) => { updateProduct('public', value) }}
          label={'Make Product Public'}
          help={'If no, it will only be visible to you.'}
          disabled={uploading}
        />

        <MultiSelect
          classList=''
          values={product.categories ? product.categories : []}
          change={(value) => { updateProduct('categories', value) }}
          choices={productcats}
          label={'Categories'}
          help={'Select any useful category designations'}
          disabled={uploading}
        />

        <div className='standard-header mt-24 mb-24'>
          <h2>Pricing & Terms</h2>
        </div>
        <NumberControl
          classlist='med-input'
          value={product.prices.list}
          change={(value) => { updateProductObj('prices', 'list', value) }}
          label={'List Price'}
          help={'Face value of the book; may or may not be encoded in your barcode.'}
          valid={false}
          disabled={uploading}
          step='.01'
        />
        <NumberControl
          classlist='med-input'
          value={product.prices.retail}
          change={(value) => { updateProductObj('prices', 'retail', value) }}
          label={'Retail Price'}
          help={'What you sell it for.'}
          valid={false}
          disabled={uploading}
          step='.01'
        />
        <TextControl
          classlist='small-input'
          value={product.prices && product.prices.retailStripe}
          change={(value) => { updateProductObj('prices', 'retailStripe', value) }}
          label={'Stripe Price ID'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
          required={true}
        />

        <BinaryControl
          value={product.prices.enableSale}
          change={(value) => { updateProductObj('prices', 'enableSale', value) }}
          label={'Enable Sale Price'}
          help={'Exactly what it says'}
          disabled={uploading}
        />

        <NumberControl
          classlist='med-input'
          value={product.prices.sale}
          change={(value) => { updateProductObj('prices', 'sale', value) }}
          label={'Sale Price'}
          help={'What you sell it for.'}
          valid={false}
          disabled={uploading}
          step='.01'
        />
        <TextControl
          classlist='small-input'
          value={product.prices && product.prices.saleStripe}
          change={(value) => { updateProductObj('prices', 'saleStripe', value) }}
          label={'Sale Stripe Price ID'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
          required={true}
        />



        <div className='standard-header mt-24 mb-24'>
          <h2>Dimensions</h2>
        </div>
        <NumberControl
          classlist='small-input'
          value={product.dimension.weight}
          change={(value) => { updateProductObj('dimension', 'weight', value) }}
          label={'Weight (oz)'}
          help={''}
          valid={product.dimension.weight > 0}
          disabled={uploading}
          step='.01'
        />
        <NumberControl
          classlist='small-input'
          value={product.dimension.height}
          change={(value) => { updateProductObj('dimension', 'height', value) }}
          label={'Height (in)'}
          help={''}
          valid={product.dimension.height > 0}
          disabled={uploading}
          step='.01'
        />
        <NumberControl
          classlist='small-input'
          value={product.dimension.width}
          change={(value) => { updateProductObj('dimension', 'width', value) }}
          label={'Width (in)'}
          help={''}
          valid={product.dimension.width > 0}
          disabled={uploading}
          step='.01'
        />
        <NumberControl
          classlist='small-input'
          value={product.dimension.thickness}
          change={(value) => { updateProductObj('dimension', 'thickness', value) }}
          label={'Thickness (in)'}
          help={''}
          valid={product.dimension.thickness > 0}
          disabled={uploading}
          step='.01'
        />

        <div className='standard-header mt-24 mb-24'>
          <h2>Description</h2>
        </div>

        <MarkdownControl
          label='Product Description'
          disabled={false}
          value={product.description}
          change={(value) => { updateProduct('description', value) }}
          disabled={uploading}
          rows={14}
        />


        {/*<RichControl
          label='Product Description'
          value={product.description ? product.description : ''}
          change={(value) => {updateProduct('description', value)}}
        />*/}

      </div>
      <div className='admin-col-small'>
        <div className='standard-header mb-24'>
          <h2>Cover</h2>
        </div>
        {!product.cover &&
          <FileControl
            classlist=''
            label={'Cover Image'}
            help={'.'}
            valid={false}
            reference={newCover}
            disabled={uploading}
            image={true}
            accept="image/png, image/jpeg"
          />
        }

        {product.cover && returnImageMarkup(product.cover, `Cover art for ${product.title}`, 'cover-preview')}
        {product.cover && <button className='secondary-submit' onClick={() => { updateProduct('cover', '') }}>Remove/Change Cover</button>}

        <hr />

        <TextControl
          value={product.asterismLink}
          change={(value) => { updateProduct('asterismLink', value) }}
          label={'Link to Asterism'}
          help={''}
          valid={false}
          type='text'
          disabled={uploading}
        />

        <hr />

        <TextControl
          value={product.related}
          change={(value) => { updateProduct('related', value.replace(/-/g, '')) }}
          label={'Related Products'}
          help={'Comma-separated list of ISBNs or product identifiers'}
          valid={false}
          type='text'
          disabled={uploading}
        />

      </div>
    </div>
  )
}
