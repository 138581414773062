import React, {useState, useEffect} from 'react';
import {
  Link
} from "react-router-dom";

export const getMenus = (roles) => {
  const menus = [
    {
      label: 'My Account Home',
      path: '/my-account',
      external: false,
    },
    {
      label: 'Orders',
      path: '/my-account/orders',
      external: false,
    },
    {
      label: 'Products',
      path: '/my-account/products',
      external: false,
    },
    {
      label: 'Bundles',
      path: '/my-account/bundles',
      external: false,
    },
    {
      label: 'Subscriptions',
      path: '/my-account/subscriptions',
      external: false,
    },
    {
      label: 'Pitches',
      path: '/my-account/pitches',
      external: false,
    },
    {
      label: 'Posts',
      path: '/my-account/posts',
      external: false,
    },
    {
      label: 'Pages',
      path: '/my-account/pages',
      external: false,
    },
  ];
  return menus;
};


export const mainMenu = {
  right: [
    {
      label: 'Books',
      path: '/all-books',
    },
    {
      label: 'Firmament',
      path: '/magazine',
    },
    {
      label: 'Empyrean',
      path: '/empyrean',
    },
    {
      label: 'eBooks',
      path: '/all-ebooks',
    },
    {
      label: 'Bundles',
      path: '/all-bundles'
    },
    {
      label: 'News',
      path: '/news',
    },
    {
      label: 'Subscribe',
      path: '/subscribe',
    },
    {
      label: 'About',
      path: '/page/about',
    },
  ]
}

export function NewMenu({ sessionValid }) {
  const [open, setOpen] = useState(false);

  if (window.innerWidth < 1001 && open) {
    return (
      <div className='mobile-menu-container'>
        <a clasName='menu-button' onClick={() => {setOpen(false)}}>Close</a>
        <div className='mobile-menu'>
          {mainMenu.right.map((item, index) =>
            <Link className='mobile-menu-item' key={item.path + index} to={item.path} onClick={() => {setOpen(false)}}>{item.label}</Link>
          )}
        </div>
      </div>
    )
  } else if (window.innerWidth < 1001) {
    return (
      <div className='mobile-menu-container'>
        <a clasName='menu-button' onClick={() => {setOpen(true)}}>Menu</a>
      </div>
    )
  } else {
    return (
      <div className='sub-menu'>
        {mainMenu.right.map((item) =>
          <Link key={item.path} to={item.path}>{item.label}</Link>
        )}
        {sessionValid ? <Link to='/my-account'>My Account</Link> : <Link to='/login'>Login</Link>}
      </div>
    )  }
}

export function MainMenu({ sessionValid }) {
  const [open, setOpen] = useState(false);

  if (window.innerWidth < 1001 && open) {
    return (
      <div className='mobile-menu'>
        <div className='sub-menu'>
          {mainMenu.right.map((item) =>
            <Link key={item.path} to={item.path}>{item.label}</Link>
          )}
          {sessionValid ? <Link to='/my-account'>My Account</Link> : <Link to='/login'>Login</Link>}
        </div>
      </div>
    )
  } else if (window.innerWidth < 1001) {
    return (
      <div className='mobile-menu-container'>
        <a onClick={() => {setOpen(false)}}>Menu</a>
      </div>
    )
  } else if (open) {
    return (
      <div className='edit-bar'>
        <a onClick={() => {setOpen(false)}}>Menu</a>
        {sessionValid ? <Link to='/my-account'>My Account</Link> : <Link to='/login'>Login</Link>}
          <div className='mobile-menu left'>
          {mainMenu.right.map((item) =>
            <div className='mobile-menu-item'>
              {item.path !== window.location.pathname  ? <Link to={item.path} onClick={() => {setOpen(false)}}>{item.label}</Link> : <span>{item.label}</span>}
            </div>
          )}
          </div>
      </div>
    )
  } else if (!open) {
    return (
      <div className='edit-bar'>
        <a onClick={() => {setOpen(true)}}>Menu</a>
        {sessionValid ? <Link to='/my-account'>My Account</Link> : <Link to='/login'>Login</Link>}
      </div>
    )
  } else {
    return (
      <div></div>
    )
  }
}

export function AdminMenu({ sessionValid, roles, logout, userName, title = null, knowledge = false}) {
  const [open, setOpen] = useState(false);

  const menuItems = getMenus(roles);
  if (!sessionValid) {
    return (
      <div className='edit-bar'>
        <div className='edit-title'>You do not have access to view this page.</div>
      </div>
    )
  } else if (!roles.publisher) {
    return (
      <div></div>
    )
  } else if (window.innerWidth > 1000) {
    return (
      <div className='edit-bar'>
        <div className='edit-title'>{!title ? userName : knowledge ? 'Knowledge Base' : <span><Link to={'/my-account'}>{userName}</Link> {title}</span>}</div>
        <div className='sub-menu'>
          {knowledge && <Link to='/my-account'>Account Home</Link>}
          {menuItems.map((item) =>
            item.path !== window.location.pathname  ? <Link to={item.path}>{item.label}</Link> : <span>{item.label}</span>
          )}
          {sessionValid && <a onClick={logout}>Log Out</a>}
        </div>
      </div>
    )
  } else if (open) {
    return (
      <div className='edit-bar'>
        <Link to='/my-account'>{userName}</Link>
        <a onClick={() => {setOpen(false)}}>Close Menu</a>
        <div className='mobile-menu right'>
          {sessionValid && <div className='mobile-menu-item'><Link to='/my-account/edit'>Edit Profile</Link></div>}
          {menuItems.map((item) =>
            <div className='mobile-menu-item'>
              {item.path !== window.location.pathname  ? <Link to={item.path} onClick={() => {setOpen(false)}}>{item.label}</Link> : <span>{item.label}</span>}
            </div>
          )}
          {sessionValid && <div className='mobile-menu-item'><a onClick={logout}>Log Out</a></div>}
        </div>
      </div>
    )
  } else if (!open) {
    return (
      <div className='edit-bar'>
        <Link to='/my-account'>{userName}</Link>
        <a onClick={() => {setOpen(true)}}>Account Menu</a>
      </div>
    )
  } else {
    return (
      <div></div>
    )
  }
}
