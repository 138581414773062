import React, {useState, useEffect} from 'react';
import {
  Link,
  useParams
} from "react-router-dom";
import moment from 'moment';

export default function AccountPosts({ userId, roles, userMenu, userName }) {
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState();
  const [login, setLogin] = useState();


  document.title = 'All Pages | Asterism Admin';
  const getPosts = async () => {
    let data = await window.ASTERISM.makeRequest(`private-pages`, 'GET');
    setPosts(data);
  }

  useEffect(() => {
    if (userId) {
      getPosts();
    }
  }, [userId]);

  return (
    <div>
      <div className='admin-full'>
        <div className='standard-header'>
          <h2>All Pages</h2>
          <Link className='see-more-link' to={`/my-account/add-page`}><button className='secondary-submit'>Add Page +</button></Link>
        </div>
        <div className='table-header table-item'>
        <div className='order-col flex-1'>Title</div>
        <div className='order-col flex-1'>Status</div>
        <div className='order-col flex-1'>Published</div>
        <div className='order-col flex-1'>Last Edited</div>
        <div className='order-col flex-1'>View</div>
        </div>
        <div className='admin-orders'>
        {posts.map((post) =>
          <div className='table-item' key={post._id} >
            <div className='order-col flex-1 bold'><Link to={`/my-account/pages/${post._id}`}>{post.title}</Link></div>
            <div className='order-col flex-1'>{post.public ? 'Published' : 'Private'}</div>
            <div className='order-col flex-1'>{post.published ? moment(post.published).format('LLL') : '--'}</div>
          </div>
        )}
        {posts.length == 0 && <div className='mt-24'><em>You don't have any pages yet.</em></div>}

        </div>
      </div>
    </div>
  )
}
