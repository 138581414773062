import React, { useState, useEffect } from 'react';
import {
  Link,
  useLocation
} from "react-router-dom";
import { ReactComponent as Arrow } from './arrow.svg';


const menuStructure = [
  {
    label: 'Books',
    submenu: [
      {
        label: 'All Books',
        path: '/all-books',
      },
      {
        label: 'Empyrean Series',
        path: '/empyrean',
      },
      {
        label: 'Subscriptions',
        path: '/subscribe',
      },
      {
        label: 'eBooks',
        path: '/all-ebooks',
      },
      {
        label: 'Book Bundles',
        path: '/all-bundles'
      },
    ]
  },
  {
    label: 'Magazine',
    path: '/magazine',
  },
  {
    label: 'News',
    path: '/news',
  },
  {
    label: 'Merch',
    externalPath: 'https://www.bonfire.com/store/sublunary-editions/',
  },
  {
    label: 'About',
    submenu: [
      {
        label: 'About Us',
        path: '/page/about',
      },
      {
        label: 'Distribution',
        path: '/page/distribution',
      },
      {
        label: 'Review Copies',
        path: '/request-review-copy'
      },
      {
        label: 'Contact',
        path: '/page/contact',
      },
      {
        label: 'Twitter',
        externalPath: 'https://twitter.com/sublunaryeds',
      },
      {
        label: 'YouTube',
        externalPath: 'https://www.youtube.com/channel/UCMDCVf_SjqsIzlgaaOeyUzw',
      },
      {
        label: 'Instagram',
        externalPath: 'https://instagram.com/sublunaryeditions',
      },
      {
        label: 'Privacy',
        path: '/page/privacy',
      },
    ]
  }
];

export default function NewMenu({ sessionValid, search, setSearch, handleKeyDown, gosearching }) {
  const [open, setOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div className='main-menu'>
      <div className={`mobile-label ${open ? 'open' : 'closed'}`} onClick={() => { setOpen(!open) }}>Menu <Arrow /></div>
      <div className={`main-menu-container ${open ? 'open' : 'closed'}`}>
        <div className='mobile-search'>
          <input className='search-box' value={search} placeholder='Search' onChange={(e) => { setSearch(e.target.value) }} onKeyDown={handleKeyDown} />
          <div className='search-button' onClick={gosearching}>Go</div>
        </div>
        {menuStructure.map((item) => {
          if (item.path) {
            return (
              <Link key={item.path} to={item.path}>{item.label}</Link>
            )
          } else if (item.externalPath) {
            return (
              <a key={item.externalPath} href={item.externalPath} target='_blank'>{item.label}</a>
            )
          } else {
            return (
              <SubMenu key={item.label} label={item.label} menu={item.submenu} location={location} />
            )
          }
        })}
        {sessionValid ? <Link to='/my-account'>My Account</Link> : <Link to='/login'>Login</Link>}{' | '}
      </div>
    </div>
  )
}

function SubMenu({ label, menu, location }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div className={`main-menu-item ${open ? 'open' : 'closed'}`}>
      <span onClick={() => { setOpen(!open) }}>{label} <Arrow /></span>
      {open &&
        <div className='extended-menu'>
          {menu.map((item) => {
            if (item.path) {
              return (
                <Link key={item.path} to={item.path}>{item.label}</Link>
              )
            } else if (item.externalPath) {
              return (
                <a key={item.externalPath} href={item.externalPath} target='_blank'>{item.label}</a>
              )
            }
          })}
        </div>
      }
    </div>
  )
}
