import React, {useState, useEffect, useRef} from 'react';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import PostForm from '../../components/PostEditForm';
import {uploadFile} from '../../Utils.js';
import slugify from 'slugify';
import moment from 'moment';

export default function AccountPostAdd({ showMessage, userId }) {
  const defaultPost = {
    title: '',
    byline: 'Sublunary Editions',
    subtitle: '',
    categories: [],
    slug: '',
    public: false,
    published: moment().format('LLLL'),
    lastModified: moment().format('LLLL'),
    content: '',
    citation: ''
  };
  const [post, setPost] = useState(defaultPost);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');
  const [login, setLogin] = useState(false);

  const featuredImage = useRef();

  let history = useHistory();

  document.title = 'Creating Post | Sublunary Admin';


  const submit = async () => {
    let updatedPost = {...post}
    updatedPost.slug = slugify(updatedPost.title, {lower: true, strict: true});
    setUploading(true);
    if (featuredImage.current && featuredImage.current.files.length > 0) {
      updatedPost.featuredImage = await window.ASTERISM.uploadFile(featuredImage, true);
    }
    setUploading(false);
    updatedPost.lastUpdated = moment().format('LLL');
    let data = await window.ASTERISM.makeRequest(`create-post?id=${userId}`, 'POST', updatedPost);
    if (data.ok) {
      showMessage({text: 'Post successfully created!', temp: true, type: 'success'});
      history.push(`/my-account/posts/${data.id}`);
    }
  }

  if (error) {
    return (
      <div className='edit-bar'><div className='edit-title'>{error} {login && <Link to={`/login?return=${window.location.pathname}`}><button className='secondary-submit'>Login</button></Link>}</div></div>
    )
  }

  return (
    <div className=''>
      <div className='edit-bar'>
        <div className='edit-title'>Creating a New Post</div>
        <button className='main-submit' onClick={submit}>Create Post</button>
      </div>
      <PostForm setPost={setPost} post={post} featuredImage={featuredImage} uploading={uploading}/>
    </div>
  )
}
