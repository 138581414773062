import React, { useState, useEffect, useRef } from 'react';
import {
  Link,
  useParams,
  useHistory
} from "react-router-dom";
import ProductForm from '../../components/ProductEditForm';
import { uploadFile } from '../../Utils.js';

export default function AccountProductEdit({ showMessage }) {
  const [product, setProduct] = useState({});
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState();
  const [login, setLogin] = useState(false);

  document.title = 'Edit Product | Sublunary Admin'

  const { id } = useParams();

  const updateProduct = (field, value) => {
    let newProduct = { ...product };
    newProduct[field] = value;
    setProduct(newProduct);
  }

  const getProduct = async () => {
    let data = await window.ASTERISM.makeRequest(`private-product?id=${id}`, 'GET');
    if (!data.error) {
      data.ebook = data.ebook ? data.ebook : {};
      setProduct(data);
    } else {
      setError(data.error)
    }
  }

  const previewFile = useRef(null);
  const newCover = useRef(null);
  const ebookFile = useRef(null);
  const newFile = useRef(null);

  useEffect(() => {
    getProduct();
  }, []);

  const submit = async () => {
    let updatedBook = { ...product }
    setUploading(true);
    if ((previewFile.current && previewFile.current.files.length > 0) || (newCover.current && newCover.current.files.length > 0) || (ebookFile.current && ebookFile.current.files.length > 0)) {
      showMessage({ text: 'Hold tight! We\'re uploading your files', temp: true, type: 'info' });
    }
    if (previewFile.current && previewFile.current.files.length > 0) {
      updatedBook.preview = await window.ASTERISM.uploadFile(previewFile);
    }
    if (newCover.current && newCover.current.files.length > 0) {
      updatedBook.cover = await window.ASTERISM.uploadFile(newCover);
    }
    if (ebookFile.current && ebookFile.current.files.length > 0) {
      updatedBook.ebook.file = await window.ASTERISM.uploadFile(ebookFile);
    }
    setUploading(false);
    let data = await window.ASTERISM.makeRequest('update-product', 'POST', updatedBook);
    if (data.ok) {
      updateProduct('_rev', data.rev);
      showMessage({ text: 'Product successfully updated!', temp: true, type: 'success' })
    }
  }

  if (error || !product._id) {
    return (
      <div className='edit-bar'><div className='edit-title'>{error} {login && <Link to={`/login?return=${window.location.pathname}`}><button className='secondary-submit'>Login</button></Link>}</div></div>
    )
  }

  return (
    <div className=''>
      <div className='edit-bar'>
        <Link className='back-link' to='/my-account/products'>⟵ Back to products</Link>
        <button className='main-submit' onClick={submit}>Save Changes</button>
      </div>
      <ProductForm setProduct={setProduct} product={product} previewFile={previewFile} newCover={newCover} uploading={uploading} ebookFile={ebookFile} newFile={newFile} showMessage={showMessage} />
    </div>
  )
}
